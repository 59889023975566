//-------Palette-------------
$H050:#fff2f5;
$H100:#fac8d4;
$H200:#f29bae;
$H300:#ed728a;
$H400:#e64966;
$H500:#e02443;
$H600:#d91a37;
$H700:#c91029;
$H800:#b3071b;
$H900:#94000f;

// $bg-disabled: #c8c8c8;
$bg-disabled: #afafaf;

//-------Body-------------
$body-text-color: #000;
$body-bg-white: #fff;
$font: 'Shapirit', sans-serif;
$body-text-second-color: #0c0c0c;
$body-text-third-color: #28253D;
$body-text-fourth-color: #6E7072;
$body-text-white-color: #fff;

//-------Footer-------------
$footer-bg-1: #28253d;
$footer-bg-2: #1f1d2d;
$footer-text-color-1: $body-text-white-color;
$footer-text-color-2: #c5c5c5;

//-------Header-------------
$header-bg-1: #fff;
$header-bg-2: #28253D;
$header-text-color-1: #151515;
$header-text-color-2: $body-text-white-color;

//--------------------------
$header-badge-bg: $H500;

//--------------------------
$header-link-color: $body-text-white-color;
$header-link-bg:#48455A;
$header-link-bg-hover:#5B5771;

//--------------------------
$header-search-bg:#fff;
$header-search-color:#88898A;

//-------Main-------------
$main-header-color: #252C32;
$main-bg-light-grey: #f7f8fA;

//--------------------------
$panel-bg-color: #fff;
$panel-border-color: #efefef;
$panel-p-color: rgba(#252C32, 0.6);
$panel-a-color: $H500;

//--------------------------
$checkbox-color: $H500;
$checkbox-border: #C9CCD6;
$checkbox-bg: $body-bg-white;

//--------------------------
$radiobox-color: rgba(#252C32, 0.6);

//--------------------------
$textarea-text-color: $body-text-second-color;
$textarea-placeholder-color: #8E9297;
$textarea-border-color: #D8DDDF;
$textarea-bg-color: #fff;
$textarea-border-color-active: #28253D;

//--------------------------
$calendar-bg: $body-bg-white;
$calendar-border-color: #DDDBDA;
$calendar-box-shadow-color: rgba(0, 0, 0, 0.16);
$calendar-un-same-month-color: #706E6B;
$calendar-header-color: #3E3E3C;
$calendar-delimiter-color:#F1F1F1;

//--------------------------
$banner-bg: #1F1D2F;
$panel-border-color: #E5E9EB;
$panel-border-color-2: #CED6DA;

//--------------------------
$button-bg-color: #F7F7F7;

//--------------------------
$scrollbar-color-1: #c9c9c9;
$scrollbar-color-2: #f1f1f1;

//--------------------------
$short-description-color: #6E7C87;

//--------------------------
$pagination-border-color: #C2C9D1;

//--------------------------
$notification-shadow-color-1: rgba(24, 39, 75, 0.12);
$notification-shadow-color-2: rgba(24, 39, 75, 0.1);

//--------------------------
$new-order-bg: #FEF7DB;
$in_progress-order-bg: #DFF9E0;
$completed-order-bg: #E5F3FE;
$failed-order-bg: #FBE3E7;
$canceled-order-bg: #E9E9E9;
$need-review-order-bg: #F4E5FE;
$awaiting-delivery-order-bg: #EDEAFF;
$waiting-payment-order-bg: #FFDEDE;
$declined-order-bg: #E9E9E9;
$delivered-order-bg: #DFEEE6;
$in-review-order-bg: #F4E5FE;

//--------------------------
$separate-border-bg: #c4c4c4;
$separate-text-color: #626364;

//--------------------------
$backdrop-bg-color: #000;
$supplier-bg-separator: #48455A;

//--------------------------
$offers-text-color: #666;
$offers-with_delay-bg: #3D9B28;
$offers-partial_order-bg: #48455A;
$offers-line-bg: #ecedf0;
$replacement-bg: #F7F7F8;

//--------------------------
$rs-tooltip-shadow: rgba(0, 0, 0, 0.16);
$rs-tooltip-corner: rgba(0, 0, 0, 0.08);

//--------------------------
$rs-loader-bg: #f2f2f2;
$rs-loader-bg-inverse: #222;
$rs-text-color: #787878;
$rs-text-color-inverse: #fff;
$rs-loader-spin-bg: #D3D3D3;
$rs-loader-spin-bg-inverse: #acacac;

//--------------------------
$account_menu_border: #EAEAEA;
$account_menu_hover: rgba(216, 221, 223, 0.3);

//--------------------------
$out_of_stock_bg: #F3D3D8;
$out_of_stock_color: #000;

//--------------------------
$link-list-bg: #F8F9FB;
$link-list-border: #E7E5E5;
$link-list-border-upload: #9AA4B8;

//--------------------------
$make_offer-delimiter-color: #DCDFE0;
$make_offer-light-color: #7C8084;
$make_offer-postfix-color: rgba(0, 0, 0, 0.54);
$make_offer-border-color: #E0E0E0;

//--------------------------
$orders_links_list-bg: rgba(40, 37, 61, 0.03);

//--------------------------
$warning_new_bg_color: rgba(252, 233, 236, 0.5);
$chose_border_color: rgba(154, 164, 184, 0.5);

//--------------------------
$light-grey: #d4d3d8;
$secondary-text: #3e3b50;
$concrete: #f3f3f3;
$primary-red-hover: #C00020;
