* {
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-color-1 $scrollbar-color-2;
}

*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-track {
  background: $scrollbar-color-2;
  border-style: solid;
  border-color: $body-bg-white;
  border-width: 0 3px;
}
*::-webkit-scrollbar-thumb {
  background-color: $scrollbar-color-1;
  border-radius: 0;
  border-style: solid;
  border-color: $body-bg-white;
  border-width: 0 3px;
}

.rs-container {
  width: 100%;
}

.placeholder-time {
  .MuiSelect-select.MuiSelect-select {
    color: #a3a3a3;
  }
}

.time-range-item {
  .MuiListItem-button:hover {
    background-color: #E02443;
    color: white;
  }
  .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background-color: #E02443;
    color: white;
  }
}

.time-range{
  width: 100%;

  & .MuiInput-underline:before {
    border-bottom: 1px solid red !important;
  }

  .MuiSelect-select {
    border: 1px solid #d8dddf;
    border-radius: 4px;
    padding-top: 12px;
    padding-right: 16px;
    padding-left: 12px;
    text-align: start;
  }

  .MuiSelect-select:focus {
    background-color: white;
  }

  svg {
    right: 90%;

    :first-child {
      color: #9AA4B8;
    }
    :last-child {
      color: #E02443;
    }
  }
}

@media (max-width: 991px) {
  .time-range{
    svg {
      right: 96%;
    }
  }
}

@media (max-width: 767px) {
  .time-range{
    svg {
      right: 94%;
    }
  }
}

@media (max-width: 575px) {
  .time-range{
    svg {
      right: 92%;
    }
  }
}

@media (max-width: 410px) {
  .time-range{
    svg {
      right: 88%;
    }
  }
}
.warning_box {
  margin: 15px 0 0;
  padding: 11px 40px 14px 14px;
  border: 1px solid $H500;
  border-radius: 3px;
  position: relative;
  p {
    font-size: 14px !important;
    line-height: 22px !important;
    letter-spacing: -0.006em !important; 
    color: $main-header-color !important;
    margin: 0 !important;
    .red {
      color: $H500;
      font-weight: 500;
    }   
    .strong {
      font-weight: 500;
    }
    a {
      color: $H500;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
        color: $H700;
      }
    }        
  }
  &::before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    overflow: hidden;
    position: absolute;
    top: 14px;
    right: 13px;
    margin: 0;
    padding: 0;
    background: transparent url('../svg/info-ico.svg') no-repeat scroll 50% 50%;
    background-size: contain;
  }
  .flex-button {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: auto;
    position: relative;    
    margin: 0 -10px;
    > div {
      padding: 0 10px;
      &:nth-child(1) {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        width: calc(100% - 210px);
        max-width: calc(100% - 210px);      
      }
      &:nth-child(2) {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        width: 210px;
        max-width: 210px;        
      }  
      button {
        display: block;
        width: 100%;
      }    
    }
  }
}

@media (max-width: 991px) {
  .warning_box {
    .flex-button {
      > div {
        &:nth-child(1) {
          width: 100%;
          max-width: 100%;      
        }
        &:nth-child(2) {
          width: 100%;
          max-width: 100%;
          margin-top: 10px;        
        }  
      }
      button {
        max-width: 210px;
      }  
    }
  }
}

.rs-btn.rs-btn-primary {
  padding: 11px 7px;
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: $body-text-white-color;  
  border-radius: 2px;
  background: $H500;
  display: inline-block;
  vertical-align: top;
  width: auto;
  max-width: 100%;
  height: 42px;
  min-width: 120px;
  cursor: pointer;
  .rs-ripple-pond {
    display: none !important;
  }
  &:hover {
    background: $H700;
  }
  > span {
    display: block;
    width: fit-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 auto;
  } 
  &.rs-btn-block {
    display: block;
    width: 100%;
  }
  &[disabled],
  &.rs-btn-disabled {
    background: $bg-disabled;
    opacity: 1;
    cursor: not-allowed !important;
  }
}

.rs-btn.rs-btn-link {
  padding: 11px 7px;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: $H500;  
  border-radius: 2px;
  background: none;
  display: inline-block;
  vertical-align: top;
  width: auto;
  max-width: 100%;
  height: 42px;
  min-width: 120px;
  cursor: pointer;
  text-decoration: underline;
  .rs-ripple-pond {
    display: none !important;
  }
  &:hover {
    color: $H700;
    background: none;
    text-decoration: none;
  }
  > span {
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  } 
  &.rs-btn-block {
    display: block;
    width: 100%;
  }
  &[disabled],
  &.rs-btn-disabled {
    color: $bg-disabled;
    background: none;
    opacity: 1;
    cursor: not-allowed !important;
    text-decoration: underline !important;
  }
}

.rs-checkbox {
  margin: 0 0 20px;
  &:last-child {
    margin: 0;
  }
  .rs-checkbox-checker {
    display: inline-block;
    vertical-align: top;
    width: auto;
    max-width: 100%;
    padding: 0;
    min-height: 20px;
    line-height: 20px;      
    label {
      padding: 0 30px 0 0;
      display: block;
      width: 100%;
      position: relative;
      .text {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: $body-text-second-color;   
        display: block;   
        width: 100%;
        overflow: hidden;
        >a{
          color: inherit;
          text-decoration: none;
          >span{
            text-decoration: underline;
          }
        }
        .light {
          color: $make_offer-light-color;
        }        
      }
      .rs-checkbox-wrapper {
        position: absolute;
        width: 18px;
        height: 18px;
        display: block;
        right: 0;
        top: 1px;   
        &::after,
        &::before {
          content: none;
          display: none;
        }
        .rs-checkbox-inner {
          width: 18px;
          height: 18px;
          display: block;
          &::before{
            border-radius: 2px;
            border: 1px solid $checkbox-border;
            width: 18px;
            height: 18px;
            background: $checkbox-bg;
            box-shadow: none !important;
          }
          &::after{
            border-radius: 0;
            border: 0 none;
            width: 18px;
            height: 18px;
            background: transparent url('../svg/checkmark_ico.svg') no-repeat scroll 50% 50%;
            background-size: 10px 10px;
            transform: none !important;
            margin: 0;
            padding: 0;
          }            
        }       
      }
    }
  }
  &.rs-checkbox-disabled {
    .rs-checkbox-checker {
      > label {
        opacity: 0.6;
      }
    }
  }
  &.rs-checkbox-indeterminate {
    .rs-checkbox-checker {
      display: inline-block;
      vertical-align: top;
      width: auto;
      max-width: 100%;
      padding: 0;
      min-height: 20px;
      line-height: 20px;      
      label {
        .rs-checkbox-wrapper {
          .rs-checkbox-inner {
            &::after{
              width: 8px;
              height: 2px;
              background: $H700;
              margin: -1px 0 0 -4px;
              top: 50%;
              left: 50%;
            }            
          }       
        }
      }
    }
  }
}

.rs-radio {
  margin: 0 0 20px;
  &:last-child {
    margin: 0;
  }  
  .rs-radio-checker {
    display: inline-block;
    vertical-align: top;
    width: auto;
    max-width: 100%;
    padding: 0;
    min-height: 20px;
    line-height: 20px;
    > label {
      padding: 0 30px 0 0;
      display: block;
      width: 100%;
      position: relative;
      .text {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: $radiobox-color;   
        display: block;   
        width: 100%;
        overflow: hidden;
        > span { 
          display: block;   
          width: 100%;
          overflow: hidden;
          margin: 0 0 8px;
          &.header {
            color: $body-text-second-color;
          }
          &:last-child {
            margin: 0;
          }
        }        
      }  
      .rs-radio-wrapper {
        position: absolute;
        width: 19px;
        height: 19px;
        display: block;
        right: 0;
        top: 0.5px;   
        &::after,
        &::before {
          content: none;
          display: none;
        }
        .rs-radio-inner {
          width: 19px;
          height: 19px;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          &::before{
            box-shadow: none !important;
            border: 1px solid $checkbox-border;
            background: $checkbox-bg;
            width: 19px;
            height: 19px; 
            display: block;           
          }
          &::after{
            display: block;  
            width: 9px;
            height: 9px;
            margin-top: 5px;
            margin-right: 5px;
          }           
        }
        [type='radio']:checked ~ .rs-radio-inner::before {
          border: 1px solid $checkbox-color;
          background: $checkbox-color;
        }      
      }          
    }
  }

}

.container {
  padding: 0 15px;
  max-width: 1350px;
  margin: 0 auto; 
}

.full-height-page {
  min-height: 100vh;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  > .rs-container {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
}

.site-footer {
  background: $footer-bg-1;
  color: $footer-text-color-1;
  .footer-logo {
    display: block;
    width: 100%;
    max-width: 198px;
    margin: 0;
    padding: 0;
    > a {
      display: block;
      margin: 0;
      padding: 0 0 9.090909090909091%;
      overflow: hidden;
      width: 100%;
      position: relative;
      height: 0;
      > span {
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin: 0;
        padding: 0;
        text-indent: 100%;
        font: 400 0/0 sans-serif;
        color: transparent;
        background: transparent url('../svg/logo/logo_white.svg') no-repeat scroll 50% 50%;
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
      }
      &:hover {
        opacity: 0.75;
      }
    }
  }
  p {
    display: block;
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: 100%;
    &.environment {
      direction: ltr;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
      letter-spacing: -0.006em;
      margin-top: 18px;
      &:empty {
        display: none;
      }
    }
  }
  h3 {
    display: block;
    margin: 0 0 4px;
    padding: 0;
    overflow: hidden;
    width: 100%;
    font-family: $font;
    font-weight: 500;
    color: $footer-text-color-1;
    font-size: 16px;
    line-height: 19px;
    &:last-child {
      margin: 0;
    }
  }
  .before-copyright {
    padding: 25px 0;
    ul,
    ul > li {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
    }
    ul > li {
      margin: 0 0 4px;
      &:last-child {
        margin: 0;
      }
    }
    p {
      font-size: 16px;
      line-height: 28px;
      color: rgba($footer-text-color-1, 0.8);
      a {
        color: rgba($footer-text-color-1, 0.8);
        text-decoration: none;
        font-size: 16px;
        line-height: 28px;
        display: inline-block;
        vertical-align: top;
        max-width: 100%;
        width: auto;
        overflow: hidden;
        cursor: pointer;
        &:hover {
          text-decoration: none;
          opacity: 0.75;
        }
        &.facebook,
        &.linkedin {
          display: inline-block;
          width: 20px;
          height: 20px;
          overflow: hidden;
          margin: 0 0 0 10px;
          padding: 0;
          > span {
            display: block;
            width: 20px;
            height: 20px;
            overflow: hidden;
            margin: 0;
            padding: 0;
            text-indent: 100%;
            font: 400 0/0 sans-serif;
            color: transparent;
            background-size: contain;
          }
        }

        &.facebook {
          > span {
            background: transparent url('../svg/facebook-footer.svg') no-repeat scroll 50% 50%;
          }
        }

        &.linkedin {
          > span {
            background: transparent url('../svg/linkedin-footer.svg') no-repeat scroll 50% 50%;
          }
        }
      }
    }
    .flex-container {
      .rs-flex-box-grid {
        position: relative;
        width: auto;
        margin: 0 -30px;
        > .rs-flex-box-grid-item {
          padding: 25px 30px;
        }
      }
    }
  }
  .copyright {
    color: $footer-text-color-2;
    background: $footer-bg-2;
    line-height: 28px;
    padding: 18px 0;
    a {
      color: inherit;
      line-height: inherit;
      font-weight: inherit;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    .flex-container {
      .rs-flex-box-grid {
        position: relative;
        width: auto;
        margin: 0 -30px;
        > .rs-flex-box-grid-item {
          padding: 0 30px;
        }
      }
    }    
  }
}

@media (max-width: 991px) {
  .site-footer {
    .before-copyright {
      .flex-container {
        .rs-flex-box-grid {
          > .rs-flex-box-grid-item {
            padding: 15px 30px;
          }
        }
      }
    }
  }  
}

.site-header {
  background: $header-bg-1;
  width: 100%;
  .info-header {
    min-height: 50px;
    .rs-flex-box-grid {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      width: auto;
      margin:0 -10px;
      > div {
        padding: 16px 10px;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;        
        &:nth-child(1) {
          width: 218px;
          max-width: 218px;
        }
        &:nth-child(2) {
          width: auto;
          max-width: calc(100% - 218px);
        }        
      }
    }
    .contact-info {
      a {
        cursor: pointer;
        display: inline-block;
        vertical-align: top;
        width: auto;
        max-width: 100%;
        font-family: $font;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $header-text-color-1;
        text-decoration: none;
        &:hover {
          opacity: 0.75;
        }
        .flex {
          display: block;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
          -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          -webkit-align-content: center;
          -ms-flex-line-pack: center;
          align-content: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          width: auto;
          position: relative;
          margin: 0 -5px;
          > span {
            padding: 0 5px;
            display: block;
            &:nth-child(1) {
              -webkit-order: 0;
              -ms-flex-order: 0;
              order: 0;
              -webkit-flex: 0 1 auto;
              -ms-flex: 0 1 auto;
              flex: 0 1 auto;
              -webkit-align-self: auto;
              -ms-flex-item-align: auto;
              align-self: auto;
              width: 20px;
              max-width: 20px;
            }
            &:nth-child(2) {
              -webkit-order: 0;
              -ms-flex-order: 0;
              order: 0;
              -webkit-flex: 1 1 auto;
              -ms-flex: 1 1 auto;
              flex: 1 1 auto;
              -webkit-align-self: auto;
              -ms-flex-item-align: auto;
              align-self: auto;
              width: calc(100% - 20px);
              max-width: calc(100% - 20px);             
            }   
            .rs-icon.rs-icon-mobile {
              display: block;
              width: 10px;
              height: 16px;
              background: transparent url('../svg/phone_ico.svg') no-repeat scroll 50% 50%;
              background-size: contain;
              &::before {
                content: none;
                display: none;
              }
            }  
            > span {
              display: block;
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }    
          }
        }
      }
    }
    .header-logo {
      display: inline-block;
      width: 100%;
      max-width: 198px;
      margin: 0;
      padding: 0;
      > a {
        display: block;
        margin: 0;
        padding: 0 0 9.090909090909091%;
        overflow: hidden;
        width: 100%;
        position: relative;
        height: 0;
        > span {
          display: block;
          width: 100%;
          height: 100%;
          overflow: hidden;
          margin: 0;
          padding: 0;
          text-indent: 100%;
          font: 400 0/0 sans-serif;
          color: transparent;
          background: transparent url('../svg/logo/logo.svg') no-repeat scroll 50% 50%;
          background-size: contain;
          position: absolute;
          top: 0;
          left: 0;
        }
        &:hover {
          opacity: 0.75;
        }
      }
    }
    &:last-child {
      border-bottom: 1px solid $textarea-border-color;
    }
  }
  .user-header{
    min-height: 60px;
    background: $header-bg-2;
    position: relative;
    .rs-flex-box-grid {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      margin: 0 -10px;
      position: static;
      width: auto;
      > div {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        padding: 10px 10px 12px;
        position: static;
        &:nth-child(1) {
          width: 218px;
          max-width: 218px;
        }
        &:nth-child(2) {
          width: calc(100% - 538px);
          max-width: calc(100% - 538px);
          -webkit-flex: 1 1 auto;
          -ms-flex: 1 1 auto;
          flex: 1 1 auto;          
        }
        &:nth-child(3) {
          width: 160px;
          max-width: 160px;          
        }
        &:nth-child(4) {
          width: 160px;
          max-width: 160px;          
        }                        
      }
    }
    button.link_bg, 
    a.link_bg {
      display: block;
      width: 100%;
      height: 38px;
      background: $header-link-bg;
      border-radius: 2px;
      padding: 9px 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;    
      font-family: $font; 
      color: $header-link-color;
      cursor: pointer;
      text-decoration: none;
      position: relative;
      &:hover{
        background: $header-link-bg-hover;
      }
      .rs-ripple-pond {
        display: none !important;
      }
      .flex {
        display: block;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        margin: 0 -4px;
        min-height: 20px;
        width: auto;
        > span {
          display: block;
          -webkit-order: 0;
          -ms-flex-order: 0;
          order: 0;
          -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
          flex: 0 1 auto;
          -webkit-align-self: auto;
          -ms-flex-item-align: auto;
          align-self: auto; 
          padding: 0 4px; 
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;    
          width: auto;      
          > span {
            display: block;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 0;
            padding: 0;
            &.desktop{
              display: block;
            }
            &.mobile{
              display: none;
            }
          }
          &.icon{
            width: 28px;
            height: 20px;
            position: relative;
            .rs-icon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }        
      }
      .rs-badge {
        font-family: $font;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        width: 20px;
        height: 20px;           
        vertical-align: top;
        padding: 3px 2px 1px;
        border-radius: 10px;
        background: $header-badge-bg;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; 
        text-align: center;
      }
      .rs-icon.rs-icon-shopping-cart {
        display: block;
        width: 18px;
        height: 16px;
        background: transparent url('../svg/cart_ico.svg') no-repeat scroll 50% 50%;
        background-size: contain;
        &::before {
          content: none;
          display: none;
        }
      }
      .rs-icon.rs-icon-th-large {
        display: block;
        width: 14px;
        height: 14px;
        background: transparent url('../svg/products_catalog_ico.svg') no-repeat scroll 50% 50%;
        background-size: contain;
        &::before {
          content: none;
          display: none;
        }            
      }
      .rs-icon.rs-icon-avatar {
        display: block;
        width: 14px;
        height: 14px;
        background: transparent url('../svg/sign_in_ico.svg') no-repeat scroll 50% 50%;
        background-size: contain;
        &::before {
          content: none;
          display: none;
        }            
      } 
      .rs-icon.rs-icon-sitemap {
        display: block;
        width: 20px;
        height: 20px;
        background: transparent url('../svg/materials-ico.svg') no-repeat scroll 50% 50%;
        background-size: contain;
        &::before {
          content: none;
          display: none;
        }            
      }     
      .rs-icon.rs-icon-order-form {
        display: block;
        width: 13px;
        height: 18px;
        background: transparent url('../svg/orders-ico.svg') no-repeat scroll 50% 50%;
        background-size: contain;
        &::before {
          content: none;
          display: none;
        }            
      }        
      &.no_bg {
        background: none;
        &:hover {
          opacity: 0.75;
        }
      }   
      &.transparent {
        background: none !important;
      }   
    }
    .search{
      .rs-input-group {
        background: $header-search-bg;
        border-radius: 2px;
        border: 0 none;
        width: 100%;
        height: 38px;
        overflow: hidden;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start; 
        .rs-input-group-addon {
          border: 0 none;
          border-radius: 0;
          background: $header-search-bg;
          width: 43px;
          max-width: 43px;
          height: 38px;
          padding: 11px 9px 11px 18px;
          .rs-icon.rs-icon-search {
            display: block;
            width: 16px;
            height: 16px;
            background: transparent url('../svg/search_bar_ico.svg') no-repeat scroll 0 0;
            &::before {
              content: none;
              display: none;
            }     
          }
          .rs-btn {
            border: 0 none;
            cursor: pointer;
            display: block;
            width: 16px;
            height: 16px;
            margin: 0;
            padding: 0;
            background: none;            
            .rs-ripple-pond {
              display: none !important;
            }
            &:hover {
              .rs-icon.rs-icon-search {
                background-position: 0 -16px;
              }
            }
          }
        }
        input {
          display: block;
          background: $header-search-bg;
          border-radius: 0;
          border: 0 none;
          padding: 9px 18px 9px 9px;
          font-family: $font;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;  
          color: $textarea-text-color;  
          overflow: hidden;      
          text-overflow: ellipsis;
          width: calc(100% - 43px);
          max-width: calc(100% - 43px);
          margin: 0;
        }
        input::-webkit-input-placeholder {color: $textarea-placeholder-color;opacity: 1;}
        input::-moz-placeholder          {color: $textarea-placeholder-color;opacity: 1;}
        input:-moz-placeholder           {color: $textarea-placeholder-color;opacity: 1;}
        input:-ms-input-placeholder      {color: $textarea-placeholder-color;opacity: 1;}          
      }
      .link_search {
        display: none;
      }
    }
    &.supplier {
      .rs-flex-box-grid {
        > div {
          &:nth-child(1) {
            width: 218px;
            max-width: 218px;
          }
          &:nth-child(2) {
            width: calc(100% - 708px);
            max-width: calc(100% - 708px);
            -webkit-flex: 1 1 auto;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;          
          }
          &:nth-child(3) {
            width: 160px;
            max-width: 160px;          
          }
          &:nth-child(4) {
            width: 190px;
            max-width: 190px;          
          }    
          &:nth-child(5) {
            width: 140px;
            max-width: 140px;            
          }                                
        }        
      }
    }
  }
  .user-header_supplier{
    .rs-flex-box-grid{
      justify-content: space-between;
      >div:nth-child(1){
        width: 150px;
      }
      >div:nth-child(2) {
        max-width: 140px;
        width: 140px;
      }
    }
  }
}

@media (max-width: 991px) {
  .site-header {
    .user-header{
      .rs-flex-box-grid {
        margin: 0 -5px;
        > div {
          padding: 10px 5px 12px;
          &:nth-child(1){
            width: calc(100% - 174px);
            max-width: calc(100% - 174px);
            a.catalog {
              span.title {
                display: block;
                > span.desktop {
                  display: none;
                }
                > span.mobile {
                  display: block;
                }                  
              }
            }              
          }
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            width: 58px;
            max-width: 58px;
            button.link_bg, 
            a.link_bg {
              .flex {
                position: static;
              }              
              span.title {
                display: none;
              }
              &.no_bg {
                background: $header-link-bg;
                &:hover{
                  background: $header-link-bg-hover;
                }                
              }
              span.badge {
                position: absolute;
                top: 1px;
                left: 1px;
                padding: 0;
                .rs-badge {
                  width: 18px;
                  height: 18px;
                  font-size: 12px;
                  line-height: 14px;
                }
              }
            }         
          }                        
        }
      }      
      .search{
        .rs-input-group {
          display: none;
          height: 38px;
        }
        .link_search {
          display: block;
        }
        &.active {
          position: absolute;
          top: 0;
          left: 0;
          padding: 10px 15px;
          z-index: 10;
          background: $header-bg-2;
          height: 60px;
          width: 100%;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
          -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-align-content: flex-start;
          -ms-flex-line-pack: start;
          align-content: flex-start;
          -webkit-align-items: flex-start;
          -ms-flex-align: start;
          align-items: flex-start;         
          .rs-input-group {
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            width: calc(100% - 43px);
            max-width: calc(100% - 43px);            
          }
          .link_search {
            width: 38px;
            max-width: 38px;
            background: none;
            &:hover {
              opacity: 0.75;
            }            
            .rs-icon-search {
              &::before {
                content: "";
                display: block;
                width: 12px;
                height: 12px;
                background: transparent url('../svg/delete_button_white.svg') no-repeat scroll 50% 50%;
              }
            }
          }
        }
      }
      &.supplier {
        .rs-flex-box-grid {
          > div {
            &:nth-child(1) {
              width: calc(100% - 426px);
              max-width: calc(100% - 426px);
            }
            &:nth-child(2), 
            &:nth-child(3) {
              width: 58px;
              max-width: 58px;   
            }  
            &:nth-child(4) {
              width: 170px;
              max-width: 170px; 
              button.link_bg,
              a.link_bg {
                span.title {
                  display: block;
                }
              }
              a.materials {
                span.title {
                  display: block;
                  > span.desktop {
                    display: none;
                  }
                  > span.mobile {
                    display: block;
                  }                  
                }
              }                                             
            }
            &:nth-child(5) {
              button.link_bg,
              a.link_bg {
                span.title {
                  display: block;
                }
              }    
            }                                
          }        
        }
      }
    }
    .user-header_supplier{
      .rs-flex-box-grid{
        >div:nth-child(1){
          width: 150px;
        }
        >div:nth-child(2) {
          max-width: 140px;
          width: 140px;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .site-header {
    .info-header {
      .rs-flex-box-grid {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        width: auto;
        margin:0 -10px;
        > div {
          &:nth-child(1) {
            width: calc(100% - 40px);
            max-width: 218px;
          }
          &:nth-child(2) {
            width: 40px;
            max-width: 40px;
          }        
        }
      }
      .contact-info {
        a {
          .flex {
            > span {
              &:nth-child(1) {
                width: 40px;
                max-width: 40px;
              }
              &:nth-child(2) {
                display: none;            
              }   
              .rs-icon.rs-icon-mobile {
                width: 20px;
                height: 20px;
              }  
            }
          }
        }
      }
      .header-logo {
        display: inline-block;
        width: 100%;
        max-width: 198px;
        margin: 0;
        padding: 0;
        > a {
          display: block;
          margin: 0;
          padding: 0 0 9.090909090909091%;
          overflow: hidden;
          width: 100%;
          position: relative;
          height: 0;
          > span {
            display: block;
            width: 100%;
            height: 100%;
            overflow: hidden;
            margin: 0;
            padding: 0;
            text-indent: 100%;
            font: 400 0/0 sans-serif;
            color: transparent;
            background: transparent url('../svg/logo/logo.svg') no-repeat scroll 50% 50%;
            background-size: contain;
            position: absolute;
            top: 0;
            left: 0;
          }
          &:hover {
            opacity: 0.75;
          }
        }
      }
    }    
    .user-header{
      .rs-flex-box-grid {
        > div {
          &:nth-child(1){
            width: calc(100% - 144px);
            max-width: calc(100% - 144px);
          }
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            width: 48px;
            max-width: 48px; 
            button.link_bg,
            a.link_bg {
              span.title {
                display: none;
              }
              span.badge {
                left: auto;
                right: 50%;
                transform: translateX(50%);
                margin-right: 12px;
              }
            }         
          }                        
        }
      } 
      &.supplier {
        .rs-flex-box-grid {
          > div {
            &:nth-child(1) {
              display: none;
            }
            &:nth-child(2) {
              width: 48px;
              max-width: 48px;
            }
            &:nth-child(3) {
              display: none;
            }            
            &:nth-child(4) {
              width: calc(50% - 10px);
              max-width: calc(50% - 10px);
            }
            &:nth-child(5) {
              width: calc(50% - 38px);
              max-width: calc(50% - 38px); 
            }                                
          }   
        }
      }     
    }
  }
}

.main_container {
  min-height: 100%;
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;

  section {
    width: 100%;
  }
}

.checkout {
  min-height: 100%;
  padding: 48px 0;
  background: $main-bg-light-grey;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  width: 100%;  
  &.white_bg {
    background: transparent;
  }
  h1 {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: $main-header-color;
  }
  .checkout-flex-col {
    .warning_box {
      margin-bottom: 15px;
      border: 1px solid $textarea-border-color;
    }

    .time_constraints{
      .text {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: $radiobox-color;
        display: block;
        width: 100%;
        overflow: hidden;

        > span {
          display: block;
          width: 100%;
          overflow: hidden;
          margin: 0 0 8px;
          &.header {
            color: $body-text-second-color;
          }
        }
      }
    }
    div.rs-flex-box-grid {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      position: relative;
      margin: 0 -20px;
      > div {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        padding: 20px;
      }
      &.flex-420-col {
        > div {
          &:nth-child(1) {
            width: calc(100% - 460px);
            max-width: calc(100% - 460px);
          }
          &:nth-child(2) {
            width: 460px;
            max-width: 460px;
          } 
        }
      }
    }
  }
  .rs-panel {
    background: $panel-bg-color;
    border: 1px solid $panel-border-color;
    border-radius: 0;
    padding: 25px;
    margin: 0 0 10px;
    &.gray_bg {
      background: $main-bg-light-grey;
      border: 1px solid $main-bg-light-grey;
    }
    &:last-child {
      margin: 0;
    }
    .rs-panel-body {
      padding: 0;
      margin: 0;
      background: none;
      border: 0 none;
    }
    &.opacity_50 {
      background: rgba($panel-bg-color, 0.6);
      border: 1px solid rgba($panel-border-color, 0.6);
    }    
  }
  .panel_edit {
    &.gray_bg {
      background: $main-bg-light-grey;
      border: 1px solid $main-bg-light-grey;
      padding: 18px 15px;
    }
    p{
      width: 100%;
      overflow: hidden;
      padding: 0;
      margin: 8px 0;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: $panel-p-color;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }   
      &.header {
        font-size: 18px;
        line-height: 22px;
        color: $body-text-second-color;
        font-weight: 500;
        .light {
          color: $short-description-color;
          font-weight: 400;
        }        
      }
      &.invoice_link{
        color: #0C0C0C;
        a {
          color: #0C0C0C;
          opacity: 0.6;
        }
      }  
      &.m20 {
        margin: 20px 0;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }           
      }
      &.edit {
        color: $panel-a-color;
        a {
          cursor: pointer;
          display: inline-block;
          vertical-align: top;
          width: auto;
          max-width: 100%;
          font-style: inherit;
          font-weight: inherit;
          font-size: inherit;
          line-height: inherit; 
          text-decoration: underline; 
          color: inherit;
          &:hover {
            text-decoration: none;
          }
          > span {
            display: block;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;            
          }    
        }
      } 
      &.label {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        margin: 0 0 8px;
        color: $body-text-second-color;
        &:last-child {
          margin-bottom: 0;
        }           
      }
      &.upload_info {
        a {
          color: $H500;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
            color: $H700;
          }
        }
        strong {
          font-weight: 500;
          color: $main-header-color;
        }
      }      
    }
    .rs-btn.info {
      display: block;
      width: 26px;
      height: 14px;
      margin: 4px 0;
      padding: 0;
      border: 0 none;
      border-radius: 0;
      background: none;
      .rs-icon {
        background: transparent url('../svg/info-ico-grey.svg') no-repeat scroll 50% 50%;
        background-size: contain;
        display: block;
        width: 14px;
        height: 14px;
        margin: 0 auto;
        padding: 0;
        border: 0 none;
        border-radius: 0; 
        &::before {
          content: none;
          display: none;
        }       
      }
      .rs-ripple-pond {
        display: none !important;
      }
      &:hover {
        opacity: 0.75;
      }      
    }
    .flex-container {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      width: auto;
      position: relative;
      margin: 0 -10px;
      .flex-item {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        padding: 0 10px;
        width: calc(100% - 70px);
        max-width: calc(100% - 70px);
        &.item-edit {
          width: 70px;
          max-width: 70px;
          -webkit-order: 0;
          -ms-flex-order: 0;
          order: 0;
          -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
          flex: 0 1 auto;
          -webkit-align-self: auto;
          -ms-flex-item-align: auto;
          align-self: auto;   
          text-align: left;       
        }
        &:first-child:last-child {
          width: 100%;
          max-width: 100%;          
        }
      }
      &.info-tooltip {
        .flex-item {
          &:nth-child(1) {
            width: calc(100% - 46px);
            max-width: calc(100% - 46px);
          }
          &:nth-child(2) {
            width: 46px;
            max-width: 46px;
          }          
        }
      }
    }
    &.opacity_50 {
      p {
        opacity: 0.6;
      }
    }  
    .step_flex {
      position: relative;
      margin: 0 -3px;
      width: auto;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      > span {
        padding: 0 3px;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        width: auto;
        max-width: 100%;        
      }      
    }       
  }
  .checkbox_list {
    ul,
    ul > li {
      display: block;
      margin: 0;
      padding: 0;
      list-style: none;
    }
    ul {
      margin: 12px -15px 0;
      width: auto;
      position: relative;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;      
      &:first-child {
        margin: 0 -15px;
      }
      > li {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        padding: 10px 15px;
        width: 33.33333333333333%;
        max-width: 33.33333333333333%;
      }
      &.full-width{
        >li{
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
  .rs-form-control-wrapper {
    textarea {
      display: block;
      width: 100%;
      background: $textarea-bg-color;
      border: 1px solid $textarea-border-color;
      border-radius: 2px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      resize: none;
      color: $textarea-text-color;  
      padding: 16px;    
    }
    textarea::-webkit-input-placeholder {color: $textarea-placeholder-color;opacity: 1;}
    textarea::-moz-placeholder          {color: $textarea-placeholder-color;opacity: 1;}
    textarea:-moz-placeholder           {color: $textarea-placeholder-color;opacity: 1;}
    textarea:-ms-input-placeholder      {color: $textarea-placeholder-color;opacity: 1;}  
    textarea:hover,
    textarea:focus {
      border: 1px solid $textarea-border-color-active;
    } 
  }
  .project-list-flex {
    margin-top: 35px;
    max-height: 247px;
    overflow: auto;
    &:first-child {
      margin: 0;
    }
    .flex-row {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      margin: 0;
      width: auto;
      border-bottom: 1px solid $panel-border-color;
      &:last-child {
        border: 0 none;
      }
      > div {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        padding: 20px 7.5px;
        &:nth-child(1) {
          // width: 70px;
          // max-width: 70px;
          width: 0;
          max-width: 0;          
          padding-right: 0;
          display: none;
        }
        &:nth-child(2),
        &:nth-child(3) {
          -webkit-flex: 1 1 auto;
          -ms-flex: 1 1 auto;
          flex: 1 1 auto;     
          // width: calc(50% - 102.5px);
          // max-width: calc(50% - 102.5px);
          width: calc(50% - 67.5px);
          max-width: calc(50% - 67.5px);          
        }  
        &:nth-child(4) {
          width: 135px;
          max-width: 135px;
          padding-left: 0;
        }
        .rs-btn {
          display: block;
          width: 100%;
        }              
      }
      &:first-child  {
        > div {
          padding-top: 0;
        }
      }
      &:last-child  {
        > div {
          padding-bottom: 0;
        }
      }      
      .img-box {
        width: 55px;
        height: 55px;
        overflow: hidden;
        margin: 0;
        padding: 0;
        position: relative; 
        > img {
          display: block;
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
      p {
        display: block;
        width: 100%;
        overflow: hidden;
        margin: 0 0 6px;
        padding: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;  
        color: $panel-p-color;      
        &.name {
          color: $main-header-color;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .search{
    margin: 20px 0 35px;
    .rs-input-group {
      border-radius: 2px;
      background: $textarea-bg-color;
      border: 1px solid $textarea-border-color;
      width: 100%;
      height: 38px;
      overflow: hidden;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;        
      .rs-input-group-addon {
        border: 0 none;
        border-radius: 0;
        background: $header-search-bg;
        width: 27px;
        max-width: 27px;
        height: 38px;
        padding: 11px 11px 11px 0;
        .rs-icon.rs-icon-search {
          display: block;
          width: 16px;
          height: 16px;
          background: transparent url('../svg/search_bar_ico.svg') no-repeat scroll 0 0;
          &::before {
            content: none;
            display: none;
          }     
        }
      }
      input {
        display: block;
        background: $header-search-bg;
        border-radius: 0;
        border: 0 none;
        padding: 9px 11px;
        font-family: $font;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;  
        color: $textarea-text-color;
        overflow: hidden;      
        text-overflow: ellipsis;
        width: calc(100% - 27px);
        max-width: calc(100% - 27px);
        margin: 0;
      }
      input::-webkit-input-placeholder {color: $textarea-placeholder-color;opacity: 1;}
      input::-moz-placeholder          {color: $textarea-placeholder-color;opacity: 1;}
      input:-moz-placeholder           {color: $textarea-placeholder-color;opacity: 1;}
      input:-ms-input-placeholder      {color: $textarea-placeholder-color;opacity: 1;}  
      &:focus,
      &:hover,
      &.rs-input-group-focus {
        border: 1px solid $textarea-border-color-active !important;
      }  
    }
    .link_search {
      display: none;
    }
  }
  .switch_buttons {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    > div {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
      width: 50%;
      max-width: 50%;
      .rs-btn.rs-btn-ghost {
        display: block;
        width: 100%;
        margin: 0;
        padding: 8px;
        border-radius: 0;
        border: 1px solid $textarea-border-color;
        color: $main-header-color;
        font-size: 15px;        
        line-height: 20px;
        font-weight: 400;
        cursor: pointer;
        > span {
          display: block;
          width: 100%;
          margin: 0;
          padding: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        &:hover {
          border: 1px solid $H500;
          color: $H500;
        }
        &.active {
          cursor: default;
          border: 1px solid $H500;
          color: $H500;
          font-weight: 500;
        }
      }
      &:first-child {
        .rs-btn.rs-btn-ghost{
          -webkit-border-top-right-radius: 2px;
          -webkit-border-bottom-right-radius: 2px;
          -moz-border-radius-topright: 2px;
          -moz-border-radius-bottomright: 2px;
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;    
        }
      }
      &:last-child {
        .rs-btn.rs-btn-ghost{
          -webkit-border-top-left-radius: 2px;
          -webkit-border-bottom-left-radius: 2px;
          -moz-border-radius-topleft: 2px;
          -moz-border-radius-bottomleft: 2px;
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;                
        } 
      }
    }
  }
  .new_tab_option {
    margin: 20px 0 0;
    &:first-child {
      margin: 0;
    }
    div.rs-flex-box-grid {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      margin: 20px -10px;
      width: auto;
      position: relative;
      &:first-child {
        margin-top: 0;
      }      
      &:last-child {
        margin-bottom: 0;
      }
      &.label_address {
        margin-bottom: 9px;
      }
      &.label_address + div.rs-flex-box-grid {
        margin-top: 9px;
      }   
      &.save_button {
        margin-top: 26px;
      }   
      > div {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        padding: 0 10px;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }
    label {
      position: relative;
      display: inline-block;
      vertical-align: top;
      width: auto;
      max-width: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      padding: 0;
      margin: 0 0 9px;
      &:last-child {
        margin: 0;
      }
      &.required {
        padding-right: 8px;
        &::before{
          content: "\002A";
          position: absolute;
          color: $H500;
          top: 0;
          right: 0;
          font-size: 16px;
          line-height: 19px;
          display: block;
          width: 8px;
          height: 19px;
          overflow: hidden;
        }
      }      
    }
    .rs-input {
      display: block;
      width: 100%;
      background: $textarea-bg-color;
      border: 1px solid $textarea-border-color;
      border-radius: 2px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      resize: none;
      color: $textarea-text-color;  
      padding: 10px 16px;    
    }
    .rs-input::-webkit-input-placeholder {color: $textarea-placeholder-color;opacity: 1;}
    .rs-input::-moz-placeholder          {color: $textarea-placeholder-color;opacity: 1;}
    .rs-input:-moz-placeholder           {color: $textarea-placeholder-color;opacity: 1;}
    .rs-input:-ms-input-placeholder      {color: $textarea-placeholder-color;opacity: 1;}  
    .rs-input:hover,
    .rs-input:focus {
      border: 1px solid $textarea-border-color-active;
    } 
    .rs-btn {
      max-width: 154px;
    }    
  }
}

@media (max-width: 991px) {
  .checkout {
    .checkout-flex-col {
      div.rs-flex-box-grid {
        &.flex-420-col {
          > div {
            &:nth-child(1),
            &:nth-child(2) {
              width: 100%;
              max-width: 100%;
            } 
          }
        }
      }
    }    
    .new_tab_option {
      div.rs-flex-box-grid {
        > div + div {
          margin-top: 15px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .checkout {
    .checkbox_list {
      ul {
        > li {
          width: 100%;
          max-width: 100%;
        }
      }
    }
    .project-list-flex {
      .flex-row {
        > div {
          &:nth-child(2) {
            -webkit-flex: 1 1 auto;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;     
            // width: calc(100% - 70px);
            // max-width: calc(100% - 70px);
            width: 100%;
            max-width: 100%;            
            padding-left: 0;
            padding-right: 0;
          }  
          &:nth-child(3) {
            -webkit-flex: 1 1 auto;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;     
            width: 100%;
            max-width: 100%;
            // padding-right: 77.5px;
            padding-left: 0;
            padding-right: 0;            
          }    
          &:nth-child(4) {
            -webkit-flex: 1 1 auto;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;     
            width: 100%;
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
          }                   
        }
        &:not(:first-child):not(:last-child) {
          > div {
            &:nth-child(3) {
              padding-top: 0;
            } 
          }
        }
      }
    }    
  }
}

.order-component {
  .header {
    display: block;
    overflow: hidden;
    width: 100%;
    margin: 0 0 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $body-text-second-color;    
    &:last-child {
      margin: 0;
    }
  }
  .grey_box {
    padding: 22px 18px;
    background: $main-bg-light-grey;
    border-radius: 2px;
    margin: 22px 0;
    &.white_bg {
      background: $body-bg-white;
      border: 1px solid $panel-border-color;
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }    
    p {
      display: block;
      width: 100%;
      overflow: hidden;
      padding: 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: $body-text-second-color;      
      margin: 0 0 9px;
      &.price{
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
      }
      &.vat{
        color: $body-text-fourth-color;
        margin-bottom: 28px;
      }
      &.bold{
        font-weight: 500 !important;
      }      
    }
  }
  .submit_button {
    margin: 22px 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }    
  }
  .agreement_text {
    p {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      text-align: center;      
      color: $panel-p-color;
      text-align: center;
      a {
        color: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }      
    }
  }
  .toggle_hide_view {
    margin: 30px 0 0;
    &:first-child {
      margin: 0;
    } 
    .rs-btn.rs-btn-link {
      display: inline-block;
      vertical-align: top;
      width: auto;
      max-width: 100%;
      margin: 0;
      padding: 0;
      font-size: 16px;
      line-height: 20px;
      color: $body-text-second-color;
      background: none;
      border-radius: 0;
      cursor: pointer;
      text-decoration: none;
      .flex {
        display: block;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        margin: 0 -4px;
        min-height: 20px;
        width: auto;
        > span {
          display: block;
          -webkit-order: 0;
          -ms-flex-order: 0;
          order: 0;
          -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
          flex: 0 1 auto;
          -webkit-align-self: auto;
          -ms-flex-item-align: auto;
          align-self: auto; 
          padding: 0 4px; 
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;          
          > span {
            display: block;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 0;
            padding: 0;
          }
          &:nth-child(1) {
            text-decoration: underline;
          }
          .rs-icon {
            display: block;
            width: 12px;
            height: 8px;
            overflow: hidden;
            margin: 0;
            padding: 0;
            overflow: hidden;
            &.open {
              background: transparent url('../svg/dropdown_open.svg') no-repeat scroll 50% 50%;
              background-size: contain;
            }
            &.close {
              background: transparent url('../svg/dropdown_close.svg') no-repeat scroll 50% 50%;
              background-size: contain;              
            }
          }            
        }        
      } 
      &:hover {
        .flex {
          > span {
            text-decoration: none;
          }
        }
      }     
    }
  }
  .products-list {
    max-height: 427px;
    width: 100%;
    overflow: auto;
    ul,
    ul > li {
      display: block;
      margin: 0;
      padding: 0;
      list-style: none;
    }
    ul > li {
      padding: 20px 0;
      border-bottom: 1px solid $panel-border-color;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
        border: 0 none;
      }
    }
    p {
      display: block;
      margin: 0 0 5px;
      padding: 0;
      width: 100%;
      overflow: hidden;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      color: $panel-p-color;
      &.description{
        color: $main-header-color;
        margin: 0 0 8px;
      }
      &.price-all{
        color: $main-header-color;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }      
      &:last-child {
        margin: 0;
      }
    }
    .products-img {
      width: 80px;
      height: 80px;
      overflow: hidden;
      margin: 0 0 5px;
      padding: 0;
      position: relative;
      border: 1px solid $panel-border-color;
      background: $body-bg-white;
      > img {
        display: block;
        width: auto;
        height: auto;
        max-width: calc(100% - 10px);
        max-height: calc(100% - 10px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:last-child {
        margin: 0;
      }
    }
    p.quantity{
      padding: 7px 3px;
      font-size: 14px;
      line-height: 16px;
      color: $main-header-color;
      text-align: center;
      background: $main-bg-light-grey;
      width: 80px;
      > span {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
        overflow: hidden;
      }
    }
    .products-list-flex {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      margin: 0;
      position: relative;
      width: auto;
      > div {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        &:nth-child(1) {
          width: 94px;
          max-width: 94px;
          padding: 0 0 0 7px;
        }
        &:nth-child(2) {
          width: calc(100% - 94px);
          max-width: calc(100% - 94px);
          padding: 0 7px 0 0;
        }        
      }
    }
  }
}

.rs-picker-date-menu{
  background: $calendar-bg;
  border: 1px solid $calendar-border-color;
  box-shadow: 0px 2px 3px $calendar-box-shadow-color;
  border-radius: 2px;
  .rs-calendar {
    padding-top: 14px;
    background: none;
    border: 0 none;
    box-shadow: none;
    border-radius: 0;
    width: 290px;
    .rs-calendar-header {
      padding: 0 18px;
      .rs-calendar-header-month-toolbar {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        margin: 0;
        direction: ltr; 
        &::before,
        &::after{
          content: none;
          display: none;
        }
        &.changeUi{
          .rs-calendar-header-backward{
            -webkit-order: 3;
            -ms-flex-order: 3;
            order: 3;
            transform: rotate(180deg);
          }
          .rs-calendar-header-forward{
            transform: rotate(180deg);
          }
          .rs-calendar-header-title{
            -webkit-order: 1;
            -ms-flex-order: 1;
            order: 1;
          }
        }   
        .rs-calendar-header-backward {
          -webkit-order: 0;
          -ms-flex-order: 0;
          order: 0;
          -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
          flex: 0 1 auto;
          -webkit-align-self: auto;
          -ms-flex-item-align: auto;
          align-self: auto;
          float: none;
          padding: 0;
          cursor: pointer;
          width: 14px;
          height: 18px;
          color: transparent;
          background: transparent url('../svg/rs-calendar/backward.svg') no-repeat scroll 50% 50%;  
          background-size: contain;
          text-indent: 100%;              
          font: 400 0/0 sans-serif;
          &:hover {
            opacity: 0.75;
          }
        }
        .rs-calendar-header-forward {
          -webkit-order: 0;
          -ms-flex-order: 0;
          order: 0;
          -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
          flex: 0 1 auto;
          -webkit-align-self: auto;
          -ms-flex-item-align: auto;
          align-self: auto;
          float: none;
          padding: 0;
          cursor: pointer;
          width: 14px;
          height: 18px;
          color: transparent;
          background: transparent url('../svg/rs-calendar/forward.svg') no-repeat scroll 50% 50%;  
          background-size: contain;
          text-indent: 100%;              
          font: 400 0/0 sans-serif;  
          &:hover {
            opacity: 0.75;
          }              
        }
        .rs-calendar-header-title {
          -webkit-order: 0;
          -ms-flex-order: 0;
          order: 0;
          -webkit-flex: 1 1 auto;
          -ms-flex: 1 1 auto;
          flex: 1 1 auto;
          -webkit-align-self: auto;
          -ms-flex-item-align: auto;
          align-self: auto;
          float: none;
          padding: 0 4px;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          color: $body-text-second-color;    
          white-space: nowrap;    
          text-overflow: ellipsis;
          overflow: hidden;
          background: none;
          cursor: default;
          // pointer-events: none;
        }
      }
    }
    .rs-calendar-view {
      padding: 18px 10px 21px;
      .rs-calendar-table {
        .rs-calendar-table-row {
          .rs-calendar-table-cell {
            padding: 0;
            vertical-align: top;
            height: 32px;
            .rs-calendar-table-cell-content {
              width: 32px;
              height: 32px;
              padding: 0;
              background: none;
              display: inline-block;
              vertical-align: top;    
              border-radius: 0;        
              > span {
                width: 32px;
                height: 32px;
                font-size: 12px;
                line-height: 20px; 
                margin: 0;           
                padding: 6px;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: $body-text-second-color;
                background: none;
                display: block;
                border-radius: 50%;
                &:hover {
                  background: $H050;
                }              
              }
            }
            &.rs-calendar-table-cell-is-today{
              .rs-calendar-table-cell-content {
                border: 0 none;
                > span {
                  border: 1px solid $H500;
                  padding: 5px;
                }              
              }
            }
            &.rs-calendar-table-cell-selected {
              .rs-calendar-table-cell-content {
                > span {
                  background: $H500;
                  color: $body-bg-white;
                  &:hover {
                    background: $H700;
                  }                
                }
              }
            }
            &.rs-calendar-table-cell-un-same-month {
              .rs-calendar-table-cell-content { 
                > span {
                  color: $calendar-un-same-month-color;
                  &:hover {
                    background: rgba($calendar-un-same-month-color, 0.125);
                  }                 
                }
              }
            }
            &.rs-calendar-table-cell-disabled {
              .rs-calendar-table-cell-content {
                > span {
                  text-decoration: line-through;
                  opacity: 0.3;
                  &:hover {
                    background: none;
                  }              
                }
              }
              &.rs-calendar-table-cell-selected {
                .rs-calendar-table-cell-content {
                  > span {
                    text-decoration: line-through;
                    opacity: 0.3;                    
                    &:hover {
                      background: $H500;
                    }                
                  }
                }
              }              
            }
          }
          &.rs-calendar-table-header-row {
            .rs-calendar-table-cell {
              .rs-calendar-table-cell-content {
                > span {
                  border-radius: 0;
                  color: $calendar-header-color;
                  padding: 6px 2px;
                  background: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .rs-picker-toolbar {
    padding: 6px 16px;
    border-top: 1px solid $calendar-delimiter-color;
    &::before,
    &::after {
      content: none;
      display: none;
    }
    > div {
      float: none;
      &.rs-picker-toolbar-ranges {
        display: none;
      }
      .rs-picker-toolbar-right-btn-ok {
        padding: 11px 7px;
        margin: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: $body-text-white-color;  
        border-radius: 2px;
        background: $H500;
        display: inline-block;
        vertical-align: top;
        width: auto;
        max-width: 100%;
        height: 42px;
        min-width: 60px;
        cursor: pointer;
        text-transform: capitalize;
        .rs-ripple-pond {
          display: none !important;
        }
        &:hover {
          background: $H700;
        }
        > span {
          display: block;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        } 
        &.rs-btn-block {
          display: block;
          width: 100%;
        }
        &[disabled],
        &.rs-btn-disabled {
          background: $bg-disabled;
          opacity: 1;
          cursor: not-allowed !important;
        }
      }       
    }
  }  
  .rs-calendar-show-time-dropdown {
    padding: 0;
    height: 265px;
    min-height: 265px;
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 1px;
      height: 100%;
      background: $calendar-border-color;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
    }    
    .rs-calendar-header-has-time {
      display: none;
    }
    .rs-calendar-time-dropdown {
      padding: 0;
      min-height: 262px;
      height: 262px;
      position: relative;
      top: auto;
      background: none;
      .rs-calendar-time-dropdown-content {
        animation: none;
        min-height: 262px;
        height: 262px;
        .rs-calendar-time-dropdown-row {
          min-height: 262px;
          height: 262px;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
          -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          -webkit-align-content: stretch;
          -ms-flex-line-pack: stretch;
          align-content: stretch;
          -webkit-align-items: stretch;
          -ms-flex-align: stretch;
          align-items: stretch;
          direction: ltr;
          .rs-calendar-time-dropdown-column {
            width: 50%;
            max-width: 50%;
            display: block;
            -webkit-order: 0;
            -ms-flex-order: 0;
            order: 0;
            -webkit-flex: 1 1 auto;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            -webkit-align-self: auto;
            -ms-flex-item-align: auto;
            align-self: auto;   
            direction: rtl;
            .rs-calendar-time-dropdown-column-title {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 18px;
              text-align: center;
              color: $body-text-second-color;
              padding: 14px 10px;
              background: none;
              > span {
                display: block;
                width: 100%;
                margin: 0;
                padding: 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
            > ul {
              height: 216px;
              padding: 0;
              margin: 0;
              /*
              overflow-y: scroll;
              scrollbar-width: none;
              -ms-overflow-style: none;
              &::-webkit-scrollbar {
                  width: 0;
                  height: 0;
              } */       
              .rs-calendar-time-dropdown-cell {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                cursor: pointer;
                text-decoration: none;
                background: none;
                width: 100%;
                display: block;
                height: 36px;
                padding: 9px;
                margin: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                color: $body-text-second-color;
                &:hover {
                  opacity: 0.75;
                }
                &.rs-calendar-time-dropdown-cell-active {
                  background: $H500;
                  color: $body-text-white-color;
                  &:hover {
                    opacity: 1;
                    background: $H700;
                  }                  
                }
                &[data-key$="-0"],
                &[data-key$="-1"],
                &[data-key$="-2"],
                &[data-key$="-3"],
                &[data-key$="-4"],
                &[data-key$="-5"],
                &[data-key$="-6"],
                &[data-key$="-7"],
                &[data-key$="-8"],
                &[data-key$="-9"] {
                  &::before{
                    content: "0";
                    display: inline;
                  }
                }
              }
            }
          }
        }        
      }
    }
  }
  .rs-calendar-month-dropdown-row {
    padding-right: 42px;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;    
    .rs-calendar-month-dropdown-year{
      font-size: 14px;
      line-height: 18px;
      right: 5px;
    }
    .rs-calendar-month-dropdown-cell {
      width: calc(100% / 6);
      margin: 0; 
      padding: 2px;
      &::before {
        content: "";
        display: inline-block;
        font-size: 12px;
        line-height: 20px;
        padding: 5px 2px;
        border-radius: 4px;
        width: 100%;
        height: 30px; 
        cursor: pointer;  
        color: $body-text-color;    
        text-align: center; 
      }      
      .rs-calendar-month-dropdown-cell-content {
        display: none !important;
      }
      &:hover {
        &::before {
          color: $body-text-color;
          background-color: $H050;
        }
      }
      &.rs-calendar-month-dropdown-cell-active,
      &.rs-calendar-month-dropdown-cell-active:hover {
        &::before {
          color: $body-text-white-color;
          background-color: $H500;
        }   
      }      
    }
    .rs-calendar-month-dropdown-cell:nth-child(1) {
      &::before {
        content: "'ינו";
      }
    }
    .rs-calendar-month-dropdown-cell:nth-child(2) {
      &::before {
        content: "'פבר";
      }
    }  
    .rs-calendar-month-dropdown-cell:nth-child(3) {
      &::before {
        content: "מרץ";
      }
    }
    .rs-calendar-month-dropdown-cell:nth-child(4) {
      &::before {
        content: "'אפר'";
      }
    }
    .rs-calendar-month-dropdown-cell:nth-child(5) {
      &::before {
        content: "מאי";
      }
    }
    .rs-calendar-month-dropdown-cell:nth-child(6) {
      &::before {
        content: "'יונ";
      }
    }
    .rs-calendar-month-dropdown-cell:nth-child(7) {
      &::before {
        content: "'יול";
      }
    }
    .rs-calendar-month-dropdown-cell:nth-child(8) {
      &::before {
        content: "'אוג";
      }
    }
    .rs-calendar-month-dropdown-cell:nth-child(9) {
      &::before {
        content: "'ספט";
      }
    }
    .rs-calendar-month-dropdown-cell:nth-child(10) {
      &::before {
        content: "'אוק";
      }
    }
    .rs-calendar-month-dropdown-cell:nth-child(11) {
      &::before {
        content: "'נוב";
      }
    }
    .rs-calendar-month-dropdown-cell:nth-child(12) {
      &::before {
        content: "'דצמ";
      }
    }
  }
}

.rs-picker-date {
  .rs-picker-toggle {
    display: block;
    width: 100%;
    background: $textarea-bg-color;
    border: 1px solid $textarea-border-color;
    border-radius: 2px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    resize: none;
    color: $textarea-text-color;  
    padding: 8px 15px !important; 
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;    
    &.active,
    &:hover {
      border: 1px solid $textarea-border-color-active !important;
    }
    .rs-picker-toggle-value {
      color: $textarea-text-color;
    }
    .rs-picker-toggle-placeholder {
      color: $textarea-placeholder-color;
    }
    .rs-picker-toggle-placeholder,
    .rs-picker-toggle-value {
      display: block;
      padding: 0 0 0 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: calc(100% - 24px);
      max-width: calc(100% - 24px);
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;      
    }
    .rs-picker-toggle-caret {
      display: block;
      margin: 0;
      padding: 0;
      width: 24px;
      max-width: 24px;
      height: 24px;
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;  
      position: static !important;
      top: auto !important;
      left: auto !important;
      bottom: auto !important;
      right: auto !important;           

    }
  }
  &.date {
    .rs-picker-toggle-caret {
      &::before {
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        right: auto;
        content: "" !important;
        width: 24px;
        height: 24px;     
        display: block;  
        background: transparent url('../svg/rs-calendar/date.svg') no-repeat scroll 50% 50%; 
        background-size: contain;        
      }       
    }
  }
  &.time {
    .rs-picker-toggle-caret {
      &::before {
        content: "" !important;
        width: 24px;
        height: 24px;     
        display: block;  
        background: transparent url('../svg/rs-calendar/time.svg') no-repeat scroll 50% 50%; 
        background-size: contain;        
      }       
    }
  }  
}

.successful-order {
  min-height: 100%;
  padding: 48px 0;
  background: $main-bg-light-grey;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  width: 100%;   
  .successful_order_box {
    max-width: 500px;
    margin: 0 auto;
  }
  p {
    display: block;
    width: 100%;
    margin: 20px 0;
    padding: 0;
    overflow: hidden;
    text-align: center;
    font-size: 16px;
    line-height: 20px; 
    color: $panel-p-color;   
    > span {
      display: block;
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }    
    &.header {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      color: $main-header-color;
    }
  }
  .banner {
    position: relative;
    width: 100%;
    height: 0;
    padding: 0 0 40%;
    margin: 0;
    background: $banner-bg;
    > span {
      position: absolute;
      display: block;
      width: calc(100% - 20px);
      max-width: 32.4%;
      overflow: hidden;
      margin: 0;
      padding: 0;
      transform: translate(-50%,-50%);
      top: 50%;
      left: 50%;
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 0;
        padding: 0 0 100%;
        margin: 0;
        background: transparent url('../svg/successful_order.svg') no-repeat scroll 50% 50%;
        background-size: contain;
      }
    }
  }
  .order-info {
    padding: 44px;
    background: $body-bg-white;
    .button {
      max-width: 200px;
      margin: 0 auto 10px;
      &:last-child {
        margin-bottom: 0;
      }
      > button {
        display: block;
        width: 100%;
      }
    }
  }
}

.product_not_found {
  min-height: 100%;
  padding: 48px 0;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  .product_not_found_box {
    max-width: 500px;
    margin: 0 auto;
  }  
  .banner {
    display: block;
    width: 100%;
    max-width: 162px;
    margin: 0 auto 20px;
    &:last-child {
      margin: 0 auto;
    }
    > span {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0 0 80.24691358024691%;
      background: transparent url('../svg/product_not_found.svg') no-repeat scroll 50% 50%;
      background-size: contain;      
    }
    &.banner_404 {
      > span {
        background: transparent url('../svg/http_status_404.svg') no-repeat scroll 50% 50%;
        background-size: contain;      
      }      
    }
    &.banner_500 {
      > span {
        background: transparent url('../svg/http_status_500.svg') no-repeat scroll 50% 50%;
        background-size: contain;      
      }      
    }    
  }
  p {
    display: block;
    width: 100%;
    margin: 20px 0;
    padding: 0;
    overflow: hidden;
    text-align: center;
    font-size: 16px;
    line-height: 20px; 
    color: $panel-p-color;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }    
    &.header {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      color: $main-header-color;
      .red {
        color: $H500;
      }      
    }   
    a {
      color: $H500;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
        color: $H700;
      }
    }  
  }  
  .button {
    max-width: 220px;
    margin: 0 auto 10px;
    &:last-child {
      margin-bottom: 0;
    }
    > button {
      display: block;
      width: 100%;
    }
  }  
}

.textarea-request-product {
  height: 90px;
  overflow: auto;

  &:hover, &:focus {
    border-color: inherit;
  }
}

.error-textarea {
  border: 1px solid #E02443;
}

.not_authorized {
  min-height: 100%;
  padding: 48px 0;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  .product_not_found_box {
    max-width: 500px;
    margin: 0 auto;
  }
  .banner {
    display: block;
    width: 100%;
    max-width: 162px;
    margin: 0 auto 20px;
    &:last-child {
      margin: 0 auto;
    }
    > span {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0 0 80.24691358024691%;
      background: transparent url('../svg/not_authorized.svg') no-repeat scroll 50% 50%;
      background-size: contain;
    }
    &.banner_404 {
      > span {
        background: transparent url('../svg/not_authorized.svg') no-repeat scroll 50% 50%;
        background-size: contain;
      }
    }
  }
  p {
    display: block;
    width: 100%;
    margin: 20px 0;
    padding: 0;
    overflow: hidden;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    color: $panel-p-color;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &.header {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      color: $main-header-color;
      .red {
        color: $H500;
      }
    }
    a {
      color: $H500;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
        color: $H700;
      }
    }
  }
  .button {
    max-width: 220px;
    margin: 0 auto 10px;
    &:last-child {
      margin-bottom: 0;
    }
    > button {
      display: block;
      width: 100%;
    }
  }
}

.cart_box {
  .cart_list {
    padding: 0;
    border: 0 none;
    background: none;
    .rs-panel-body{
      padding: 0 15px;
    }
    .cart_list_table {
      > div {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        margin: 0 -15px;
        position: relative;
        width: auto;
        border-bottom: 1px solid $panel-border-color;
        > div {
          padding: 20px 0;
          -webkit-order: 0;
          -ms-flex-order: 0;
          order: 0;
          -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
          flex: 0 1 auto;
          -webkit-align-self: auto;
          -ms-flex-item-align: auto;
          align-self: auto;  
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          -webkit-align-content: center;
          -ms-flex-line-pack: center;
          align-content: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;          
          &:nth-child(1){
            width: 50px;
            max-width: 50px;
            padding-right: 15px;
            padding-left: 17px;
            position: relative;
            &::before {
              content: "";
              display: block;
              width: 32px;
              height: auto;
              top: 20px;
              bottom: 20px;
              background: $main-bg-light-grey;
              position: absolute;
              right: 8px;
              overflow: hidden;
              z-index: 1;
            }
            .rs-checkbox {
              position: relative;
              z-index: 2;
            }
          }
          &:nth-child(2) {
            width: 100px;
            max-width: 100px;
          }
          &:nth-child(3) {
            -webkit-flex: 0 1 auto;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            padding-right: 20px;
            padding-left: 50px;
            width: calc(50% - 168px);
            max-width: calc(50% - 168px);            
          }
          &:nth-child(4) {
            width: 160px;
            max-width: 160px;
          }             
          &:nth-child(5) {
            -webkit-flex: 0 1 auto;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            padding-right: 30px;
            padding-left: 25px; 
            width: calc(50% - 168px);
            max-width: calc(50% - 168px);            
          }
          &:nth-child(6) {
            width: 26px;
            max-width: 26px;
          }
        }
        &:last-child {
          border: 0 none;
        }
      }
      .rs-checkbox .rs-checkbox-checker label {
        padding: 0;
      }      
      .img_box {
        border: 1px solid $panel-border-color;
        width: 100px;
        height: 100px;
        position: relative;
        background: $body-bg-white;
        > img {
          display: block;
          width: auto;
          height: auto;
          max-width: calc(100% - 10px);
          max-height: calc(100% - 10px);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      p {
        display: block;
        width: 100%;
        margin: 0 0 7px;
        padding: 0;
        overflow: hidden;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;     
        color: $panel-p-color;
        &:last-child {
          margin: 0;
        }
        &.description {
          color: $main-header-color;
        }  
        &.price-all {
          font-weight: 500;
          font-size: 20px;
          line-height: 23px;
          color: $body-text-third-color;
        }
      }
      .delete {
        width: 26px;
        height: 26px;
        padding: 7px;
        margin: 0 auto;
        border-radius: 50%;
        background: $button-bg-color;
        display: block;
        &:hover {
          opacity: 0.75;
        }        
        .rs-ripple-pond {
          display: none !important;
        }
        .rs-icon.rs-icon-close{
          width: 12px;
          height: 12px;
          overflow: hidden;
          display: block;
          margin: 0;
          padding: 0;
          background: transparent url('../svg/delete_button.svg') no-repeat scroll 50% 50%;
          background-size: contain;
          &::before {
            content: none;
            display: none;
          }
        }
      }
      .quantity {
        width: 100%;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        border-radius: 2px;
        overflow: hidden;
        background: $main-bg-light-grey;
        > div {
          -webkit-order: 0;
          -ms-flex-order: 0;
          order: 0;
          -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
          flex: 0 1 auto;
          -webkit-align-self: auto;
          -ms-flex-item-align: auto;
          align-self: auto;
          width: 42px;
          height: 42px;
          &:nth-child(2) {
            width: calc(100% - 84px);
            max-width: calc(100% - 84px);
          }
          span {
            display: block;
            width: 100%;
            margin: 0;
            padding: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
            font-weight: 400;
            font-size: 16px;
            line-height: 42px;
            color: $main-header-color;            
          }
          button {
            width: 42px;
            height: 42px;
            position: relative;
            background: none;
            padding: 0;
            margin: 0;
            &:hover {
              opacity: 0.75;
            }
            .rs-ripple-pond {
              display: none !important;
            }
            .rs-icon{
              width: 12px;
              height: 12px;
              overflow: hidden;
              display: block;
              margin: -6px 0 0 -6px;
              padding: 0;
              position: absolute;              
              top: 50%;
              left: 50%;
              &::before {
                content: none;
                display: none;
              }  
              &.rs-icon-plus {
                background: transparent url('../svg/plus_ico.svg') no-repeat scroll 50% 50%;
                background-size: contain;
              } 
              &.rs-icon-minus {
                background: transparent url('../svg/minus_ico.svg') no-repeat scroll 50% 50%;
                background-size: contain;
              }       
            }           
          }
        }        
      }      
    }
  }
}

@media (max-width: 1199px) {
  .cart_box {
    .cart_list {
      .cart_list_table {
        > div {
          > div {
            -webkit-order: 0;
            -ms-flex-order: 0;
            order: 0;
            -webkit-flex: 0 1 auto;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            -webkit-align-self: auto;
            -ms-flex-item-align: auto;
            align-self: auto;   
            &:nth-child(1) {
              padding-bottom: 12px;
              &::before {
                bottom: 12px;
              }
            }
            &:nth-child(2) {
              width: 85px;
              max-width: 85px;
              padding-bottom: 12px;
            }
            &:nth-child(3) {
              padding-right: 12px;
              padding-left: 0;
              width: calc(100% - 135px);
              max-width: calc(100% - 135px);
              padding-bottom: 12px;
            }            
            &:nth-child(4) {
              width: 135px;
              max-width: 135px;
              padding-right: 50px;
              padding-top: 0;
              padding-bottom: 0;
            }     
            &:nth-child(5) {
              width: calc(100% - 135px);
              max-width: calc(100% - 135px);
              padding-top: 0;
              padding-bottom: 0;  
              padding-right: 12px;
              padding-left: 0;                          
            }
            &:nth-child(6) {
              width: 100%;
              max-width: 100%;
              padding-top: 12px;
            }
          }
        }
        .img_box {
          width: 85px;
          height: 85px;
        }
        .quantity {
          > div {
            width: 28px;
            &:nth-child(2) {
              width: calc(100% - 56px);
              max-width: calc(100% - 56px);
            }
            button {
              width: 28px;
            }
          }        
        }         
      }
    }
  }
}

.unsupported_browser {
  width: 100%;
  min-height: calc(100vh - 56px);
  background: $main-bg-light-grey;
  padding: 48px 0;
  > .container {
    min-height: 100%;
  }
  .unsupported_browser_box {
    max-width: 1016px;
    margin: 0 auto;
    background: $body-bg-white;
    padding: 90px 45px;
    h1 {
      font-weight: 500;
      font-size: 25px;
      line-height: 29px;
      text-align: center;
      color: $main-header-color;
      display: block;
      width: 100%;
      margin: 0 0 14px;
      padding: 0;
      overflow: hidden;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: $panel-p-color;
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;      
      overflow: hidden;
    }
    ul, 
    ul > li {
      display: block;
      margin: 0;
      padding: 0;
      list-style: none;
    }
    ul {
      margin: 0 -30px;
      position: relative;
      width: auto;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;      
      > li {
        padding: 25px 30px 0;
        width: 180px;
        max-width: 180px;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;   
        text-align: center; 
        a{
          display: inline-block;
          vertical-align: top;
          margin: 0 auto;
          text-decoration: none;
          padding:0;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $main-header-color;   
          &::before {
            content: "";
            display: block;
            width: 86px;
            height: 86px;
            margin: 0 auto;
            padding: 0;
          }       
          > span {
            display: block;
            width: 100%;
            overflow: hidden;
            margin: 9px 0 0;
            padding: 0;
            text-decoration: underline;
          }
          &.chrome::before {
            background: transparent url('../svg/browsers/chrome.svg') no-repeat scroll 50% 50%;
            background-size: 80px 80px;
          }
          &.firefox::before {
            background: transparent url('../svg/browsers/firefox.svg') no-repeat scroll 50% 50%;
            background-size: 80px 80px;
          }
          &.safari::before {
            background: transparent url('../svg/browsers/safari.svg') no-repeat scroll 50% 50%;
            background-size: 86px 86px;
          }
          &.edge::before {
            background: transparent url('../svg/browsers/edge.svg') no-repeat scroll 50% 50%;
            background-size: 80px 80px;
          }
          &.opera::before {
            background: transparent url('../svg/browsers/opera.svg') no-repeat scroll 50% 50%;
            background-size: 80px 80px;
          }
          &:hover {
            &::before {
              opacity: 0.75;
            }
            > span {
              text-decoration: none;
            }
          }
        }    
      }
    }
  }
  .ie_table {
    display: table;
    table-layout: fixed;
    width: 100%;
    min-height: calc(100vh - 152px);
    > div {
      display: table-row;
      > div {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}

.site-header.isIE {
  .info-header .contact-info a .flex > span:nth-child(2) {
    min-width: 102px;
  }
}

.product_details {
  width: 100%;
  min-height: 100%;
  padding: 48px 0;
  overflow: hidden;
  .product_details_flex {
    width: auto;
    margin: 0 -30px;
    position: relative;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;    
    > div {
      padding: 0 30px;
      &:nth-child(1) {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        width: 660px;
        max-width: 660px;
      }
      &:nth-child(2) {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        width: calc(100% - 660px);
        max-width: calc(100% - 660px);      
      }      
    }
  }
  .products-img {
    width: 100%;
    position: relative;
    height: 0;
    padding: 0 0 100%;
    border: 1px solid $panel-border-color;
    background: $body-bg-white;
    max-width: 600px;
    margin: 0 auto;
    > img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
  .product_info {
    max-width: 440px;
    p {
      display: block;
      width: 100%;
      margin: 0 0 10px;
      padding: 0;
      overflow: hidden;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: $main-header-color;     
      &:last-child {
        margin: 0;
      }
      &.code {
        font-size: 16px;
        line-height: 19px;
        color: $short-description-color;        
      }
      &.price {
        margin-top: 18px;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;      
      }
    }
  }
  .short_description {
    margin: 30px 0 0;
    max-width: 440px;
    &:first-child {
      margin: 0;
    }
    p {
      display: block;
      width: 100%;
      overflow: hidden;
      margin: 0;
      padding: 0;
      color: $short-description-color;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      &.title {
        margin: 0 0 7px;
        color: $main-header-color;
        &:last-child {
          margin: 0;
        }
      }
      &.read_more {
        margin: 7px 0 0;
        display: inline-block;
        vertical-align: top;
        text-decoration: underline;
        color: $H500;
        cursor: pointer;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .add_to_cart {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    position: relative;
    margin: 30px -10px;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    > div {
      padding: 0 10px;
      &:nth-child(1) {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        width: 220px;
        max-width: 220px;
      }
      &:nth-child(2) {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;      
        width: calc(100% - 220px);
        max-width: calc(100% - 220px);          
      }      
    }
    .quantity {
      width: 100%;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      border-radius: 2px;
      overflow: hidden;
      background: $main-bg-light-grey;
      > div {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        width: 42px;
        height: 42px;
        &:nth-child(2) {
          width: calc(100% - 84px);
          max-width: calc(100% - 84px);
        }
        span {
          display: block;
          width: 100%;
          margin: 0;
          padding: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: center;
          font-weight: 400;
          font-size: 16px;
          line-height: 42px;
          color: $main-header-color;            
        }
        button {
          width: 42px;
          height: 42px;
          position: relative;
          background: none;
          padding: 0;
          margin: 0;
          &:hover {
            opacity: 0.75;
          }
          .rs-ripple-pond {
            display: none !important;
          }
          .rs-icon{
            width: 12px;
            height: 12px;
            overflow: hidden;
            display: block;
            margin: -6px 0 0 -6px;
            padding: 0;
            position: absolute;              
            top: 50%;
            left: 50%;
            &::before {
              content: none;
              display: none;
            }  
            &.rs-icon-plus {
              background: transparent url('../svg/plus_ico.svg') no-repeat scroll 50% 50%;
              background-size: contain;
            } 
            &.rs-icon-minus {
              background: transparent url('../svg/minus_ico.svg') no-repeat scroll 50% 50%;
              background-size: contain;
            }       
          }           
        }
      }        
    } 
    .rs-btn.rs-btn-primary {
      max-width: 200px;
    }
  }
  .tabs_product_info {
    margin: 30px 0 0;
    &:first-child {
      margin: 0;
    }
  }
  .tabs_list {
    li.disc {
      list-style-type: disc;
      list-style-position: inside;
    }
  }
}

@media (max-width: 1299px) {
  .product_details {
    .product_details_flex {
      > div {
        &:nth-child(1) {
          width: 50%;
          max-width: 50%;
        }
        &:nth-child(2) {
          width: 50%;
          max-width: 50%;     
        }          
      }
    }
    .product_info,
    .short_description {
      max-width: 100%;
    }
    .add_to_cart {
      > div {
        padding: 0 10px;
        &:nth-child(1) {
          width: 50%;
          max-width: 50%;
        }
        &:nth-child(2) {
          width: 50%;
          max-width: 50%;         
        }      
      }      
      .rs-btn.rs-btn-primary {
        max-width: 100%;
      }         
    }    
  }
}

@media (max-width: 767px) {
  .product_details {
    .product_details_flex {
      > div {
        &:nth-child(1) {
          width: 100%;
          max-width: 100%;
        }
        &:nth-child(2) {
          width: 100%;
          max-width: 100%;   
          padding-top: 48px;  
        }          
      }
    }
    .short_description {
      display: none;
    }
  }
}

.nav-list {
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: $main-bg-light-grey;
  .rs-nav-waterline { 
    display: none;
  }
  .rs-nav.rs-nav-horizontal {
    ul {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      > li.rs-nav-item {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        width: auto;
        max-width: 50%;
        a {
          padding: 17px 17px 18px;
          text-align: center;
          text-decoration: none;
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;   
          color: $main-header-color;   
          &:hover {
            color: $H500;
          }
          &::before {
            height: 3px;
            background: $H500;
          }
        }
      }
    }
  }

}

@media (max-width: 767px) {
  .nav-list {
    .rs-nav.rs-nav-horizontal {
      ul {
        > li.rs-nav-item {
          width: 50%;
          max-width: 50%;
        }
      }
    }
  }
}

.tabs_list {
  .rs-panel-group.rs-panel-group-accordion {
    .rs-panel {
      border-radius: 0;
      width: 100%;
      padding: 36px 0;
      border: 0 none;
      border-bottom: 1px solid $panel-border-color;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start; 
      &::before {
        content: none;
        display: none;
      }     
      .rs-panel-heading {
        padding: 0 27px 0 0;
        width: 100%;
        max-width: 100%;
        position: relative;
        .rs-panel-title,
        .rs-panel-title > span {
          display: block;
          margin: 0;
          padding: 0;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $main-header-color;          
        }
        &::before {
          content: "";
          width: 12px;
          height: 12px;
          transition: none;
          top: 50%;
          left: auto;
          right: 0;
          transform: none;
          margin: -6px 0 0;
          background: transparent url('../svg/plus_ico_red.svg') no-repeat scroll 50% 50%;
          background-size: contain;
        }
      }
      .rs-panel-collapse {
        padding: 0;
        width: 100% ;
        max-width: 100%;
        .rs-panel-body {
          width: 100%;
          margin: 0;
          padding: 30px 0 0;
          overflow: hidden;
          p {
            color: $short-description-color;
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            margin: 0 0 13px;
            display: block;
            width: 100%;
            overflow: hidden;
            padding: 0;
            strong {
              color: $main-header-color;
              font-weight: 400;
              white-space: nowrap;
              display: inline-block;
              vertical-align: top;
              width: auto;
              max-width: 100%;
            }
            &:last-child {
              margin: 0;
            }
            a {
              color: inherit;
              font-weight: inherit;
              line-height: inherit;
              text-decoration: underline;
              &:hover {
                text-decoration: none;
              }
            }
          }
          ul,
          ul > li {
            display: block;
            margin: 0;
            padding: 0;
            list-style: none;
          }
          ul {
            margin: 0 -2px 13px;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-align-content: flex-start;
            -ms-flex-line-pack: start;
            align-content: flex-start;
            -webkit-align-items: flex-start;
            -ms-flex-align: start;
            align-items: flex-start; 
            width: auto;
            position: relative;
            &:last-child {
              margin: 0 -2px;
            } 
            > li {
              padding: 0 2px;
              &:nth-child(1) {
                -webkit-order: 0;
                -ms-flex-order: 0;
                order: 0;
                -webkit-flex: 0 1 auto;
                -ms-flex: 0 1 auto;
                flex: 0 1 auto;
                -webkit-align-self: auto;
                -ms-flex-item-align: auto;
                align-self: auto;
              }
              &:nth-child(2) {
                -webkit-order: 0;
                -ms-flex-order: 0;
                order: 0;
                -webkit-flex: 1 1 auto;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;
                -webkit-align-self: auto;
                -ms-flex-item-align: auto;
                align-self: auto;                
              }              
            }           
          }
        }
      }
      &.rs-panel-in {
        .rs-panel-heading {
          &::before {
            background: transparent url('../svg/minus_ico_red.svg') no-repeat scroll 50% 50%;
            background-size: contain;
          }
        }        
      }
    }
  }
}

@media (min-width: 768px) {
  .tabs_list {
    .rs-panel-group.rs-panel-group-accordion {
      .rs-panel {
        .rs-panel-heading {
          width: 50%;
          max-width: 50%;
          padding-left: 15px;
        }
        .rs-panel-collapse {
          padding: 0;
          padding-right: 15px;
          width: 50% ;
          max-width: 50%;
          min-height: 19px;
          display: block;
          .rs-panel-body {
            padding: 0;
          }
          &[aria-hidden="true"] {
            overflow: hidden;
          }
        }
      }
    }
  }  
}
.wrap-cookie-consent{
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1060;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.cookie-consent {
  z-index: 1050;
  position: fixed;
  bottom: 25px;
  left: 0;
  width: 100%;
  .CookieConsent {
    box-shadow: 0 0 3px rgba($body-bg-white, 0.25);
    max-width: 645px;
    border-radius: 4px;
    background: $header-bg-2;
    padding: 25px 20px;
    float: left;
    direction: rtl;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: auto;
    position: relative;
    top: auto !important;
    left: auto !important;
    bottom: auto !important;
    right: auto !important;
    margin: 0;
    > div {
      &:nth-child(1) {
        padding: 0 0 0 10px;
        width: calc(100% - 180px);
        max-width: calc(100% - 180px);
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;               
      }
      &:nth-child(2) {
        padding: 0 10px 0 0;
        width: 180px;
        max-width: 180px;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;        
      }      
    }  
    p {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0 60px 0 0;
      min-height: 40px;
      position: relative;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;  
      color: $body-text-white-color;    
      > span {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0; 
        overflow: hidden;       
      }
      &::before {
        content: "";
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 50%;
        background: $header-link-bg url('../svg/cookie_ico.svg') no-repeat scroll 50% 50%;
        background-size: 20px 20px;
      }
    }  
    .confirm-button {
      display: block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0;
      padding: 11px 5px;
      height: 42px;
      border-radius: 2px;
      background: $body-bg-white;
      color: $body-text-third-color;
      text-decoration: none;
      cursor: pointer;
      font-size: 16px;
      line-height: 20px;     
      font-weight: 400; 
      text-align: center;
      float: none;
      text-shadow: none;
      outline: none;      
      &:hover {
        opacity: 0.75;
      }
    }
  }
}

@media (max-width: 767px) {

  .cookie-consent {
    z-index: 1050;
    position: fixed;
    bottom: 25px;
    left: 0;
    width: 100%;
    .CookieConsent {
      box-shadow: 0 0 3px rgba($body-bg-white, 0.25);
      max-width: 645px;
      border-radius: 4px;
      background: $header-bg-2;
      padding: 25px 20px;
      float: left;
      direction: rtl;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      width: auto;
      position: relative;
      top: auto !important;
      left: auto !important;
      bottom: auto !important;
      right: auto !important;
      margin: 0;
      > div {
        &:nth-child(1) {
          padding: 0;
          width: 100%;
          max-width: 100%;
        }
        &:nth-child(2) {
          padding: 20px 0 0;
          width: 100%;
          max-width: 100%;    
        }      
      }  
      .decline-button,
      .confirm-button {
        width: calc(50% - 5px);
      }      
    }
  }

}

.breadcrumb-list {
  display: flex;
  align-items: center;
  margin: -24px 0 18px;
  position: relative;

  .landing_page_link {
    display: block;
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $short-description-color;
    text-decoration: none;
  }

  .landing_page_link:hover {
    text-decoration: underline;
  }
  .rs-breadcrumb {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 -2px; 
    padding: 0;   
    position: relative;
    width: auto;
    > .rs-breadcrumb-item {
      display: block;
      padding: 4px 2px;
      width: auto;
      max-width: 100%; 
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;   
      > a,
      > span {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: $short-description-color;
        text-decoration: none;    
        > span {
          display: block;
          width: 100%;
          margin: 0;
          padding: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }  
      > span {
        color: $main-header-color;
      }    
      > a:hover {
        text-decoration: underline;
      }        
    }
    > .rs-breadcrumb-separator {
      display: block;
      padding: 4px 2px;
      width: 19px;
      max-width: 19px;
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;      
    }
  }
  .rs-icon.rs-icon-angle-right{
    display: block;
    width: 13px;
    height: 16px;
    margin: 0;
    padding: 0;
    background: transparent url('../svg/breadcrumb_chevron_rtl.svg') no-repeat scroll 50% 50%;
    background-size: contain;
    &::before {
      content: "";
      display: none;
    }
  }
}

.product_list {
  width: 100%;
  min-height: 100%;
  padding: 48px 0;
  overflow: hidden;
  h1 {
    display: block;
    width: 100%;
    margin: 0 0 26px;
    padding: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: $main-header-color;
    .light_grey {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $panel-p-color;
    }
    &:last-child {
      margin: 0;
    }
  }
  .wrapper_catalog_list_text {
    margin-bottom: 37px;
    margin-top: 24px;
    color: #252C32;
  }
  .wrapper_product_grid {
    .product_item {
      margin: 0;
      padding: 0;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
      -webkit-align-items: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      padding: 0.5px;    
      > div {
        float: none;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: stretch;
        -ms-flex-item-align: stretch;
        align-self: stretch;  
        padding: 25px 35px;
        border: 1px solid $panel-border-color;
        margin: -0.5px;
        width: 25%;
        max-width: 25%;
      }
      .products-list-flex {
        width: 100%;
        p {
          display: block;
          margin: 0 0 5px;
          padding: 0;
          width: 100%;
          overflow: hidden;
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
          color: $panel-p-color;
          &.description {
            color: $main-header-color;
            margin: 0 0 8px;
            a {
              color: inherit;
              line-height: inherit;
              font-weight: inherit;
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            }        
          }
          &.price-all {
            color: $main-header-color;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
          }
          &:last-child {
            margin: 0;
          }
        }
      }
      .wrapper_products-img {
        max-width: 260px;
        width: 100%;
        margin: 0 auto 18px;
        display: block;
        .products-img {
          width: 100%;
          height: 0;
          margin: 0;
          padding: 0 0 100%;
          position: relative;
          border: 1px solid $panel-border-color;
          background: $body-bg-white;
          > img {
            display: block;
            width: auto;
            height: auto;
            max-width: calc(100% - 2px);
            max-height: calc(100% - 2px);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .add_to_cart{
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        margin: 17px -5px;
        width: auto;
        max-width: calc(100% + 10px);
        position: relative;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        > div {
          padding: 0 5px;
          -webkit-order: 0;
          -ms-flex-order: 0;
          order: 0;
          -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
          flex: 0 1 auto;
          -webkit-align-self: auto;
          -ms-flex-item-align: auto;
          align-self: auto;
          width: 50%;
          max-width: 50%;
        }
        .quantity {
          width: 100%;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
          -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          -webkit-align-content: flex-start;
          -ms-flex-line-pack: start;
          align-content: flex-start;
          -webkit-align-items: flex-start;
          -ms-flex-align: start;
          align-items: flex-start;
          border-radius: 2px;
          overflow: hidden;
          background: $main-bg-light-grey;
          > div {
            -webkit-order: 0;
            -ms-flex-order: 0;
            order: 0;
            -webkit-flex: 0 1 auto;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            -webkit-align-self: auto;
            -ms-flex-item-align: auto;
            align-self: auto;
            width: 42px;
            height: 42px;
            &:nth-child(2) {
              width: calc(100% - 84px);
              max-width: calc(100% - 84px);
            }
            span {
              display: block;
              width: 100%;
              margin: 0;
              padding: 0;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              text-align: center;
              font-weight: 400;
              font-size: 16px;
              line-height: 42px;
              color: $main-header-color;
            }
            button {
              width: 42px;
              height: 42px;
              position: relative;
              background: none;
              padding: 0;
              margin: 0;
              &:hover {
                opacity: 0.75;
              }
              .rs-ripple-pond {
                display: none !important;
              }
              .rs-icon{
                width: 12px;
                height: 12px;
                overflow: hidden;
                display: block;
                margin: -6px 0 0 -6px;
                padding: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                &::before {
                  content: none;
                  display: none;
                }
                &.rs-icon-plus {
                  background: transparent url('../svg/plus_ico.svg') no-repeat scroll 50% 50%;
                  background-size: contain;
                }
                &.rs-icon-minus {
                  background: transparent url('../svg/minus_ico.svg') no-repeat scroll 50% 50%;
                  background-size: contain;
                }
              }
            }
          }
        }
        .rs-btn.rs-btn-primary {
          min-width: 100%;
        }
      }
    }
    .product_item_not_found {
      display: flex;
      justify-content: center;
    }
  }   
}

@media (max-width: 1199px) {
  .product_list {
    .wrapper_product_grid {
      .product_item {
        > div {
          width: 33.33333333333333%;
          max-width: 33.33333333333333%;
        }
      }
    }     
  }
}

@media (max-width: 991px) {
  .product_list {
    .wrapper_product_grid {
      .product_item {
        > div {
          width: 50%;
          max-width: 50%;
        }
      }
    }     
  }
}

@media (max-width: 767px) {
  .product_list {
    .wrapper_product_grid {
      .product_item {
        > div {
          width: 100%;
          max-width: 100%;
          padding: 25px;
        }
      }
    }     
  }
}

.wrapper_pagination {
  margin: 20px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }  
  .rs-pagination {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    width: auto;
    margin: 0 -2px;
    > li {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
      min-width: 36px;
      padding: 4px 2px 0;
    }    
    .rs-pagination-btn {
      .rs-icon.rs-icon-page-previous {
        display: block;
        width: 25px;
        height: 24px;
        overflow: hidden;
        background: transparent url('../svg/page_top_icon.svg') no-repeat scroll 0 0;
        &::before {
          content: none;
          display: none;
        }
      }      
      .rs-icon.rs-icon-page-next {
        display: block;
        width: 25px;
        height: 24px;
        overflow: hidden;
        background: transparent url('../svg/page_end_icon.svg') no-repeat scroll 0 0;        
        &::before {
          content: none;
          display: none;
        }
      } 
      .rs-icon.rs-icon-more{
        font-family: $font !important;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;   
        &::before {
          content: '\2026';
        }        
      }       
      .rs-ripple-pond {
        display: none !important;
      }          
      a {
        display: block;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: $main-header-color;
        background: none !important;
        margin: 0; 
        padding: 0;
        min-width: 36px;
        border-radius: 0;
        span[aria-label="Previous"],
        span[aria-label="Next"] {
          border: 1px solid $pagination-border-color;
          background: $body-bg-white;
          display: block;
          width: 42px;
          height: 42px;
          border-radius: 2px;
          position: relative;
          > .rs-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
        &:hover {
          color: $H500;
          .rs-icon.rs-icon-page-previous,
          .rs-icon.rs-icon-page-next {
            background-position: 0 -24px;
          }      
        }
      }
      .rs-pagination-btn-disabled {
        a {
          opacity: 0.4 !important;
          color: $panel-p-color !important;
          .rs-icon.rs-icon-page-previous,
          .rs-icon.rs-icon-page-next {
            display: block;
            width: 25px;
            height: 24px;
            overflow: hidden;
            background-position: 0 0 !important;
          }             
        }
      }
      &:first-child {
        padding-left: 6px;
      }
      &:last-child {
        padding-right: 6px;
      } 
      &.rs-pagination-btn-active {
        a {
          color: $H500 !important; 
        }
      }  
    }
    .rs-pagination-btn-disabled:not(:last-child):not(:first-child){
      a {
        color: $main-header-color !important;
        opacity: 1 !important;
        cursor: default !important;
        pointer-events: none;
      }
    }
  }
}

.rs-notification {
  .rs-notification-item-content {
    padding: 0 20px 26px;
    background: $body-bg-white;
    box-shadow: 0px 6px 14px -6px $notification-shadow-color-1, 0px 10px 32px -4px $notification-shadow-color-2;    
    border-radius: 0;
  }
  .rs-notification-item-wrapper {
    width: 330px;
  }  
  .rs-notification-description {
    border-top: 1px solid $panel-border-color;
    padding: 28px 0 0;
    margin: 0;
  }  
  .rs-notification-item-close{
    display: block;
    width: 11px;
    height: 11px;
    top: 22px;
    left: 20px;
    margin: 0;
    padding: 0;
    .rs-notification-item-close-x {
      display: block;
      width: 11px;
      height: 11px;
      overflow: hidden;
      background: transparent url('../svg/delete_button.svg') no-repeat scroll 50% 50%;
      &::before {
        content: none;
        display: none;
      }
    }
    &:hover {
      opacity: 0.75;
    }
  }   
  .rs-notification-title {
    display: block;
    width: calc(100% - 21px);
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $main-header-color;    
    overflow: hidden;
    padding: 18px 0 0;
    margin: 0 0 18px;
  } 
  .products-list-flex {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
    margin: 0;
    position: relative;
    width: auto;
    margin-bottom: 20px;
    p {
      display: block;
      margin: 0 0 5px;
      padding: 0;
      width: 100%;
      overflow: hidden;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      color: $panel-p-color;
      &.description {
        color: $main-header-color;
        margin: 0 0 8px;
      }
      &.price-all {
        color: $main-header-color;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }
      &:last-child {
        margin: 0;
      }
    }
  
    .products-img {
      width: 80px;
      height: 80px;
      overflow: hidden;
      margin: 0 0 5px;
      padding: 0;
      position: relative;
      border: 1px solid $panel-border-color;
      background: $body-bg-white;
  
      > img {
        display: block;
        width: auto;
        height: auto;
        max-width: calc(100% - 10px);
        max-height: calc(100% - 10px);
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
  
      &:last-child {
        margin: 0;
      }
    }
  
    p.quantity {
      padding: 7px 3px;
      font-size: 14px;
      line-height: 16px;
      color: $main-header-color;
      text-align: center;
      background: $main-bg-light-grey;
      width: 80px;
  
      > span {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
        overflow: hidden;
      }
    }
  
    > div {
      -ms-flex-order: 0;
      -webkit-box-ordinal-group: 1;
      order: 0;
      -ms-flex: 0 1 auto;
      -webkit-box-flex: 0;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
  
      &:nth-child(1) {
        width: 94px;
        max-width: 94px;
        padding: 0 0 0 7px;
      }
  
      &:nth-child(2) {
        width: calc(100% - 94px);
        max-width: calc(100% - 94px);
        padding: 0 7px 0 0;
      }
    }
  }
  .remove_added_product {
    margin: 20px 0 0;
    p {
      display: block;
      margin: 0; 
      padding: 0;
      overflow: hidden;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: $main-header-color;
      button,
      a {
        color: inherit;
        line-height: inherit;
        font-weight: inherit;
        text-decoration: underline;
        cursor: pointer;
        background: none;
        margin: 0;
        padding: 0;
        &:hover {
          text-decoration: none;
        }
      }      
    }
    &.first-child {
      margin: 0;
    }
  }
}

@media (max-width: 575px) {
  .rs-notification {
    .rs-notification-item-wrapper {
      max-width: 330px;
      width: calc(100% - 30px);
    }  
  }
}

.product_catalog {
  width: 100%;
  min-height: 100%;
  padding: 48px 0;
  overflow: hidden;
  h1 {
    display: block;
    width: 100%;
    margin: 0 0 26px;
    padding: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: $main-header-color;
    .light_grey {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $panel-p-color;
    }
    &:last-child {
      margin: 0;
    }
  }
  .wrapper_catalog_list_text {
    margin-bottom: 37px;
    margin-top: 24px;
    color: #252C32;
  }
  .wrapper_catalog_list {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: auto;
    margin: 0 - 13px;
    position: relative; 
    > div {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
      margin: 26px 0 0;
      padding: 0 13px;
      width: 25%;
      max-width: 25%;
    }
  }  
  .catalog_item {
    width: 100%;
    min-height: 100%;
    background: $main-bg-light-grey;
    border: 1px solid $panel-border-color;
    padding: 34px 28px 175px;    
    position: relative;
    .img-box {
      width: 100%;
      height: 170px;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      > img {
        display: block;
        margin: 0;
        width: auto;
        height: 170px;
        padding: 0;
        position: absolute;      
        top: 0;
        left: 0;        
      }
    }
    p{
      display: block;
      margin: 0 0 14px;
      padding: 0;
      overflow: hidden;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      color: $main-header-color;      
      &:last-child {
        margin: 0;
      }
      a {
        display: inline-block;
        vertical-align: top;
        width: auto;
        max-width: 100%;
        cursor: pointer;
        color: inherit;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;        
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .links_list {
      width: 100%;
      min-height: 135px;
      ul,
      ul > li {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
      }
      ul > li {
        margin: 0 0 10px;
        &:last-child {
          margin: 0;
        }
        &.show_more {
          padding: 0;
          &::before {
            content: none;
            display: none;
          }
          > span {
            cursor: pointer;
            text-decoration: underline;
          }
          &:hover {
            > span {
              cursor: pointer;
              text-decoration: none;
            }
          }           
        } 
      }
      a {
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        width: auto;
        max-width: 100%;
        text-decoration: none;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: $main-header-color;
        position: relative;
        padding: 0 22px 0 0;
        margin: 0;
        min-height: 19px;
        &::before {
          content: "";
          display: block;
          width: 12px;
          height: 16px;
          overflow: hidden;
          margin: -8px 0 0;
          padding: 0;
          background: transparent url('../svg/catalog_chevron_rtl.svg') no-repeat scroll 50% 50%;
          position: absolute;
          top: 50%;
          right: 0;
        }
        > span {
          display: block;
          width: 100%;
          margin: 0;
          padding: 0;
          overflow: hidden;
        }  
        &:hover {
          > span {
            text-decoration: underline;
          }
        }  
        &.show_more {
          padding: 0;
          &::before {
            content: none;
            display: none;
          }
          > span {
            text-decoration: underline;
          }
          &:hover {
            > span {
              text-decoration: none;
            }
          }           
        }    
      }
    }
  }
}

@media (max-width: 1199px) {
  .product_catalog {
    .wrapper_catalog_list {
      > div {
        width: 33.33333333333333%;
        max-width: 33.33333333333333%;
      }
    }  
  }
}

@media (max-width: 991px) {
  .product_catalog {
    .wrapper_catalog_list {
      > div {
        width: 50%;
        max-width: 50%;
      }
    }  
  }
}

@media (max-width: 767px) {
  .product_catalog {
    .wrapper_catalog_list {
      > div {
        width: 100%;
        max-width: 100%;
      }
    }  
  }
}

.registration {
  width: 100%;
  min-height: 100%;
  padding: 48px 0;
  overflow: hidden;
  background: $main-bg-light-grey;
  .registration_box {
    max-width: 470px;
    margin: 0 auto;
    background: $body-bg-white;
    padding: 35px 41px 41px;
    border-radius: 4px;
    border: 1px solid $panel-border-color;
    overflow: hidden;
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 67%;
      height: 3px;
      background: $H500;
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: 0;
    }
    p {
      display: block;
      width: 100%;
      padding: 0;
      margin: 0 0 10px;
      overflow: hidden;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px; 
      color: $body-text-second-color;     
      &:last-child {
        margin: 0;
      }
      &.steps {
        opacity: 0.6;
        margin: 0 0 14px;
      }
    }
    .rs-form-group {
      display: block;
      width: 100%;
      margin: 0 0 30px;
      &:last-child {
        margin: 0;
      }
    }
    .rs-control-label {
      position: relative;
      display: inline-block;
      vertical-align: top;
      width: auto;
      max-width: 100%;
      overflow: hidden;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      padding: 0;
      margin: 25px 0 10px;
      color: $body-text-second-color;     
      &.required {
        padding-right: 8px;
        &::before{
          content: "\002A";
          position: absolute;
          color: $H500;
          top: 0;
          right: 0;
          font-size: 16px;
          line-height: 19px;
          display: block;
          width: 8px;
          height: 19px;
          overflow: hidden;
        }
      }
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .registration_error + .rs-control-label {
      margin-top: 17.11px;
    }
    .rs-picker-block{
      margin: 0;
    }
    .rs-form-control-wrapper {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
      &:last-child {
        margin: 0;
      }     
      .rs-input {
        display: block;
        width: 100%;
        background: $textarea-bg-color;
        border: 1px solid $textarea-border-color;
        border-radius: 2px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        resize: none;
        color: $textarea-text-color;  
        padding: 10px 14px;    
      }
      .rs-input::-webkit-input-placeholder {color: $textarea-placeholder-color;opacity: 1;}
      .rs-input::-moz-placeholder          {color: $textarea-placeholder-color;opacity: 1;}
      .rs-input:-moz-placeholder           {color: $textarea-placeholder-color;opacity: 1;}
      .rs-input:-ms-input-placeholder      {color: $textarea-placeholder-color;opacity: 1;}  
      .rs-input:hover,
      .rs-input:focus {
        border: 1px solid $textarea-border-color-active;
      }
      .rs-input[name="catalogNumber"] {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        &[type=number] {
          -moz-appearance: textfield;
        }
      } 
    }
    .wrapper_checkbox {
      margin: 25px 0 0;
      &:first-child {
        margin-top: 0;
      }
      .rs-checkbox-checker label .text {
        a {
          color: inherit;
          line-height: inherit;
          font-weight: inherit;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .registration-title {
      margin: 0 0 24px;
      p {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        > span {
          display: block;
          width: 100%;
          padding: 0;
          margin: 0;
          overflow: hidden;
        }
      }
    }
    .buttons_list {
      margin: 30px auto 0;
      .rs-btn.rs-btn-ghost {
        display: block;
        width: 100%;
        margin: 0;
        padding: 10px 6px;
        border-radius: 2px;
        border: 1px solid $H500;
        color: $H500;
        font-size: 15px;        
        line-height: 20px;
        font-weight: 500;
        cursor: pointer;
        > span {
          display: block;
          width: 100%;
          margin: 0;
          padding: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        &:hover {
          border: 1px solid $H700;
          color: $H700;
        }
      }      
      .rs-btn {
        display: block;
        width: 100%;
        border-radius: 2px;
        min-width: 100%;
      }
      .flex-container {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        width: auto;
        position: relative;
        margin: 0 -10px;
        > div {
          -webkit-order: 0;
          -ms-flex-order: 0;
          order: 0;
          -webkit-flex: 1 1 auto;
          -ms-flex: 1 1 auto;
          flex: 1 1 auto;
          -webkit-align-self: auto;
          -ms-flex-item-align: auto;
          align-self: auto;
          width: 50%; 
          max-width: 50%;
          padding: 0 10px;
        }
      }
    }
    .success_icon {
      width: 36px;
      height: 36px;
      display: block;
      margin: 0 auto 18px;
      background: transparent url('../svg/success_icon.svg') no-repeat scroll 50% 50%;
      background-size: contain;
    }
    &.success_box {
      padding: 41px;
      text-align: center;
      p {
        opacity: 0.7;
      }
      .registration-title {
        margin: 0 0 17px;
        opacity: 1;
      }
      .buttons_list {
        margin: 21px auto 0;
        .rs-btn {
          max-width: 100%;
          min-width: 210px;
          width: auto;
          display: block;
          margin: 0 auto;
          &.rs-btn-link {
            padding: 0;
            height: auto;
          }
        }
      }      
      &::before {
        width: 100%;
      }     
    }    
  }
}

@media (max-width: 767px) {
  .registration {
    .registration_box {
      padding: 35px 15px 41px;
      &.success_box {
        padding: 41px 15px;
      }    
    }
  }
}

.rs-modal-wrapper {
  .authentication {
    max-width: 450px;
    width: calc(100% - 30px);
    margin: 90px auto 60px;
    .rs-modal-content {
      padding: 0;
      margin: 0;
      background: $body-bg-white;
      border: 0 none;
      border-radius: 4px;
      box-shadow: 0 2px 3px $calendar-box-shadow-color;
      .rs-modal-header {
        margin: 0;
        padding: 0;
        .rs-modal-header-close {
          width: 26px;
          height: 26px;
          margin: 0;
          padding: 0;
          overflow: hidden;
          border-radius: 50%;
          background: $body-bg-white url('../svg/delete_button.svg') no-repeat scroll 50% 50%;
          background-size: 12px 12px;
          cursor: pointer;
          position: absolute;
          top: 0;
          right: auto;
          left: -38px;
          bottom: auto;
          &:hover {
            opacity: 0.75;
          }
          > span {
            display: none;
          }
          &::before {
            content: none;
            display: none;
          }
        }
        .nav-list {
          background: none;
          position: relative;
          ul > li > a{
            font-size: 18px;
          }
          .rs-nav.rs-nav-horizontal ul > li.rs-nav-item{
            width: 50%;
            max-width: 50%;
          }
          &::before {
            content: "";
            width: 100%;
            height: 1px;
            margin: 0;
            padding: 0;
            overflow: hidden;
            background: $panel-border-color-2;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
      .rs-modal-body {
        margin: 0;
        padding: 35px 30px;
        background: $body-bg-white !important;
        p {
          display: block;
          width: 100%;
          margin: 0 0 18px;
          padding: 0;
          overflow: hidden;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $body-text-second-color;   
          strong {
            font-weight: 500;
          }
          &:last-child {
            margin: 0;
          }   
          &.forgot_phone {
            text-align: center;
            font-weight: 500;
            margin-top: 25px;
            a {
              font-weight: inherit;
              font-size: inherit;
              line-height: inherit;
              text-decoration: underline;
              color: inherit;
              &:hover {
                text-decoration: none;
              }
            }
          } 
          &.edit_phone {
            a {
              font-weight: 500;
              font-size: inherit;
              line-height: inherit;
              text-decoration: none;
              color: $H500;
              &:hover {
                text-decoration: underline;
              }
            }            
          }   
        }
        .rs-control-label {
          display: inline-block;
          position: relative;
          vertical-align: top;
          width: auto;
          max-width: 100%;
          overflow: hidden;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          padding: 0;
          margin: 0 0 10px;
          color: $body-text-second-color;     
          &.required {
            padding-right: 8px;
            &::before{
              content: "\002A";
              position: absolute;
              color: $H500;
              top: 0;
              right: 0;
              font-size: 16px;
              line-height: 19px;
              display: block;
              width: 8px;
              height: 19px;
              overflow: hidden;
            }
          }
          &:last-child {
            margin: 0;
          }
        } 
        .rs-input {
          display: block;
          width: 100%;
          background: $textarea-bg-color;
          border: 1px solid $textarea-border-color;
          border-radius: 2px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          resize: none;
          color: $textarea-text-color;  
          padding: 10px 16px;    
        }
        .rs-input::-webkit-input-placeholder {color: $textarea-placeholder-color;opacity: 1;}
        .rs-input::-moz-placeholder          {color: $textarea-placeholder-color;opacity: 1;}
        .rs-input:-moz-placeholder           {color: $textarea-placeholder-color;opacity: 1;}
        .rs-input:-ms-input-placeholder      {color: $textarea-placeholder-color;opacity: 1;}  
        .rs-input:hover,
        .rs-input:focus {
          border: 1px solid $textarea-border-color-active;
        }  
        .rs-form-group {
          display: block;
          margin: 0 0 20px;
          padding: 0;
          &.buttons_box {
            margin: 5px 0 5px;
          }
          &:first-child {
            margin-top: 0;
          }          
          &:last-child {
            margin-bottom: 0;
          }
        }
        .flex-container-number {
          width: auto;
          position: relative;
          direction: ltr;
          margin: 0 -7px;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
          -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          -webkit-align-content: flex-start;
          -ms-flex-line-pack: start;
          align-content: flex-start;
          -webkit-align-items: flex-start;
          -ms-flex-align: start;
          align-items: flex-start;
          > div {
            padding: 0 7px;
            -webkit-order: 0;
            -ms-flex-order: 0;
            order: 0;
            -webkit-flex: 0 1 auto;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            -webkit-align-self: auto;
            -ms-flex-item-align: auto;
            align-self: auto;
            width: 25%;
            max-width: 25%;            
          }   
          .rs-input {
            text-align: center;
          }
        }   
      }      
    }
  }
}

@media (max-width: 575px) {
  .rs-modal-wrapper {
    .authentication {
      .rs-modal-content {
        .rs-modal-header {
          .rs-modal-header-close {
            top: -38px;
            left: 0;
          }
        }      
      }
    }
  }
}

.wrapper_dropdown {
  .rs-dropdown {
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    .rs-btn {
      position: relative;
      display: block;
      background: $body-bg-white;
      border: 1px solid $textarea-border-color;
      box-sizing: border-box;
      border-radius: 2px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      margin: 0;
      padding: 8px 10px 8px 34px;
      text-align: right;
      color: $short-description-color;   
      white-space: nowrap;         
      text-overflow: ellipsis;
      overflow: hidden;
      .rs-ripple-pond {
        display: none;
      }
      .rs-dropdown-toggle-caret {
        display: block;
        width: 18px;
        height: 14px;
        background: transparent url('../svg/dropdown_icon.svg') no-repeat scroll -14px 0;
        position: absolute;
        top: 50%;
        padding: 0;
        left: 8px;
        margin: -7px 0 0;
        &::before {
          content: none;
          display: none;
        }
      }
    }
    &:focus,
    &:hover {
      .rs-btn {
        border-color: $textarea-border-color-active;
      } 
    }
    &.rs-dropdown-open {
      border-radius: 0;
      -webkit-border-top-left-radius: 4px;
      -webkit-border-top-right-radius: 4px;
      -moz-border-radius-topleft: 4px;
      -moz-border-radius-topright: 4px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      .rs-btn {
        border-color: $textarea-border-color-active;
        .rs-dropdown-toggle-caret {
          background-position: -14px -14px;
        }
      }      
    }
    .rs-dropdown-menu {
      width: 100%;
      border-radius: 0;
      -webkit-border-bottom-right-radius: 4px;
      -webkit-border-bottom-left-radius: 4px;
      -moz-border-radius-bottomright: 4px;
      -moz-border-radius-bottomleft: 4px;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;      
      background: $calendar-bg;
      border: 1px solid $calendar-border-color;
      box-shadow: 0px 2px 3px $calendar-box-shadow-color;
      padding: 0;     
      > li {
        border-bottom: 1px solid $textarea-border-color;
        &:last-child {
          border-bottom: 0;
        }
      } 
      .rs-dropdown-item-content {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        cursor: pointer;
        text-decoration: none;
        background: none;
        width: 100%;
        display: block;
        height: 36px;
        padding: 9px;
        margin: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: $body-text-second-color;
        transition: none;
        &:hover {
          background: $account_menu_hover;
          color: $body-text-second-color;
        }
      }
    }
    .rs-dropdown-menu-active + .rs-btn {
      color: $body-text-second-color;
    }
    &.rs-dropdown-placement-bottom-start {
      .rs-dropdown-menu {
        margin-top: -1px;
        border-top-color: $textarea-border-color-active;
      }
    }
    &.rs-dropdown-placement-top-start {
      .rs-dropdown-menu {
        margin-bottom: -1px;
        border-bottom-color: $textarea-border-color-active;
      }
    }
  }
  &.language-dropdown {
    max-width: 120px;
    transform: translateX(8px);
    -webkit-transform: translateX(8px);
    -moz-transform: translateX(8px);
    -ms-transform: translateX(8px);
    -o-transform: translateX(8px);
    .rs-dropdown {
      .rs-btn {
        border: none;
        margin: 3px;
        img {
          margin: 0 24px 0 11px;
        }
        .rs-dropdown-toggle-caret {
          background: transparent url('../svg/dropdown_icon.svg') no-repeat scroll 0 0;
        }
      }   
      &.rs-dropdown-open {
        .rs-btn {
          .rs-dropdown-toggle-caret {
            background-position: 0 -14px;
          }
        }      
      }
      .rs-dropdown-menu {
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        border-top: none;
        .rs-dropdown-item-content {
          font-size: 16px;
          width: 95%;
          margin: 3px;
          border-radius: 3px;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
          text-align: right;
          padding-left: 0;     
          &:hover {
            background: $concrete;
          }
          & img {
            margin-right: 11px;
          }
        }
      }
    }
  }
}

.order_list {
  width: 100%;
  min-height: 100%;
  padding: 48px 0;
  // overflow: hidden;
  h1 {
    display: block;
    width: 100%;
    margin: 0 0 26px;
    padding: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: $main-header-color;
    &:last-child {
      margin: 0;
    }  
  }  
  .search{
    .rs-input-group {
      border-radius: 2px;
      background: $textarea-bg-color;
      border: 1px solid $textarea-border-color;
      width: 100%;
      height: 38px;
      overflow: hidden;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;        
      .rs-input-group-addon {
        border: 0 none;
        border-radius: 0;
        background: $header-search-bg;
        width: 27px;
        max-width: 27px;
        height: 38px;
        padding: 11px 11px 11px 0;
        cursor: pointer;
        .rs-icon.rs-icon-search {
          display: block;
          width: 16px;
          height: 16px;
          background: transparent url('../svg/search_bar_ico.svg') no-repeat scroll 0 0;
          &::before {
            content: none;
            display: none;
          }     
        }
      }
      input {
        display: block;
        background: $header-search-bg;
        border-radius: 0;
        border: 0 none;
        padding: 9px 11px;
        font-family: $font;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;  
        color: $textarea-text-color;
        overflow: hidden;      
        text-overflow: ellipsis;
        width: calc(100% - 27px);
        max-width: calc(100% - 27px);
        margin: 0;
      }
      input::-webkit-input-placeholder {color: $textarea-placeholder-color;opacity: 1;}
      input::-moz-placeholder          {color: $textarea-placeholder-color;opacity: 1;}
      input:-moz-placeholder           {color: $textarea-placeholder-color;opacity: 1;}
      input:-ms-input-placeholder      {color: $textarea-placeholder-color;opacity: 1;}  
      &:focus,
      &:hover,
      &.rs-input-group-focus {
        border: 1px solid $textarea-border-color-active !important;
      }  
    }
    .link_search {
      display: none;
    }
  }     
  .order_list_box {
    margin: 38px 0 0;
    .order_list_item {
      .item_date {
        background: $main-bg-light-grey;
        border-width: 1px 0;
        border-style: solid;
        border-color: $panel-border-color;
        padding: 10px 0;
        p {
          display: block;
          width: 100%;
          margin: 0;
          padding: 0;          
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $panel-p-color;
        }
      }
      .item_body {
        padding: 32px 0;
        border-bottom: 1px solid $panel-border-color;
        background: $body-bg-white;
        &:last-child {
          border: 0 none;
        }
        .button_flex {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          -webkit-align-content: flex-start;
          -ms-flex-line-pack: start;
          align-content: flex-start;
          -webkit-align-items: flex-start;
          -ms-flex-align: start;
          align-items: flex-start;
          width: auto;
          position: relative;
          margin: 0 -15px;
          > div {
            padding: 0 15px;
            -webkit-order: 0;
            -ms-flex-order: 0;
            order: 0;
            -webkit-flex: 0 1 auto;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            -webkit-align-self: auto;
            -ms-flex-item-align: auto;
            align-self: auto;
            &:nth-child(1) {
              width: calc(100% - 230px);
              max-width: calc(100% - 230px);
            }
            &:nth-child(2) {
              width: 230px;
              max-width: 230px;
              padding-top: 12px;
              padding-bottom: 12px;
            }  
            &.wrapper_buttons {
              .rs-btn ~ .rs-btn {
                margin-top: 30px;
              }
            }                         
          }
        }
        .column_flex {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          -webkit-align-content: center;
          -ms-flex-line-pack: center;
          align-content: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          width: auto;
          margin: 0 -15px;
          position: relative;
          > div {
            padding: 12px 15px;
            -webkit-order: 0;
            -ms-flex-order: 0;
            order: 0;
            -webkit-flex: 0 1 auto;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            -webkit-align-self: auto;
            -ms-flex-item-align: auto;
            align-self: auto;
            &:nth-child(1) {
              width: 100%;
              max-width: 100%;
            }
            &:nth-child(2){
              padding-left: 0;
              // width: 70px;
              // max-width: 70px;
              width: 0;
              max-width: 0;
              display: none;              
            }
            &:nth-child(3),
            &:nth-child(4) {
              // width: calc(50% - 35px);
              // max-width: calc(50% - 35px);
              width: 50%;
              max-width: 50%;                
            }                        
          }          
        }
        p {
          display: block;
          width: 100%;
          margin: 0 0 5px;
          padding: 0;
          overflow: hidden;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;          
          color: $main-header-color;     
          &.title {
            font-weight: 500;
          }     
          &.date {
            color: $panel-p-color;
          }
          &.order {
            font-weight: 500;
            font-size: 20px;
            line-height: 23px;
          }
          .new,
          .in_progress,
          .completed,
          .canceled,
          .need-review,
          .awaiting-delivery, 
          .waiting-payment,  
          .default,
          .failed,
          .declined {
            display: block;
            margin: 0;
            padding: 2px 12px;
            border-radius: 3px;
            white-space: nowrap;            
            text-overflow: ellipsis;
            overflow: hidden;
            width: auto;
            max-width: 100%;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            letter-spacing: -0.006em;     
            &.new {
              background: $new-order-bg;
            }   
            &.in_progress {
              background: $in_progress-order-bg;
            }  
            &.completed {
              background: $completed-order-bg;
            }   
            &.failed {
              background: $failed-order-bg;
            } 
            &.canceled {
              background: $canceled-order-bg;
            }
            &.need-review {
              background: $need-review-order-bg;
            }
            &.awaiting-delivery {
              background: $awaiting-delivery-order-bg;
            }
            &.waiting-payment {
              background: $waiting-payment-order-bg;
            }
            &.default {
              background: transparent;
            }   
            &.declined {
              background: $declined-order-bg;
            }
          }
          &:last-child {
            margin: 0;
          }
        }
        .header-flex {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          -webkit-align-content: center;
          -ms-flex-line-pack: center;
          align-content: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          width: auto;
          position: relative;
          margin: -3px -3px 5px;
          &:last-child {
            margin-bottom: 0;
          }
          > div {
            padding: 3px 3px 0;
            -webkit-order: 0;
            -ms-flex-order: 0;
            order: 0;
            -webkit-flex: 0 1 auto;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            -webkit-align-self: auto;
            -ms-flex-item-align: auto;
            align-self: auto;
            width: auto;
            max-width: 100%;            
          }
        }
        .img-box {
          width: 55px;
          height: 55px;
          overflow: hidden;
          margin: 0;
          padding: 0;
          position: relative; 
          border-radius: 4px;
          > img {
            display: block;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
        .rs-btn.rs-btn-ghost {
          display: block;
          width: 100%;
          margin: 0;
          padding: 10px 6px;
          border-radius: 2px;
          border: 1px solid $H500;
          color: $H500;
          font-size: 15px;        
          line-height: 20px;
          font-weight: 500;
          cursor: pointer;
          > span {
            display: block;
            width: 100%;
            margin: 0;
            padding: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          &:hover {
            border: 1px solid $H700;
            color: $H700;
          }
        }   
      }
    }
  }
  .wrapper_flex_search {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: auto;
    position: relative;
    margin: 0 -9px;
    > div {
      padding: 0 9px;
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
      &:nth-child(1) {
        width: 218px;
        max-width: 218px;
      }
      &:nth-child(2) {
        width: calc(100% - 218px);
        max-width: calc(100% - 218px);
      }      
    }    
  }
  .product_not_found {
    padding-bottom: 0;
    .banner {
      max-width: 156px;
      margin: 0 auto 24px;
      > span {
        padding: 0 0 72.85714285714286%;
        background: transparent url('../svg/orders_not_found.svg') no-repeat scroll 50% 50%;
        background-size: contain;
      }
    }
  }
}

@media (max-width: 767px) {
  .order_list {
    .order_list_box {
      margin: 30px 0 0;
      .order_list_item {
        .item_body {
          padding: 20px 0;
          .button_flex {
            > div {
              &:nth-child(1) {
                width: 100%;
                max-width: 100%;
              }
              &:nth-child(2) {
                width: 100%;
                max-width: 100%;
              }            
            }
          }
          .column_flex {
            > div {
              &:nth-child(3) {
                // width: calc(100% - 70px);
                // max-width: calc(100% - 70px);      
                width: 100%;
                max-width: 100%;                          
              }
              &:nth-child(4) {
                width: 100%;
                max-width: 100%;
              }                        
            }          
          }
        }
      }
    }
    .wrapper_flex_search {
      > div {
        &:nth-child(1) {
          width: 100%;
          max-width: 100%;
          -webkit-order: 2;
          -ms-flex-order: 2;
          order: 2;   
          padding-top: 15px;       
        }
        &:nth-child(2) {
          width: 100%;
          max-width: 100%;
          -webkit-order: 1;
          -ms-flex-order: 1;
          order: 1;          
        }      
      }    
    }
  }  
}

.canceling_registration {
  max-width: 470px;
  width: calc(100% - 30px);
  margin: 90px auto 60px;
  .rs-modal-content {
    padding: 44px 45px;
    margin: 0;
    background: $body-bg-white;
    border: 0 none;
    border-radius: 4px;
    box-shadow: 0px 2px 3px $calendar-box-shadow-color;
    text-align: center;
    p {
      margin: 0;
      padding: 0;
      display: block;
      width: 100%;
      overflow: hidden;
    }
    .rs-modal-header {
      margin: 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: $body-text-second-color;
      .rs-modal-header-close {
        width: 26px;
        height: 26px;
        margin: 0;
        padding: 0;
        overflow: hidden;
        border-radius: 50%;
        background: $body-bg-white url("../svg/delete_button.svg") no-repeat scroll 50% 50%;
        background-size: 12px 12px;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: auto;
        left: -38px;
        bottom: auto;
        &:hover {
          opacity: 0.75;
        }
        > span {
          display: none;
        }
        &::before {
          content: none;
          display: none;
        }
      }
      > p:last-child {
        margin-bottom: 18px;
      }
    }
    .rs-modal-body {
      margin: 0;
      padding: 0;
      > p {
        margin: 0 0 22px;
        font-size: 16px;
        line-height: 19px;
        color: $panel-p-color;
        &.mb0,
        &:last-child {
          margin-bottom: 0;
        }
      }
      .flex-container {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        margin: 25px -10px 0;
        width: auto;
        > div {
          -webkit-order: 0;
          -ms-flex-order: 0;
          order: 0;
          -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
          flex: 0 1 auto;
          -webkit-align-self: auto;
          -ms-flex-item-align: auto;
          align-self: auto;
          width: 50%;
          max-width: 150px;          
          padding: 0 10px;
        }
        .rs-btn {
          min-width: 100%;
          &.rs-btn-ghost {
            display: block;
            width: 100%;
            margin: 0;
            padding: 10px 6px;
            border-radius: 2px;
            border: 1px solid $H500;
            color: $H500;
            font-size: 16px;        
            line-height: 20px;
            font-weight: 500;
            cursor: pointer;
            > span {
              display: block;
              width: 100%;
              margin: 0;
              padding: 0;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            &:hover {
              border: 1px solid $H700;
              color: $H700;
            }
          }            
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .canceling_registration {
    .rs-modal-content {
      .rs-modal-header {
        .rs-modal-header-close {
          top: -38px;
          left: 0;
        }
      }      
    }
  }
}

.lost-access-phone {
  width: 100%;
  min-height: 100%;
  padding: 48px 0;
  overflow: hidden;
  background: $main-bg-light-grey;
  > div {
    max-width: 470px;
    margin: 0 auto;
    background: $body-bg-white;
    padding: 40px 45px 33px;
    border-radius: 4px;
    border: 1px solid $panel-border-color;
    overflow: hidden;
    position: relative;
    border-top: 3px solid $H500;
    .title-box {
      margin-bottom: 22px;
      p {
        display: block;
        width: 100%;
        padding: 0;
        margin: 0 0 17px;
        overflow: hidden;
        font-weight: 500;
        font-size: 18px;
        line-height: 19px;
        color: $body-text-second-color;
        &:last-child {
          margin-bottom: 0;
          font-size: 16px;
          font-weight: normal;
          color: $panel-p-color;
        }
      }
    }
    .rs-control-label {
      font-size: 16px;
    }
    .rs-form-control-wrapper {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
      &:last-child {
        margin: 0;
      }
      .rs-input {
        display: block;
        width: 100%;
        background: $textarea-bg-color;
        border: 1px solid $textarea-border-color;
        border-radius: 2px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        resize: none;
        color: $textarea-text-color;
        padding: 10px 14px;
      }
      .rs-input::-webkit-input-placeholder {
        color: $textarea-placeholder-color;
        opacity: 1;
      }
      .rs-input::-moz-placeholder {
        color: $textarea-placeholder-color;
        opacity: 1;
      }
      .rs-input:-moz-placeholder {
        color: $textarea-placeholder-color;
        opacity: 1;
      }
      .rs-input:-ms-input-placeholder {
        color: $textarea-placeholder-color;
        opacity: 1;
      }
      .rs-input:hover,
      .rs-input:focus {
        border: 1px solid $textarea-border-color-active;
      }
    }
  }
  .separate-block {
    position: relative;
    margin: 30px 0;
    height: 19px;
    text-align: center;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      border-top: 1px solid $separate-border-bg;
      width: 100%;
      top: 50%;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      margin: -0.5px 0 0;
      display: block;
    }
    > span {
      background: $body-bg-white;
      padding: 0 12px;
      font-size: 16px;
      line-height: 19px;
      color: $separate-text-color;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      vertical-align: top;
      text-overflow: ellipsis;
    }
  }
  .contacts {
    > p {
      display: block;
      overflow: hidden;
      margin: 0 0 25px;
      padding: 0;
      text-align: center;
      font-size: 16px;
      line-height: 19px;
      color: $body-text-second-color;
      a {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
      &:last-child {
        margin: 0;
      }
    }
  }
}

.lost-access-phone-confirm-request {
  max-width: 470px;
  width: calc(100% - 30px);
  margin: 90px auto 60px;
  .rs-modal-content {
    padding: 44px 45px;
    margin: 0;
    background: $body-bg-white;
    border: 0 none;
    border-radius: 4px;
    box-shadow: 0px 2px 3px $calendar-box-shadow-color;
    text-align: center;
    p {
      margin: 0;
      padding: 0;
      display: block;
      width: 100%;
      overflow: hidden;
    }
    .rs-modal-header {
      margin: 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: $body-text-second-color;
      .rs-modal-header-close {
        width: 26px;
        height: 26px;
        margin: 0;
        padding: 0;
        overflow: hidden;
        border-radius: 50%;
        background: $body-bg-white url("../svg/delete_button.svg") no-repeat scroll 50% 50%;
        background-size: 12px 12px;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: auto;
        left: -38px;
        bottom: auto;
        &:hover {
          opacity: 0.75;
        }
        > span {
          display: none;
        }
        &::before {
          content: none;
          display: none;
        }
      }
      > p:last-child {
        margin-bottom: 18px;
      }
    }
    .rs-modal-body {
      margin: 0;
      padding: 0;
      > p {
        font-size: 16px;
        line-height: 19px;
        color: $panel-p-color;
      }
      .rs-btn {
        min-width: 170px;
        margin-top: 30px;
        &.rs-btn-ghost {
          display: block;
          width: 100%;
          margin: 0;
          padding: 10px 6px;
          border-radius: 2px;
          border: 1px solid $H500;
          color: $H500;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          cursor: pointer;
          > span {
            display: block;
            width: 100%;
            margin: 0;
            padding: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          &:hover {
            border: 1px solid $H700;
            color: $H700;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .lost-access-phone-confirm-request {
    .rs-modal-content {
      .rs-modal-header {
        .rs-modal-header-close {
          top: -38px;
          left: 0;
        }
      }
    }
  }
}

.login_error,
.registration_error {
  display: block;
  width: 100%;
  overflow: hidden;
  padding: 0 !important;
  margin: 7px 0 7.89px !important;
  color: $H500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: -0.006em;  
  font-weight: 400 !important;
  &:last-child {
    margin-bottom: 0 !important;
  }
}

.rs-supplier-open {
  overflow: hidden;
}
.rs-supplier-wrapper {
  position: fixed;
  overflow: auto;
  z-index: 1050;
  top: 56px;
  bottom: 0;
  left: 0;
  right: 0;
}
.rs-supplier {
  display: none;
  overflow: visible;
  outline: 0;
  margin: 0;
  position: relative;
  width: auto;
  z-index: 1050;
}
.rs-supplier-content {
  position: relative;
  background-color: $body-bg-white;
  border-radius: 0;
  outline: 0;
  padding: 0;
}
.rs-supplier-backdrop {
  position: fixed;
  top: 56px;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $backdrop-bg-color;
}
.rs-supplier-backdrop.fade {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
}
.rs-supplier-backdrop.in {
  opacity: 0.7;
}

.white-rs-supplier-open {
  overflow: hidden;
}
.white-rs-supplier-wrapper {
  position: fixed;
  overflow: auto;
  z-index: 1050;
  top: 116px;
  bottom: 0;
  left: 0;
  right: 0;
}
.white-rs-supplier {
  display: none;
  overflow: visible;
  outline: 0;
  margin: 0;
  position: relative;
  width: auto;
  z-index: 1050;
}
.white-rs-supplier-content {
  position: relative;
  background-color: $body-bg-white;
  border-radius: 0;
  outline: 0;
  padding: 0;
}
.white-rs-supplier-backdrop {
  position: fixed;
  top: 116px;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $backdrop-bg-color;
}
.white-rs-supplier-backdrop.fade {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
}
.white-rs-supplier-backdrop.in {
  opacity: 0.7;
}

.supplier_menu {
  width: 20px;
  height: 20px;
  .rs-btn {
    display: block;
    width: 20px;
    margin: 0;
    padding: 0;
    background: transparent;
    .rs-icon.rs-icon-bars {
      display: block;
      width: 20px;
      height: 20px;
      background: transparent url('../svg/bars-ico.svg') no-repeat scroll 50% 50%;
      background-size: 18px 18px;
      &::before {
        content: none;
        display: none;
      }
    }
    .rs-icon.rs-icon-close {
      display: block;
      width: 20px;
      height: 20px;
      background: transparent url('../svg/delete_button.svg') no-repeat scroll 50% 50%;
      background-size: 18px 18px;
      &::before {
        content: none;
        display: none;
      }      
    }    
    .rs-ripple-pond {
      display: none !important;
    }
    &:hover {
      opacity: 0.75;
    }
  }
}

.supplier_mobile_menu {
  margin: 0;
  width: 100%;
  max-width: 100%;
  max-height: calc(100vh - 56px);
  height: auto;
  overflow: auto;
  padding: 0;
  .white-rs-supplier-content,
  .rs-supplier-content {
    box-shadow: none;
    background: $header-bg-2;
    border-radius: 0;
    padding: 0;
    margin: 0;
    .white-rs-modal-body,
    .rs-modal-body {
      padding: 0;
      margin: 0;   
      overflow: hidden !important;   
      max-height: none !important;  
      ul,
      ul > li {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
      }
      ul > li {
        padding: 19px 15px;
        border-bottom: 1px solid $supplier-bg-separator;
        &:last-child {
          border: 0 none;
        }
        &.bg_white {
          background: $body-bg-white;
          border-bottom: 1px solid $account_menu_border;         
          .link {
            color: $body-text-third-color;
          }
        }
      }
      .link {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        max-width: 100%;
        padding: 0;
        border-radius: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $body-text-white-color;     
        text-decoration: none;   
        background: none;
        cursor: pointer;
        text-align: inherit;
        .rs-ripple-pond {
          display: none !important;
        }
        .flex {
          display: block;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
          -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          -webkit-align-content: center;
          -ms-flex-line-pack: center;
          align-content: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          width: auto;
          position: relative;
          margin: 0 -5px;
          > span {
            padding: 0 5px;
            display: block;
            &:nth-child(1) {
              -webkit-order: 0;
              -ms-flex-order: 0;
              order: 0;
              -webkit-flex: 0 1 auto;
              -ms-flex: 0 1 auto;
              flex: 0 1 auto;
              -webkit-align-self: auto;
              -ms-flex-item-align: auto;
              align-self: auto;
              width: 30px;
              max-width: 30px;
            }
            &:nth-child(2) {
              -webkit-order: 0;
              -ms-flex-order: 0;
              order: 0;
              -webkit-flex: 1 1 auto;
              -ms-flex: 1 1 auto;
              flex: 1 1 auto;
              -webkit-align-self: auto;
              -ms-flex-item-align: auto;
              align-self: auto;
              width: calc(100% - 30px);
              max-width: calc(100% - 30px);             
            }   
            .rs-icon.rs-icon-mobile {
              display: block;
              width: 10px;
              height: 16px;
              background: transparent url('../svg/phone_ico_white.svg') no-repeat scroll 50% 50%;
              background-size: contain;
              margin: 0 auto;
              &::before {
                content: none;
                display: none;
              }
            }  
            .rs-icon.rs-icon-avatar {
              display: block;
              width: 16px;
              height: 17px;
              background: transparent url('../svg/sign_in_ico.svg') no-repeat scroll 50% 50%;
              background-size: contain;
              margin: 0 auto;
              &::before {
                content: none;
                display: none;
              }            
            }     
            .rs-icon.rs-icon-th-large {
              display: block;
              width: 14px;
              height: 14px;
              background: transparent url('../svg/products_catalog_ico.svg') no-repeat scroll 50% 50%;
              background-size: contain;
              margin: 0 auto;
              &::before {
                content: none;
                display: none;
              }            
            }      
            .rs-icon.rs-icon-cog {
              display: block;
              width: 20px;
              height: 20px;
              background: transparent url('../svg/header/settings-ico.svg') no-repeat scroll 50% 50%;
              background-size: contain;
              margin: 0 auto;
              &::before {
                content: none;
                display: none;
              }            
            }   
            .rs-icon.rs-icon-sign-out {
              display: block;
              width: 20px;
              height: 20px;
              background: transparent url('../svg/header/log-out-ico.svg') no-repeat scroll 50% 50%;
              background-size: contain;
              margin: 0 auto;
              &::before {
                content: none;
                display: none;
              }            
            }     
            .rs-icon.rs-icon-task {
              display: block;
              width: 20px;
              height: 20px;
              background: transparent url('../svg/header/orders-ico.svg') no-repeat scroll 50% 50%;
              background-size: contain;
              margin: 0 auto;
              &::before {
                content: none;
                display: none;
              }               
            }  
            .rs-icon.rs-icon-map-marker {
              display: block;
              width: 20px;
              height: 20px;
              background: transparent url('../svg/header/address-ico.svg') no-repeat scroll 50% 50%;
              background-size: contain;
              margin: 0 auto;
              &::before {
                content: none;
                display: none;
              }               
            }                                     
            > span {
              display: block;
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }    
          }
        } 
        &:hover {
          opacity: 0.75;
        }  
        &.sub_menu {
          position: relative;
          padding-left: 24px;
          &::before {
            content: "";
            display: block;
            width: 18px;
            height: 14px;
            margin: -7px 0 0;
            padding: 0;
            position: absolute;
            top: 50%;
            left: 0;
            background: transparent url('../svg/header/dropdown_icon.svg') no-repeat scroll 0 -14px;
            background-size: 18px 28px;
          }
          &.open {
            &::before {
              background-position: 0 0;
            }
          }
        }     
      }
    }
  }
  &.white {
    max-height: calc(100vh - 116px);
    .white-rs-supplier-content,
    .rs-supplier-content {
      background: $body-bg-white;
      .white-rs-modal-body,
      .rs-modal-body {
        ul > li {
          border-bottom: 1px solid $account_menu_border; 
        }
        .link {
          color: $body-text-third-color;
        }
      }
    }
  }  
}

.offers_list {
  width: 100%;
  min-height: 100%;
  padding: 48px 0;
  overflow: hidden;
  background: $main-bg-light-grey;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  width: 100%;    
  .rs-panel {
    background: $panel-bg-color;
    border: 1px solid $panel-border-color;
    border-radius: 2px;
    padding: 24px;
    margin: 0;
    position: relative;
    overflow: visible;
    .rs-panel-body {
      padding: 0;
      min-height: 77px;
    }
    &.border_red {
      border: 1px solid $H500;
    }
    .best_offer {
      height: 37px;
      position: absolute;
      top: 20px;
      left: -8px;
      > span {
        display: block;
        background: $H500;
        width: 100%;
        height: 25px;
        color: $body-text-white-color;
        padding: 4.5px;
        padding-left: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;  
        text-transform: uppercase;  
        overflow: hidden;    
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
      }
      &::after {
        content: "";
        display: block;
        border-style: solid;
        border-color: transparent;
        border-width: 6px 4px;
        border-top-color: $H900;
        border-right-color: $H900;
        width: 0;
        height: 0;   
        margin: 0;
        padding: 0;     
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
  .offer_number {
    h1 {
      display: block;
      width: 100%;
      overflow: hidden;
      margin: 0 0 10px;
      padding: 0;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      color: $main-header-color; 
      &:last-child {
        margin: 0;
      }     
    }
    p {
      display: block;
      width: 100%;
      overflow: hidden;
      margin: 0;
      padding: 0;  
      color: $offers-text-color;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      .offer_number_description{
        color: $H500;
        >span {
          white-space: nowrap;
        }
      }
    }
    .rs-btn.rs-btn-ghost {
      display: block;
      width: 100%;
      max-width: 190px;
      margin: 0;
      padding: 10px 6px;
      border-radius: 2px;
      border: 1px solid $footer-bg-1;
      color: $footer-bg-1;
      font-size: 15px;        
      line-height: 20px;
      font-weight: 500;
      cursor: pointer;
      > span {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &:hover {
        border: 1px solid $footer-bg-2;
        color: $footer-bg-2;
      }
    }  
    .flex {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      width: auto;
      position: relative;
      margin: 0 -25px;
      > div {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        padding: 0 25px;
        &:nth-child(1) {
          width: calc(100% - 240px);
          max-width: calc(100% - 240px);
        }
        &:nth-child(2) {
          width: 240px;
          max-width: 240px;
        }        
      }
    }
  }
  .offers_list_flex {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    position: relative;
    margin: 0 -15px;
    width: auto;
    > div {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
      padding: 20px 15px 0;
      width: 33.33333333333333%;
      max-width: 33.33333333333333%;
    }
  }
  .grey_box {
    padding: 22px 18px;
    background: $main-bg-light-grey;
    border-radius: 2px;
    margin: 25px 0 30px;
    &.white_bg {
      background: $body-bg-white;
      border: 1px solid $panel-border-color;
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }    
    p {
      display: block;
      width: 100%;
      overflow: hidden;
      padding: 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: $body-text-second-color;      
      margin: 0 0 9px;
      &.price{
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
      }
      &.vat{
        color: $body-text-fourth-color;
      }
    }
  }
  .toggle_hide_view {
    margin: 30px 0 0;
    &:first-child {
      margin: 0;
    } 
    .rs-btn.rs-btn-link {
      display: inline-block;
      vertical-align: top;
      width: auto;
      max-width: 100%;
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $body-text-second-color;
      background: none;
      border-radius: 0;
      cursor: pointer;
      text-decoration: none;
      .flex {
        display: block;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        margin: 0 -4px;
        min-height: 20px;
        width: auto;
        > span {
          display: block;
          -webkit-order: 0;
          -ms-flex-order: 0;
          order: 0;
          -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
          flex: 0 1 auto;
          -webkit-align-self: auto;
          -ms-flex-item-align: auto;
          align-self: auto; 
          padding: 0 4px; 
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;          
          > span {
            display: block;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 0;
            padding: 0;
          }
          &:nth-child(1) {
            text-decoration: underline;
          }
          .rs-icon {
            display: block;
            width: 12px;
            height: 8px;
            overflow: hidden;
            margin: 0;
            padding: 0;
            overflow: hidden;
            &.open {
              background: transparent url('../svg/dropdown_open.svg') no-repeat scroll 50% 50%;
              background-size: contain;
            }
            &.close {
              background: transparent url('../svg/dropdown_close.svg') no-repeat scroll 50% 50%;
              background-size: contain;              
            }
          }            
        }        
      } 
      &:hover {
        .flex {
          > span {
            text-decoration: none;
          }
        }
      }     
    }
  }  
  .supplier_name {
    margin: 0 0 22px;
    p {
      display: block;
      overflow: hidden;
      margin: 0 0 5px;
      padding: 0;
      width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $body-text-second-color;    
      &.header {
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        margin: 0 0 15px;
        width: calc(100% - 77px);
        span{
          opacity: 0.6;
        }
      }  
      &:last-child {
        margin: 0;
      }
    }
  }
  .rs-btn.rs-btn-primary {
    width: 100%;
    margin-top: 30px;
    display: block;
  }
  .tag_box {
    min-height: 25px;
    margin: 20px -5px;
    position: relative;
    width: auto;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;    
    > p {
      margin: 0;
      padding: 0 5px;
      width: auto;
      max-width: 50%;
      position: relative;
      z-index: 2;
      > span {
        display: block;
        width: 100%;
        overflow: hidden;
        padding: 0 8px;
        text-overflow: ellipsis;
        font-size: 14px;
        line-height: 25px;
        font-weight: 400;
        color: $body-text-white-color;
        &.with_delay{
          background: $offers-with_delay-bg;
        }
        &.partial_order{
          background: $offers-partial_order-bg;
        }      
      }
      &:first-child:last-child{
        max-width: 100%;
      }
    }
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 5px;
      right: 5px;
      height: 2px;
      margin: -1px 0 0;
      padding: 0;
      background: $offers-line-bg;
    }
  }
  .price_box {
    margin: 30px 0;
    p {
      display: block;
      margin: 0; 
      padding: 0;
      overflow: hidden;
      color: $body-text-second-color;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
    }    
    ul,
    ul > li {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
    }
    ul {
      margin: 0 -10px 20px;
      position: relative;
      width: auto;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      > li {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        padding: 0 10px;
        &:nth-child(1) {
          width: 200px;
          max-width: 200px;
        }
        &:nth-child(2) {
          width: calc(100% - 200px);
          max-width: calc(100% - 200px);
          p {
            text-align: left;
          }         
        }        
      }      
      &:last-child {
        margin-bottom: 0;
      }
      &.total {
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
        }
      }
    }
  }
  .offer-box {
    margin: 30px 0 0;
    .products-list {
      width: 100%;
      ul,
      ul > li {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
      }
      ul > li {
        padding: 20px 0;
        border-bottom: 1px solid $panel-border-color;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
          border: 0 none;
        }
      }
      p {
        display: block;
        margin: 0 0 5px;
        padding: 0;
        width: 100%;
        overflow: hidden;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: $panel-p-color;
        &.description{
          color: $main-header-color;
          margin: 0 0 8px;
        }
        &.price-all{
          color: $main-header-color;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
        }      
        &:last-child {
          margin: 0;
        }
      }
      .flex_box_p {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        width: auto;
        margin: 0 -7.5px 5px;
        > p {
          width: auto;
          max-width: 100%;
          padding: 0 7.5px;
          margin: 0;
        }
      }      
      .products-img {
        width: 80px;
        height: 80px;
        overflow: hidden;
        margin: 0 0 5px;
        padding: 0;
        position: relative;
        border: 1px solid $panel-border-color;
        background: $body-bg-white;
        > img {
          display: block;
          width: auto;
          height: auto;
          max-width: calc(100% - 10px);
          max-height: calc(100% - 10px);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        > .out_of_stock {
          position: absolute;
          left: 0;
          bottom: 0;
          background: $out_of_stock_bg;
          height: 26px;
          padding: 2px 3px 4px;
          font-size: 14px;
          line-height: 22px;          
          text-align: center;
          letter-spacing: -0.006em;
          color: $out_of_stock_color;          
          > span {
            display: block;
            width: 100%;
            margin: 0;
            padding: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        &:last-child {
          margin: 0;
        }
      }
      p.quantity{
        padding: 7px 3px;
        font-size: 14px;
        line-height: 16px;
        color: $main-header-color;
        text-align: center;
        background: $main-bg-light-grey;
        width: 80px;
        > span {
          display: block;
          width: 100%;
          margin: 0;
          padding: 0;
          overflow: hidden;
        }
      }
      .products-list-flex {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        margin: 0;
        position: relative;
        width: auto;
        > div {
          -webkit-order: 0;
          -ms-flex-order: 0;
          order: 0;
          -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
          flex: 0 1 auto;
          -webkit-align-self: auto;
          -ms-flex-item-align: auto;
          align-self: auto;
          &:nth-child(1) {
            width: 94px;
            max-width: 94px;
            padding: 0 0 0 7px;
          }
          &:nth-child(2) {
            width: calc(100% - 94px);
            max-width: calc(100% - 94px);
            padding: 0 7px 0 0;
          }        
        }
        &.opacity {
          opacity: 0.6;
        }
      }
    }
    .warning_box {
      margin: 10px 0 0;
    }
    .replacement {
      margin: 12px 0 0;
      .label {
        margin: 0 0 12px;
        display: block;
        height: 36px;
        position: relative;
        text-align: left;
        &::before {
          content: "";
          display: block;
          width: calc(100% - 170px);
          left: 170px;
          top: 50%;
          position: absolute;
          height: 1px;
          background: $panel-border-color;
        }
        p {
          display: inline-block;
          margin: 0;
          padding: 10px 14px 10px 35px;
          width: 145px;
          position: relative;
          vertical-align: top;
          background: $replacement-bg;
          height: 36px;
          border-radius: 4px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          letter-spacing: -0.006em;
          color: $body-text-third-color;  
          > span {
            display: block;
            width: 100%;
            margin: 0;
            padding: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          } 
          &::before {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            position: absolute;
            margin: 0;
            padding: 0;
            top: 10px;
            left: 11px;
            background: transparent url('../svg/replacement-ico.svg') no-repeat scroll 50% 50%;
            background-size: cover;
          }       
        }
      }
    }
    .rs-panel-group {
      border-radius: 0;
      .rs-panel {
        border: 0 none;
        border-radius: 0;
        padding: 0;
        margin: 0 0 30px;
        &:last-child {
          margin: 0;
        }
        &::before {
          content: none;
          display: none;
        }
        .rs-panel-heading {
          background: $replacement-bg;
          border-radius: 5px;
          padding: 10px 17px 10px 44px;
          .rs-panel-title {
            display: block;
            width: 100%;
            margin: 0; 
            padding: 0;
            overflow: hidden;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: $body-text-second-color;
            > .count {
              color: $panel-p-color;
            }            
          }
          &::before {
            content: "";
            width: 18px;
            height: 14px;
            transition: none;
            top: 50%;
            left: 13px;
            right: auto;
            transform: none;
            margin: -7px 0 0;
            background: transparent url('../svg/dropdown_icon.svg') no-repeat scroll 0 0;
            transform: none !important;
          }
        }
          &.rs-panel-in {
            .rs-panel-heading {
              &::before {
                background: transparent url('../svg/dropdown_icon.svg') no-repeat scroll 0 -14px;
              }
            }        
          }	        
        .rs-panel-body {
          padding: 30px 0 0;
          height: auto;
        }
      }
      .delivery-time{
        font-size: 16px;
        margin: 0 0 15px;
      }
    }
  }
}

@media (max-width: 991px) {
  .offers_list {
    .offer_number {
      p {
        margin: 0 0 10px;
        &:last-child {
          margin: 0;
        }                
      }
      .flex {
        > div {
          -webkit-order: 0;
          -ms-flex-order: 0;
          order: 0;
          -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
          flex: 0 1 auto;
          -webkit-align-self: auto;
          -ms-flex-item-align: auto;
          align-self: auto;
          padding: 0 25px;
          &:nth-child(1) {
            width: 100%;
            max-width: 100%;
          }
          &:nth-child(2) {
            padding-top: 15px;
            width: 100%;
            max-width: 100%;
          }        
        }
      }
    }    
    .offers_list_flex {
      > div {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.decline-all-offers {
  max-width: 470px;
  width: calc(100% - 30px);
  margin: 90px auto 60px;
  .rs-modal-content {
    padding: 44px 45px;
    margin: 0;
    background: $body-bg-white;
    border: 0 none;
    border-radius: 4px;
    box-shadow: 0px 2px 3px $calendar-box-shadow-color;
    text-align: center;
    p {
      margin: 0;
      padding: 0;
      display: block;
      width: 100%;
      overflow: hidden;
    }
    .rs-modal-header {
      margin: 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: $body-text-second-color;
      .rs-modal-header-close {
        width: 26px;
        height: 26px;
        margin: 0;
        padding: 0;
        overflow: hidden;
        border-radius: 50%;
        background: $body-bg-white url("../svg/delete_button.svg") no-repeat scroll 50% 50%;
        background-size: 12px 12px;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: auto;
        left: -38px;
        bottom: auto;
        &:hover {
          opacity: 0.75;
        }
        > span {
          display: none;
        }
        &::before {
          content: none;
          display: none;
        }
      }
      > p:last-child {
        margin-bottom: 18px;
      }
    }
    .rs-modal-body {
      margin: 0;
      padding: 0;
      > p {
        font-size: 16px;
        line-height: 19px;
        color: $panel-p-color;
      }
      .rs-btn {
        display: block;
        width: 100%;
        margin: 20px 0 0;
        &.rs-btn-ghost {
          display: block;
          padding: 10px 6px;
          border-radius: 2px;
          border: 1px solid $H500;
          color: $H500;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          cursor: pointer;
          > span {
            display: block;
            width: 100%;
            margin: 0;
            padding: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          &:hover {
            border: 1px solid $H700;
            color: $H700;
          }
        }
      }
      .flex-box {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        width: auto;
        position: relative;
        margin: 10px -10px 0;
        > div {
          -webkit-order: 0;
          -ms-flex-order: 0;
          order: 0;
          -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
          flex: 0 1 auto;
          -webkit-align-self: auto;
          -ms-flex-item-align: auto;
          align-self: auto;
          padding: 0 10px;
          width: 150px;
          max-width: 150px;
        }
      }
    }
  }
}

.request-product-wrapper {
  background: #F7F8FA;
  border: 1px solid #E5E9EB;
  border-radius: 4px;
  height: 162px;
  min-width: 422px;
  position: relative;
}

.corner-img {
  width: 84px;
  height: 115px;
  display: block;
  position: absolute;
  background: url("../svg/request_productHEB.svg") no-repeat;
  bottom: 0;
  left: 0;
}

.request-product-header {
  color: #28253D;
  font-family: $font;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 117%;
  width: 226px;
  height: 40px;
  padding-top: 24px;
  padding-right: 24px;
}

.request-button {
  margin-top: 48px;
  background: white;
  width: 160px;
  margin-right: 24px;
}

.warning_add_rfp {
  display: flex;
  justify-content: space-between;
}

.rfp_icon {
  padding-top: 8px;

  svg {
    margin-right: 16px;
  }
}

.add-rfp {
  max-width: 470px;
  width: calc(100% - 30px);
  margin: 90px auto 60px;
  .rs-modal-content {
    padding: 44px 26px;
    margin: 0;
    background: $body-bg-white;
    border: 0 none;
    border-radius: 4px;
    box-shadow: 0px 2px 3px $calendar-box-shadow-color;
    text-align: center;
    p {
      margin: 0;
      padding: 0;
      display: block;
      width: 100%;
      overflow: hidden;
    }
    .rs-modal-header {
      margin: 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: $body-text-second-color;
      .rs-modal-header-close {
        width: 26px;
        height: 26px;
        margin: 0;
        padding: 0;
        overflow: hidden;
        border-radius: 50%;
        background: $body-bg-white url("../svg/delete_button.svg") no-repeat scroll 50% 50%;
        background-size: 12px 12px;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: auto;
        left: -38px;
        bottom: auto;
        &:hover {
          opacity: 0.75;
        }
        > span {
          display: none;
        }
        &::before {
          content: none;
          display: none;
        }
      }
      > p:last-child {
        margin-bottom: 18px;
      }
    }
    .rs-modal-body {
      margin: 0;
      padding: 0;
      > p {
        font-size: 16px;
        line-height: 19px;
        color: $panel-p-color;
      }
      .rs-btn {
        display: block;
        width: 100%;
        margin: 20px 0 0;
        &.rs-btn-ghost {
          display: block;
          padding: 10px 6px;
          border-radius: 2px;
          border: 1px solid $H500;
          color: $H500;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          cursor: pointer;
          > span {
            display: block;
            width: 100%;
            margin: 0;
            padding: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          &:hover {
            border: 1px solid $H700;
            color: $H700;
          }
        }
      }
      .flex-box {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        width: auto;
        position: relative;
        margin: 10px -10px 0;
        > div {
          -webkit-order: 0;
          -ms-flex-order: 0;
          order: 0;
          -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
          flex: 0 1 auto;
          -webkit-align-self: auto;
          -ms-flex-item-align: auto;
          align-self: auto;
          padding: 0 10px;
          width: 150px;
          max-width: 150px;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .decline-all-offers, .add-rpf {
    .rs-modal-content {
      .rs-modal-header {
        .rs-modal-header-close {
          top: -38px;
          left: 0;
        }
      }
    }
  }
}

.quantity {
  .rs-input-group.rs-input-number {
    border: 0 none !important;
    border-radius: 0 !important;
    display: block !important;
    background: none !important;
    .rs-input {
      border: 0 none !important;
      border-radius: 0 !important;
      display: block !important;
      background: none !important;
      color: inherit;    
      font-size: inherit; 
      line-height: inherit; 
      font-weight: inherit;
      margin: 0;
      padding: 0;
      text-align: center;
    }
    .rs-input-number-btn-group-vertical {
      display: none !important;
    }
  }
}

.empty_cart {
  min-height: 100%;
  padding: 48px 0;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  .empty_cart_box {
    max-width: 432px;
    margin: 0 auto;
  }  
  .banner {
    display: block;
    width: 100%;
    max-width: 150px;
    margin: 0 auto 28px;
    &:last-child {
      margin: 0 auto;
    }
    > span {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0 0 113.33333333333331%;
      background: transparent url('../img/empty_cart.png') no-repeat scroll 50% 50%;
      background-size: contain;      
    }
  }
  p {
    display: block;
    width: 100%;
    margin: 28px 0;
    padding: 0;
    overflow: hidden;
    text-align: center;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: $main-header-color;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }    
  }  
  .button {
    max-width: 220px;
    margin: 0 auto;
    > button {
      display: block;
      width: 100%;
    }
  }  
}

.rs-tooltip {
  .rs-tooltip-inner {
    background: $body-bg-white;
    box-shadow: 0px 2px 8px $rs-tooltip-shadow;
    border-radius: 4px;
    padding: 10px 15px;
    overflow-wrap: normal;
    max-width: 450px;
    p {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
      overflow: hidden;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: $body-text-third-color;    
      text-align: right;  
    }
    .rs-btn.rs-btn-link {
      display: inline-block;
      vertical-align: top;
      width: auto;
      max-width: 100%;
      text-decoration: none;
      border: 0 none;
      border-radius: 0;
      margin: 0;
      padding: 0;
      font-size: 16px;
      line-height: 20px;
      color: $H500;  
      height: auto;
      min-width: auto;      
      > span {
        display: block;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }       
      &:hover {
        text-decoration: underline;
      }    
    }
    .flex-box {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      width: auto;
      margin: 0 -20px;  
      position: relative;    
      > div {
        padding: 4px 20px;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
      }
    }
  }
  &.placement-bottom {
    .rs-tooltip-arrow {
      width: 12px;
      height: 6px;
      border: 0 none;
      background: none;        
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: -1px;
        left: 0;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 0 6px 6px;
        border-bottom-color: $rs-tooltip-corner; 
        z-index: 1;   
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 0 6px 6px;
        border-bottom-color: $body-bg-white;   
        z-index: 2;            
      }      
    }
  }
  &.placement-top {
    .rs-tooltip-arrow {
      width: 12px;
      height: 6px;
      border: 0 none;
      background: none;      
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 1px;
        left: 0;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 6px 6px 0;
        border-top-color: $rs-tooltip-corner; 
        z-index: 1;   
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 6px 6px 0;
        border-top-color: $body-bg-white;   
        z-index: 2;            
      }      
    }
  }
  &.placement-bottom-end {
    .rs-tooltip-arrow {
      width: 12px;
      height: 6px;
      border: 0 none;
      background: none;
      left: 7px; 
      margin: 0; 
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: -1px;
        left: 0;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 0 6px 6px;
        border-bottom-color: $rs-tooltip-corner; 
        z-index: 1;   
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 0 6px 6px;
        border-bottom-color: $body-bg-white;   
        z-index: 2;            
      }
    }
  }
  &.placement-top-end {
    .rs-tooltip-arrow {
      width: 12px;
      height: 6px;
      border: 0 none;
      background: none;
      left: 7px;
      margin: 0;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 1px;
        left: 0;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 6px 6px 0;
        border-top-color: $rs-tooltip-corner; 
        z-index: 1;   
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 6px 6px 0;
        border-top-color: $body-bg-white;   
        z-index: 2;            
      }
    }
  }
  &.placement-bottom-start {
    .rs-tooltip-arrow {
      width: 12px;
      height: 6px;
      border: 0 none;
      background: none;
      right: 7px; 
      margin: 0;   
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: -1px;
        left: 0;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 0 6px 6px;
        border-bottom-color: $rs-tooltip-corner; 
        z-index: 1;   
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 0 6px 6px;
        border-bottom-color: $body-bg-white;   
        z-index: 2;            
      }
    }
  }
  &.placement-top-start {
    .rs-tooltip-arrow {
      width: 12px;
      height: 6px;
      border: 0 none;
      background: none;
      right: 7px;
      margin: 0;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 1px;
        left: 0;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 6px 6px 0;
        border-top-color: $rs-tooltip-corner; 
        z-index: 1;   
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 6px 6px 0;
        border-top-color: $body-bg-white;   
        z-index: 2;            
      }
    }
  }  
  &.info_tooltip_checkout {
    .rs-tooltip-inner {
      max-width: 330px;
    }
  }
}

@media (max-width: 575px) {
  .rs-tooltip {
    .rs-tooltip-inner {
      max-width: 330px;
    }
  }  
}

.rs-loader-wrapper {
  z-index: 2000;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;  
  .rs-loader-backdrop {
    background: $body-bg-white;
    opacity: 0.7;
  }
  .rs-loader {
    width: auto;
    height: auto;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background: $rs-loader-bg;
    border-radius: 3px;
    padding: 16px 20px 18px;    
    > span {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
      &.rs-loader-spin {
        display: block;
        width: 18px;
        height: 18px;
        margin: 0;
        padding: 0;  
        &::before {
          width: 18px;
          height: 18px;
          border: 2.5px solid $rs-loader-spin-bg;
        }     
        &::after {
          width: 18px;
          height: 18px;
          border-width: 2.5px;
          border-style: solid;
          border-color: $rs-text-color transparent transparent;
        }            
      }
      &.rs-loader-content {
        display: block;
        margin: 0;
        padding: 0 7px 0 0;
        width: auto;
        white-space: nowrap;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $rs-text-color;
      }
    }    
  }
  &.rs-loader-inverse {
    .rs-loader-backdrop {
      background: $backdrop-bg-color;
    }
    .rs-loader {
      background: $rs-loader-bg-inverse;
      > span {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        &.rs-loader-spin {
          &::before {
            border-color: $rs-loader-spin-bg-inverse;
          }     
          &::after {
            border-color: $rs-text-color-inverse transparent transparent;
          }            
        }
        &.rs-loader-content {
          color: $rs-text-color-inverse;
        }
      }    
    }
  }
}

.rs-btn.rs-btn-ghost{
  &[disabled],
  &.rs-btn-disabled {
    border-color: $bg-disabled !important;
    color: $bg-disabled !important;
    opacity: 1;
    cursor: not-allowed !important;
  }
}

.rs-btn.rs-btn-link,
.rs-btn.rs-btn-ghost,
.rs-btn.rs-btn-primary {
  .rs-loader-wrapper {
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 18px;
    .rs-loader {
      display: block;
      width: 18px;
      height: 18px;
      background: none;
      border-radius: 0;
      padding: 0;      
    }
  }
  .rs-loader-wrapper + span {
    display: inline-block !important;
    vertical-align: top;
    width: auto !important;
    max-width: calc(100% - 25px);
  }
}

.input_group_flag {
  border-radius: 2px !important;
  border-color: $textarea-border-color !important;
  .rs-form-control-wrapper {
    .rs-input {
      border: 0 none !important;
    }
  }
  .rs-input-group-btn {
    background: none !important;
    border-radius: 0 !important;
    pointer-events: none;
    border-left: 1px solid $textarea-border-color !important;
    cursor: default;
    .rs-ripple-pond {
      display: none !important;
    }
  }
  &:focus,
  &:hover,
  &.rs-input-group-focus {
    border-color: $textarea-border-color-active !important;
    .rs-input-group-btn {
      border-left-color: $textarea-border-color-active !important;
      cursor: default;
    }    
  } 
}

.rs-dropdown.account_menu {
  display: block;
  width: 100%;
  .rs-dropdown-toggle {
    display: block;
    width: 100%;
    .rs-ripple-pond {
      display: none !important;
    }
  }
  .rs-dropdown-menu {
    margin: 17px 0 0;
    padding: 0;
    background: $body-bg-white;
    box-shadow: 0px 2px 8px $rs-tooltip-shadow;
    border-radius: 4px;
    max-width: 260px; 
    width: 260px;   
    list-style: none; 
    a.rs-dropdown-item-content {
      padding: 15px;
      margin: 0;
      display: block;
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: $body-text-third-color;
      .flex {
        display: block;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        margin: 0 -5px;
        min-height: 20px;
        width: auto;
        > span {
          display: block;
          -webkit-order: 0;
          -ms-flex-order: 0;
          order: 0;
          -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
          flex: 0 1 auto;
          -webkit-align-self: auto;
          -ms-flex-item-align: auto;
          align-self: auto; 
          padding: 0 5px; 
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;    
          width: calc(100% - 30px);  
          max-width: calc(100% - 30px);   
          > span {
            display: block;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 0;
            padding: 0;
            &.desktop{
              display: block;
            }
            &.mobile{
              display: none;
            }
          }
          &.icon{
            width: 30px;
            max-width: 30px;
            height: 20px;
            position: relative;
            .rs-icon {
              width: 20px;
              height: 20px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              &::before {
                content: none;
                display: none;
              }
              &.rs-icon-task {
                background: transparent url('../svg/header/orders-ico.svg') no-repeat scroll 0 0;
              }
              &.rs-icon-map-marker {
                background: transparent url('../svg/header/address-ico.svg') no-repeat scroll 0 0;
              }
              &.rs-icon-cog {
                background: transparent url('../svg/header/settings-ico.svg') no-repeat scroll 0 0;
              }
              &.rs-icon-sign-out {
                background: transparent url('../svg/header/log-out-ico.svg') no-repeat scroll 0 0;
              }
            }
          }
        }        
      }  
      &:hover {
        background: $account_menu_hover;
      }          
    }       
    & > li {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      border-bottom: 1px solid $account_menu_border;
      &:first-child {
        a.rs-dropdown-item-content {
          -webkit-border-top-left-radius: 4px;
          -webkit-border-top-right-radius: 4px;
          -moz-border-radius-topleft: 4px;
          -moz-border-radius-topright: 4px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;          
        }
      }
      &:last-child {
        border-bottom: 0;
        a.rs-dropdown-item-content {
          -webkit-border-bottom-right-radius: 4px;
          -webkit-border-bottom-left-radius: 4px;
          -moz-border-radius-bottomright: 4px;
          -moz-border-radius-bottomleft: 4px;
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;          
        }
      }      
    }
  }
}

@media (max-width: 991px) {
  .site-header {
    .user-header:not(.supplier) {
      .rs-dropdown.account_menu {
        .rs-dropdown-menu {
          right: auto;
          left: 0;
        }
      }
    }
  }
}

.offer_confirmation_info {
  min-height: 100%;
  padding: 48px 0;
  background: $main-bg-light-grey;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  .offer_confirmation_info_box {
    max-width: 510px;
    margin: 0 auto;
  }  
  .banner {
    display: block;
    width: 100%;
    max-width: 220px;
    margin: 0 auto 35px;
    &:last-child {
      margin: 0 auto;
    }
    > span {
      display: block;
      width: 100%;
      height: 0;
      margin: 0;
      padding: 0;
    }
    &.successfully_placed{
      max-width:211px;
      > span {
        padding: 0 0 72.98578199052133%;
        background: transparent url('../svg/order/successfully_placed.svg') no-repeat scroll 50% 50%;
        background-size: contain;
      }
    }  
    &.successfully_paid{
      max-width:205px;
      > span {
        padding: 0 0 80.48780487804878%;
        background: transparent url('../svg/order/successfully_paid.svg') no-repeat scroll 50% 50%;
        background-size: contain;
      }
    }   
    &.payment_failed{
      max-width:220px;
      > span {
        padding: 0 0 81.81818181818182%;
        background: transparent url('../svg/order/payment_failed.svg') no-repeat scroll 50% 50%;
        background-size: contain;
      }
    }   
    &.well_done{
      max-width:205px;
      > span {
        padding: 0 0 90.73170731707317%;
        background: transparent url('../svg/order/well_done.svg') no-repeat scroll 50% 50%;
        background-size: contain;
      }
    }       
  }
  p {
    display: block;
    width: 100%;
    margin: 10px 0;
    padding: 0;
    overflow: hidden;
    text-align: center;
    font-size: 16px;
    line-height: 20px; 
    color: $panel-p-color;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }    
    &.header {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      color: $main-header-color;
    }   
    a {
      color: $H500;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
        color: $H700;
      }
    }  
  }  
  .button {
    max-width: 100%;
    margin: 4px 0 0;
    &:first-child {
      margin-top: 0;
    }
    button {
      display: block;
      width: 100%;
      margin: 15px 0 0;
      max-width: 200px;      
      &.rs-btn.rs-btn-ghost {
        display: block;
        padding: 10px 6px;
        border-radius: 2px;
        border: 1px solid $H500;
        color: $H500;
        font-size: 15px;        
        line-height: 20px;
        font-weight: 500;
        cursor: pointer;
        background: $body-bg-white;
        > span {
          display: block;
          width: 100%;
          margin: 0;
          padding: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        &:hover {
          border: 1px solid $H700;
          color: $H700;
        }
      }         
    }
    > button {
      max-width: 130px;
      margin: 15px auto 0;
      display: block;
    }    
    .flex {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      width: auto;
      position: relative;
      margin: 0 -10px;
      > div {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        padding: 0 10px;
        min-width: 220px;
        width: auto;
        max-width: 50%;
      }      
    }
  }  
}

.offer_confirmation {
  .offers_list {
    padding: 0;
    background: none;
    display: block;
    width: 100%;
    .price_box {
      margin: 0;
      overflow: hidden;
      ul {
        margin: 0 -10px;
        padding: 20px 0;
        border-bottom: 1px solid $panel-border-color;
        &:first-child {
          padding-top: 0;
        }
      }
      ul.discount_price {
        align-items: center;
        p{
          overflow: visible;
          .flex {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            position: relative;
            width: auto;
            > span {
              display: block;
              order: 0;
              flex: 0 1 auto;
              align-self: center;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .percentage {
            border: 1px solid $H500;
            border-radius: 3px;
            color: $H500;
            font-size: 14px;
            margin-right: 8px;
            padding: 1px 5px;
            white-space: nowrap;
          }
        }
      }
      ul.total {
        p{
          font-size: 16px;
        }
      }
      p {
        .ltr {
          font-weight: 500;
        }
      }
      &:not(:last-child) {
        ul:last-child {
          border-bottom: 0 none;
        }        
      }
    }
    :not(.new_tab_option) {
      .price_box {
        ul {
          margin: 0 -10px;
          padding: 20px 0 0 ;
          border: 0 none;
          &:first-child {
            padding-top: 0;
            border-top: 0 none;
          }
        }        
      }
    }
    .price_box + .warning_box {
      margin: 0;
    }
  }
  .panel_edit {
    .grey_info_box {
      padding: 7px 14px;
      background: #ECECEC;
      p {
        color: $main-header-color;
        margin: 0;
        font-size: 14px;
        line-height: 17px;    
      }
    }    
    .project_ico_box {
      padding: 0 30px 0 0;
      position: relative;
      min-height: 20px;
      &::before {
        content: "";
        display: block;
        width: 17px;
        height: 20px;
        margin: -10px 0 0;
        padding: 0;
        background: transparent url('../svg/project-ico.svg') no-repeat scroll 50% 50%;
        background-size: contain;
        position: absolute;
        top: 50%;
        right: 0;
      }
      p {
        margin: 0 0 6px;
        font-size: 16px;
        line-height: 19px; 
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .payment{
    .grey_box{
      background: $main-bg-light-grey;
      padding: 7px 14px;
      p{
        color: $main-header-color;
        font-size: 14px;
        span{
          font-weight: 500;
          white-space: nowrap;
        }
      }
    }
    .payment_info{
      color: $main-header-color;
    }
    .warning_box{
      p{
        max-width: 540px;
      }
    }
  }
}

.order_list_item {
  background: $main-bg-light-grey;
  .offers_list {
    padding: 0;
    background: none;
    display: block;
    width: 100%;
    .rs-panel {
      margin: 0 0 20px;
      .rs-panel-body {
        min-height: auto;
      }
      &:last-child {
        margin: 0;
      }
      &.documents-box {
        p {
          display: block;
          width: 100%;
          margin: 0;
          padding: 0;
          overflow: hidden;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          color: $panel-p-color;
          &.header {
            font-size: 18px;
            line-height: 21px;
            font-weight: 500;
            margin: 0 0 19px;
            color: $main-header-color; 
          }         
        }
        ul.list_links,
        ul.list_links > li {
          display: block;
          width: 100%;
          margin: 0;
          padding: 0;
          list-style: none;
        }
        ul.list_links > li {
          padding: 20px 15px;
          background: $link-list-bg;
          position: relative;
          &::after {
            content: "";
            display: block;
            width: calc(100% - 30px);
            height: 1px;
            background: $link-list-border;
            position: absolute;
            bottom: 0;
            left: 15px;
          }
          &:last-child {
            &::after {
              content: none;
              display: none;
            }
          }
          a {
            display: block;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: $main-header-color;    
            margin: 0;  
            padding: 0 30px;   
            text-decoration: none;   
            cursor: pointer;
            position: relative;
            > span {
              display: block;
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            } 
            &::before {
              content: "";
              display: block;
              width: 20px;
              height: 20px;
              margin: -10px 0 0;
              position: absolute;
              top: 50%;
              right: 0;
              background: transparent url('../svg/document-ico.svg') no-repeat scroll 50% 50%;
              background-size: contain;
            }
            &::after {
              content: "";
              display: block;
              width: 20px;
              height: 20px;
              margin: -10px 0 0;
              position: absolute;
              top: 50%;
              left: 0;
              background: transparent url('../svg/download-ico.svg') no-repeat scroll 50% 50%;
              background-size: contain;
            } 
            &:hover {
              opacity: 0.75;
            }           
          }
        }
      }      
    }
    .price_box {
      p .ltr {
        font-weight: inherit;
      }
      ul:first-child {
        border-top: 1px solid $panel-border-color;
        padding-top: 20px;
      }
      .total {
        border: 0 none;
        padding: 20px 0 0;
        p {
          font-size: 18px;
          line-height: 21px;
        }
      }      
    }
    .details_box {
      border-top: 1px solid $panel-border-color;
      width: auto;
      position: relative;
      margin: 0 -24px;
      padding: 24px 24px 20px;
      p {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
        overflow: hidden;
        font-size: 16px;
        line-height: 19px;
        color: $main-header-color;  
        font-weight: 400;      
        &.header {
          font-size: 18px;
          line-height: 21px;        
          font-weight: 500;  
          margin: 0 0 22px;
        }
        &.sub-header {
          font-weight: 500;  
          margin: 0 0 6px;
        }
        &.relative_for_badge{
          line-height: 28px;
          padding-left: 70px;
          overflow: visible;
          width: auto;
          display: inline-block;
        }        
      }
      .ico-item {
        margin: 17px 0;
        padding: 0 34px 17px 0;
        position: relative;
        min-height: 37px;
        border-bottom: 1px solid $panel-border-color;
        &:last-child {
          border: 0 none;
          padding: 0 34px 0 0;
          margin: 0;
          min-height: 20px;
        }
        &::before {
          content: "";
          display: block;
          width: 24px;
          height: 20px;
          position: absolute;
          top: 0;
          right: 0;
          margin: 0;
          padding: 0;
        }
        &.clock {
          &::before {
            background: transparent url('../svg/clock-ico.svg') no-repeat scroll 50% 50%;
            background-size: contain;
          }
        }
        &.project {
          &::before {
            background: transparent url('../svg/project-ico.svg') no-repeat scroll 50% 50%;
            background-size: contain;
          }
        }
        &.contacts {
          &::before {
            background: transparent url('../svg/contacts-ico.svg') no-repeat scroll 50% 50%;
            background-size: contain;
          }
        }
        &.services {
          &::before {
            background: transparent url('../svg/services-ico.svg') no-repeat scroll 50% 50%;
            background-size: contain;
          }
        }
        &.comment {
          &::before {
            background: transparent url('../svg/comment-ico.svg') no-repeat scroll 50% 50%;
            background-size: contain;
          }
        }
        &.suppliet {
          &::before {
            background: transparent url('../svg/suppliet-contact-ico.svg') no-repeat scroll 50% 50%;
            background-size: contain;
          }
        }
        &.delivery {
          &::before {
            background: transparent url('../svg/delivery-contact-ico.svg') no-repeat scroll 50% 50%;
            background-size: contain;
          }
        }
        &.deliveryman {
          &::before {
            background: transparent url('../svg/deliveryman-ico.svg') no-repeat scroll 50% 50%;
            background-size: contain;
          }
        }
        &.address {
          &::before {
            background: transparent url('../svg/time.svg') no-repeat scroll 50% 50%;
            background-size: contain;
          }
        }
        &.vehicle {
          &::before {
            background: transparent url('../svg/vehicle.svg') no-repeat scroll 50% 50%;
            background-size: contain;
          }
        }
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
    .payment_box {
      .flex-container {
        width: auto;
        position: relative;
        margin: 0 -5px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        > div {
          padding: 0 5px;
          &:nth-child(1) {
            -webkit-order: 0;
            -ms-flex-order: 0;
            order: 0;
            -webkit-flex: 1 1 auto;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            -webkit-align-self: auto;
            -ms-flex-item-align: auto;
            align-self: auto;
            width: auto;
          }
          &:nth-child(2) {
            -webkit-order: 0;
            -ms-flex-order: 0;
            order: 0;
            -webkit-flex: 0 1 auto;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            -webkit-align-self: auto;
            -ms-flex-item-align: auto;
            align-self: auto;      
            width: auto;
          }          
        }        
      }
      p {
        display: block;
        width: 100%;
        margin: 6px 0 0;
        padding: 0;
        overflow: hidden;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;  
        color: $panel-p-color;      
        &.header {
          margin: 0;
          font-weight: 500;
          color: $main-header-color;
        }
      }
      .paid,
      .failed {
        display: block;
        margin: 0;
        padding: 2px 12px;
        border-radius: 3px;
        white-space: nowrap;            
        text-overflow: ellipsis;
        overflow: hidden;
        width: auto;
        min-width: 70px;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.006em;   
        color: $main-header-color;
        &.paid {
          background: $in_progress-order-bg;
          &.paid_counter{
            direction: ltr;
          }
        }   
        &.failed {
          background: $failed-order-bg;
        }  
      }
    }
    .offer-box {
      margin: 15px 0 0;
    }
    .without_collapse {
      .rs-panel-heading {
        &::before {
          content: none;
          display: none;
        }        
        padding-left: 17px !important;
      }
    }
  }
  .header-flex {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: auto;
    position: relative;
    margin: -3px -3px 5px;
    &:last-child {
      margin-bottom: 0;
    }
    > div {
      padding: 3px 3px 0;
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
      width: auto;
      max-width: 100%;            
    }
    p {
      display: block;
      width: 100%;
      margin: 0 0 5px;
      padding: 0;
      overflow: hidden;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;          
      color: $main-header-color;     
      &.title {
        font-weight: 500;
      }     
      &.date {
        color: $panel-p-color;
      }
      &.order {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
      }
      .orders,
      .new,
      .in_progress,
      .completed,
      .canceled,
      .need-review,
      .awaiting-delivery, 
      .waiting-payment, 
      .default,
      .failed,
      .declined,
      .delivered,
      .in-review{
        display: block;
        margin: 0;
        padding: 2px 12px;
        border-radius: 3px;
        white-space: nowrap;            
        text-overflow: ellipsis;
        overflow: hidden;
        width: auto;
        max-width: 100%;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.006em;     
        &.new {
          background: $new-order-bg;
        }   
        &.in_progress {
          background: $in_progress-order-bg;
        }  
        &.completed {
          background: $completed-order-bg;
        }
        &.delivered {
          background: $delivered-order-bg;
        }
        &.failed {
          background: $failed-order-bg;
        }  
        &.orders {
          background: none;
          border: 1px solid $main-header-color;
          // padding: 0 10px;
          padding: 2px 12px;
          line-height: 20px;
        } 
        &.canceled {
          background: $canceled-order-bg;
        }
        &.need-review {
          background: $need-review-order-bg;
        }
        &.awaiting-delivery {
          background: $awaiting-delivery-order-bg;
        } 
        &.waiting-payment {
          background: $waiting-payment-order-bg;
        }
        &.default {
          background: transparent;
        } 
        &.declined {
          background: $declined-order-bg;
        }
        &.in-review{
          background: $in-review-order-bg;
        }
      }
      &:last-child {
        margin: 0;
      }
    }  
  }  
  .header-flex ~ .warning_box {
    margin: 23px 0 8px;
  }  
}

@media (min-width: 1200px) {
  .order_list_item {
    .offers_list {
      .offer-box {
        .products-list {
          .products-list-flex {
            margin: 0 -10px;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-align-content: center;
            -ms-flex-line-pack: center;
            align-content: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;            
            > div {
              padding: 0 10px;
              &:nth-child(1) {
                width: 100px;
                max-width: 100px;
              }
              &:nth-child(2) {
                width: calc((100% - 220px)*0.55);
                max-width: calc((100% - 220px)*0.55);
                padding-left: 70px;
                &.initial_padding {
                  padding: 0 10px;
                }
              }
              &:nth-child(3) {
                width: 120px;
                max-width: 120px;
                padding: 0;
              }
              &:nth-child(4) {
                width: calc((100% - 220px)*0.45);
                max-width: calc((100% - 220px)*0.45);
                padding-right: 70px;
              }                                          
            }
          }
        }
      }
    }
  }
}

.breadcrumb-list + .checkout-flex-col {
  position: relative;
  margin-top: -20px;
}

.connect_buy_now {
  min-height: 100%;
  padding: 48px 0;
  background: $main-bg-light-grey;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  .connect_buy_now_box {
    max-width: 470px;
    margin: 0 auto;
  }
  p {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $body-text-fourth-color;  
    a {
      color: $H700;
      line-height: inherit;
      font-weight: inherit;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }      
  }
  .panel {
    max-width: 470px;
    margin: 0 auto 15px;
    background: $body-bg-white;
    padding: 39px 30px 28px;
    border-radius: 4px;
    border: 1px solid $panel-border-color;
    overflow: hidden;
    position: relative;
    border-top: 3px solid $H500;
    &:last-child {
      margin-bottom: 0;
    }
    p {
      text-align: unset;
      &.header {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: $body-text-second-color;
        margin: 0 0 19px;
        &:last-child {
          margin-bottom: 0;
        }      
      }      
    }
  }  
  ul.number_list,
  ul.number_list > li 
  ul.list_links,
  ul.list_links > li {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  ul.number_list {
    counter-reset: list;
    font-size: 16px;
    line-height: 24px;
  }
  ul.number_list > li {
    position: relative;
    min-height: 72px;
    padding: 0 36px 14px 0; 
    &::before {
      counter-increment: list;
      content: counter(list);
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0;
      right: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $body-text-white-color;  
      background: $header-bg-2;
      border-radius: 2px;          
    } 
    &::after {
      content: "";
      display: block;
      width: 2px;
      height: auto;
      position: absolute;
      top: 38px;
      right: 11px;
      bottom: 14px;
      background: $link-list-border;
    }    
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: $main-header-color;
      margin: 2px 0 16px;
    }
    &:last-child {
      padding: 0 36px 0 0; 
      min-height: 24px;
      &:last-child {
        margin: 2px 0;
      }         
    }     
  }
  ul.list_links > li {
    padding: 20px 15px;
    background: $link-list-bg;
    position: relative;
    &::after {
      content: "";
      display: block;
      width: calc(100% - 30px);
      height: 1px;
      background: $link-list-border;
      position: absolute;
      bottom: 0;
      left: 15px;
    }
    &:last-child {
      &::after {
        content: none;
        display: none;
      }
    }
    a {
      display: block;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: $main-header-color;    
      margin: 0;  
      padding: 0 30px;   
      text-decoration: none;   
      cursor: pointer;
      position: relative;
      > span {
        display: block;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      } 
      &::before {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        margin: -10px 0 0;
        position: absolute;
        top: 50%;
        right: 0;
        background: transparent url('../svg/document-ico.svg') no-repeat scroll 50% 50%;
        background-size: contain;
      }
      &::after {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        margin: -10px 0 0;
        position: absolute;
        top: 50%;
        left: 0;
        background: transparent url('../svg/download-ico.svg') no-repeat scroll 50% 50%;
        background-size: contain;
      } 
      &:hover {
        opacity: 0.75;
      }           
    }
  }  
  .banner {
    display: block;
    width: 100%;
    max-width: 82px;
    margin: 0 auto 22px;
    &:last-child {
      margin: 0 auto;
    }
    > span {
      display: block;
      width: 100%;
      height: 0;
      margin: 0;
      padding: 0 0 65.85365853658537%;
      background: transparent url('../svg/hands.svg') no-repeat scroll 50% 50%;
      background-size: contain;
    }
  }
}

.product_number {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $body-text-second-color;
  display: inline-block;
  vertical-align: top;
  width: auto;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  padding: 3px 26px 3px 8px;
  height: 26px;
  border-radius: 13px;
  border: 1px solid $calendar-border-color;
  &::after {
    display: block;
    content: "";
    width: 15px;
    height: 10px;
    position: absolute;
    top: 50%; 
    right: 7px;
    margin: -5px 0 0;
    background: transparent url('../svg/product_number.svg') no-repeat scroll 50% 50%;
    background-size: contain;
  }
}

.supplier_orders {
  .order-component.offers_list {
    .submit_button,
    .grey_box {
      margin: 22px 0;
      .rs-btn {
        margin: 0;
      }
      p.price {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }
    }
    .submit_button + .submit_button {
      margin-top: -7px;
      .rs-btn.rs-btn-ghost {
        display: block;
        padding: 10px 6px;
        border-radius: 2px;
        border: 1px solid $H500;
        color: $H500;
        font-size: 15px;        
        line-height: 20px;
        font-weight: 500;
        cursor: pointer;
        background: $body-bg-white;
        > span {
          display: block;
          width: 100%;
          margin: 0;
          padding: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        &:hover {
          border: 1px solid $H700;
          color: $H700;
        }
      }  
    }
  }
  .panel_edit {
    .header-flex ~ p.date {
      max-width: 55%;
    }
  }  
  .offers_list .offer-box .products-list {
    .flex_box_p {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      width: auto;
      margin: 0 -7.5px;
      > p {
        width: auto;
        max-width: 100%;
        padding: 0 7.5px;
        margin: 0 0 5px;
      }
    }
    ul > li {
      padding: 30px 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }      
    }
    p.quantity {
      padding: 8px 5px;
    }
  }
}

@media (max-width: 1199px) {
  .supplier_orders {
    .panel_edit {
      .header-flex ~ p.date {
        max-width: 100%;
      }
    }  
  }  
}

@media (min-width: 1200px) {
  .supplier_orders {
    .panel_edit .offers_list .offer-box .products-list {
      .products-list-flex {
        > div:nth-child(1) {
          width: 100px;
          max-width: 100%;
          padding: 0 10px;
        }         
        > div:nth-child(2) {
          width: calc((100% - 380px)*0.55);
          max-width: calc((100% - 380px)*0.55);
          padding: 0 10px;
        }  
        > div:nth-child(3) {
          padding: 0 90px;
          width: 280px;
          max-width: 280px;
        }  
        > div:nth-child(4) {
          padding: 0 10px;
          width: calc((100% - 380px)*0.45);
          max-width: calc((100% - 380px)*0.45);          
        }                    
      }
      p.quantity {
        padding: 11px 15px;
        width: 100%;
      }      
    }
  }  
}

.supplier_orders {
  .delivery_method {
    .new_tab_option {
      margin: 0;
      label {
        &:last-child {
          margin: 0 0 9px;
        }
      }       
    }
    div.rs-flex-box-grid {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      margin: 22px -10px;
      width: auto;
      position: relative;        
      &:first-child {
        margin-top: 0;
      }      
      &:last-child {
        margin-bottom: 0;
      }  
      > div {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        padding: 0 10px;
      } 
      label {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: auto;
        max-width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        padding: 0;
        margin: 0 0 9px;
        &:last-child {
          margin: 0;
        }
        &.required {
          padding-right: 8px;
          &::before{
            content: "\002A";
            position: absolute;
            color: $H500;
            top: 0;
            right: 0;
            font-size: 16px;
            line-height: 19px;
            display: block;
            width: 8px;
            height: 19px;
            overflow: hidden;
          }
        }      
      }                    
    }  
    .grey_info_box {
      padding: 10px 17px;
      margin: 25px 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }      
      p {
        font-size: 16px;
        line-height: 19px;
        color: $body-text-second-color;
      }
    }
    .send_offer,
    .rs-radio,
    .warning_box {
      margin: 22px 0;
      .rs-btn {
        min-width: 140px;
      }
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }    
    }
    .ton_box {
      ul,
      ul > li {
        display: block;
        width: auto;
        margin: 0;
        padding: 0;
        width: auto;
        list-style: none;
      }
      ul {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        position: relative;
        margin: 0 -6px;
        > li {
          -webkit-order: 0;
          -ms-flex-order: 0;
          order: 0;
          -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
          flex: 0 1 auto;
          -webkit-align-self: auto;
          -ms-flex-item-align: auto;
          align-self: auto;   
          padding: 0 6px;   
          width: 25%;
          max-width: 25%;
          input {
            display: none;
          }          
          label {
            cursor: pointer;
            display: block;
            width: 100%;
            height: 100%;
            background: $body-text-white-color;
            border: 1px solid $textarea-border-color;
            border-radius: 2px;
            padding: 17px;
            position: relative;
            > span {
              font-size: 16px;
              line-height: 19px;
              text-align: center;
              color: $body-text-second-color;
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &::before {
              content:"";
              display: block;
              width: 100%;
              height: 25px;
              margin: 0 auto 6px;
              padding: 0;
            }
            &.delivery-truck-4 {
              &::before {
                width: 25px;
                background: transparent url('../svg/trucks/delivery-truck-4.svg') no-repeat scroll 50% 50%;
                background-size: contain;
              }              
            }
            &.delivery-truck-8 {
              &::before {
                width: 33px;
                background: transparent url('../svg/trucks/delivery-truck-8.svg') no-repeat scroll 50% 50%;
                background-size: contain;                
              }              
            }  
            &.delivery-truck-12 {
              &::before {
                width: 38px;
                background: transparent url('../svg/trucks/delivery-truck-12.svg') no-repeat scroll 50% 50%;
                background-size: contain;                 
              }              
            } 
            &.delivery-truck-15 {
              &::before {
                width: 55px;
                background: transparent url('../svg/trucks/delivery-truck-15.svg') no-repeat scroll 50% 50%;
                background-size: contain;                 
              }              
            }                                    
          }  
          input:checked + label {
            border: 1px solid $H700;
          }  
        }        
      }
    }
  }
  .offer_summary {
    .order_list_item {
      background: none;
      .offers_list {
        .details_box {
          padding-top: 0;
          border: 0 none;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .supplier_orders {
    .delivery_method {
      .new_tab_option {
        margin: 0;
      }
      div.rs-flex-box-grid {
        > div + div {
          margin-top: 15px;
        }              
      }    

    }
  }  
}

@media (max-width: 575px) {
  .supplier_orders {
    .delivery_method {
      .ton_box {
        ul {
          > li {
            width: 50%;
            max-width: 50%;
          } 
          > li:nth-child(2) ~ li {
            margin-top: 12px;
          }
        }
      }
    }
  }  
}

.make_offer {
  .in_stock {
    width: 100%;
    padding: 18px 16px;
    margin: 27px 0 0;
    &:first-child {
      margin-top: 0;
    }
    background: $main-bg-light-grey;
    .rs-checkbox {
      .rs-checkbox-checker label .text {
        .light {
          color: $make_offer-light-color;
        }
      }      
    }
  }
  .offers_list .offer-box {
    margin: 0;
    .products-list.supplier_orders_list {
      .flex_box_p{
        margin-bottom: 0;
        > p {
          margin-bottom: 5px;
        }
      }        
      ul,
      ul > li {
        display: block;
        width: auto;
        margin: 0;
        padding: 0;
        list-style: none;
      }
      ul > li{
        padding: 30px 0;
        border-bottom: 1px solid $make_offer-delimiter-color;
      }
      .flex-checkbox {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        > div {
          &:nth-child(1) {
            -webkit-order: 0;
            -ms-flex-order: 0;
            order: 0;
            -webkit-flex: 0 1 auto;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            -webkit-align-self: auto;
            -ms-flex-item-align: auto;
            align-self: auto;
            width: 46px;
            max-width: 46px;
            padding: 0 16px 0 12px;
            .rs-checkbox {
              position: relative;
              z-index: 1;
            }
          }
          &:nth-child(2) {
            -webkit-order: 0;
            -ms-flex-order: 0;
            order: 0;
            -webkit-flex: 1 1 auto;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            -webkit-align-self: auto;
            -ms-flex-item-align: auto;
            align-self: auto;     
            width: calc(100% - 221px);
            max-width: calc(100% - 221px);
            padding: 0 0 0 25px;
          } 
          &:nth-child(3) {
            -webkit-order: 0;
            -ms-flex-order: 0;
            order: 0;
            -webkit-flex: 0 1 auto;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            -webkit-align-self: auto;
            -ms-flex-item-align: auto;
            align-self: auto;
            width: 175px;
            max-width: 175px;
            padding: 0 25px 0 0;    
            .rs-btn.rs-btn-ghost {
              display: block;
              width: 100%;
              margin: 0;
              padding: 10px 7px;
              border-radius: 0;
              border: 1px solid $H500;
              color: $H500;
              font-size: 15px;        
              line-height: 20px;
              font-weight: 500;
              cursor: pointer;
              > span {
                margin: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                &::after {
                  background: transparent url('../svg/icon-plus.svg') no-repeat scroll 0 0;
                }
              }
              &:hover {
                border: 1px solid $H700;
                color: $H700;
                > span {
                  &::after {
                    background: transparent url('../svg/icon-plus.svg') no-repeat scroll 0 -12px;
                  }
                }                
              }
              &.rs-btn-disabled {
                border: 1px solid $H500 !important;
                color: $H500 !important;              
                opacity: 0.6 !important;
                > span {
                  &::after {
                    background: transparent url('../svg/icon-plus.svg') no-repeat scroll 0 0 !important;
                  }
                }                
              }
            }
            .rs-btn {
              text-align: center;
              margin: 28px 0 0 !important;
              position: relative;
              z-index: 1;
              > span {
                padding: 0 25px 0 0;
                display: inline-block;
                position: relative;
                vertical-align: top;
                width: auto;
                max-width: 100%;
                &::after {
                  content: "";
                  display: block;
                  position: absolute;
                  top: 50%;
                  right: 0;
                  margin: -6px 0 0;
                  padding: 0;
                  width: 12px;
                  height: 12px;
                }
              }
              &.rs-btn-primary{
                > span {
                  &::after {
                    background: transparent url('../svg/ico-minus.svg') no-repeat scroll 0 0;
                  }
                }
              }
              .rs-ripple-pond {
                display: none !important;
              }
            }
            .not_ready_for_alternative {
              background: $bg-disabled;
              > span {
                margin: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                &::after {
                  margin: -7px 0 0;
                  padding: 0;
                  width: 14px;
                  height: 14px;                  
                  background: transparent url('../svg/info-ico-white.svg') no-repeat scroll 0 0 !important;
                }
              } 
              &.opacity {
                opacity: 0.6 !important;
              }             
            } 
            &.is_alternative {
              .rs-btn {
                margin: 0 !important;
              }              
            }
          }                
        }
      }
      .products-list-flex {
        margin: -7.5px -25px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start; 
        > div {
          padding: 0;
          &:nth-child(1){
            width: 124px;
            max-width: 124px;
            padding: 7.5px 25px 7.5px 0;
            .products-img {
              width: 99px;
              height: 99px;
            }
          }
          &:nth-child(2){
            width: calc(30% - 31px);
            max-width: calc(30% - 31px);
            padding: 7.5px 25px;
          }        
          &:nth-child(3){
            width: calc(25% - 31px);
            max-width: calc(25% - 31px);
            padding: 7.5px 25px;
          }   
          &:nth-child(4){
            width: calc(25% - 31px);
            max-width: calc(25% - 31px);   
            padding: 7.5px 25px;
          }                 
          &:nth-child(5){
            width: calc(20% - 31px);
            max-width: calc(20% - 31px); 
            padding: 7.5px 25px;
          }        
        } 
        p.description {
          font-size: 16px;
          line-height: 19px;
        }
      }
      .hr {
        margin-top: 30px;
        margin-bottom: 30px;
        border: 0;
        border-top: 1px solid $panel-border-color;
      }
      .input_wrapper {
        p {
          display: block;
          width: 100%;
          margin: 0 0 9px;
          overflow: hidden;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $body-text-second-color;
          // white-space: nowrap;  
          &.summa {
            width: 100%;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-align-content: center;
            -ms-flex-line-pack: center;
            align-content: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            min-height: 42px;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: $body-text-third-color;   
            margin: 0;   
            word-break: break-all;  
          }
          &.error {
            display: block;
            width: 100%;
            overflow: hidden;
            padding: 0 !important;
            margin: 7px 0 7.89px !important;
            color: $H500 !important;
            font-size: 14px !important;
            line-height: 16px !important;
            letter-spacing: -0.006em;  
            font-weight: 400 !important;
            &:last-child {
              margin-bottom: 0 !important;
            }
            &:empty {
              display: none;
            }
          }
        }
        .wrapper_number_input {
          .postfix {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            white-space: nowrap;
            margin: 0;
            padding: 0;
            display: block;
            overflow: hidden;
            letter-spacing: -0.006em;
            color: $make_offer-postfix-color;
          }
          .rs-input-number {
            .rs-input-number-btn-group-vertical {
              display: none !important;
            }
          }
          .rs-input-group {
            border-radius: 2px;
            background: $textarea-bg-color;
            border: 1px solid $textarea-border-color;
            width: 100%;
            height: 42px;
            overflow: hidden;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-align-content: stretch;
            -ms-flex-line-pack: stretch;
            align-content: stretch;
            -webkit-align-items: flex-start;
            -ms-flex-align: start;
            align-items: flex-start;        
            .rs-input-group-addon {
              background: none;
              padding: 10px 5px;
            }
            input {
              display: block;
              background: $header-search-bg;
              border-radius: 0;
              border: 0 none;
              padding: 10px 10px 10px 5px;
              font-family: $font;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;  
              color: $body-text-second-color;
              overflow: hidden;      
              text-overflow: ellipsis;
              margin: 0;
            }
            input::-webkit-input-placeholder {color: $textarea-placeholder-color;opacity: 1;}
            input::-moz-placeholder          {color: $textarea-placeholder-color;opacity: 1;}
            input:-moz-placeholder           {color: $textarea-placeholder-color;opacity: 1;}
            input:-ms-input-placeholder      {color: $textarea-placeholder-color;opacity: 1;}  
            &:focus,
            &:hover,
            &.rs-input-group-focus {
              border: 1px solid $textarea-border-color-active !important;
            }  
          }  
          &.has-error {
            .rs-input-group {
              border: 1px solid $H500 !important;
              &:focus,
              &:hover,
              &.rs-input-group-focus {
                border: 1px solid $H500 !important;
              } 
            }  
          }         
        }
      }
    }
  }
  .make_offer_total {
    margin: 30px 0 0;
    text-align: left;
    &:first-child {
      margin-top: 0;
    }
    > div {
      text-align: initial;
      max-width: 527px;
      width: 100%;
      display: inline-block;
      vertical-align: top;
      .grey_box {
        background: $main-bg-light-grey;
        padding: 24px 22px;
        border-radius: 2px;
        margin: 0 0 26px;
        &:last-child {
          margin-bottom: 0;
        }   
        .warning_box {
          background: $body-bg-white;
          margin: 24px 0;
          border-color: $make_offer-border-color;
          &:last-child {
            margin-bottom: 0;
          }
          &:first-child {
            margin-top: 0;
          }          
        }    
        ul,
        ul > li {
          display: block;
          margin: 0;
          padding: 0;
          list-style: none;
          width: auto;
        } 
        ul {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-align-content: center;
          -ms-flex-line-pack: center;
          align-content: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;          
          position: relative;
          margin: 20px -10px;
          &:last-child {
            margin-bottom: 0;
          }
          &:first-child {
            margin-top: 0;
          } 
          > li {
            -webkit-order: 0;
            -ms-flex-order: 0;
            order: 0;
            -webkit-flex: 0 1 auto;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            -webkit-align-self: auto;
            -ms-flex-item-align: auto;
            align-self: auto;            
            padding: 0 10px;
            width: auto;
            max-width: calc(50% - 54px);
          }  
          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: $main-header-color;
          }  
          &.total {
            p {
              font-weight: 500;
            }
          }      
        }
      }
      .rs-input-group {
        border-radius: 2px;
        background: $textarea-bg-color;
        border: 1px solid $textarea-border-color;
        width: 100%;
        height: 42px;
        overflow: hidden;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;        
        .rs-input-group-addon {
          background: $main-bg-light-grey;
          padding: 10px;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $body-text-second-color;  
          border-right: 1px solid $textarea-border-color;   
        }
        input {
          display: block;
          background: $header-search-bg;
          border-radius: 0;
          border: 0 none;
          padding: 10px;
          font-family: $font;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;  
          color: $body-text-second-color;
          overflow: hidden;      
          text-overflow: ellipsis;
          margin: 0;
        }
        input::-webkit-input-placeholder {color: $textarea-placeholder-color;opacity: 1;}
        input::-moz-placeholder          {color: $textarea-placeholder-color;opacity: 1;}
        input:-moz-placeholder           {color: $textarea-placeholder-color;opacity: 1;}
        input:-ms-input-placeholder      {color: $textarea-placeholder-color;opacity: 1;}  
        &:focus,
        &:hover,
        &.rs-input-group-focus {
          border: 1px solid $textarea-border-color-active !important;
          .rs-input-group-addon {
            border-right: 1px solid $textarea-border-color-active !important; 
          }          
        } 
        .rs-input-number-btn-group-vertical {
          display: none !important;
        } 
      }   
      .wrapper_input_number {
        display: inline-block;
        vertical-align: top;
        width: auto;
        max-width: 108px;
        .rs-input-group-addon {
          display: block;
          width: 40px;
          text-align: center;
        }
      }      
      .rs-btn {
        width: 100%;
      }
    }
  }
  .make_offer_timer_flex {
    position: relative;
    width: auto;
    margin: 0 -15px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;    
    > div {
      padding: 0 15px;
      &:nth-child(1) {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        width: calc(100% - 200px);
        max-width: calc(100% - 200px);         
      }
      &:nth-child(2) {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;  
        width: 200px;
        max-width: 200px;      
      }      
    }
  }
}

@media (max-width: 1199px) {
  .make_offer {
    .offers_list .offer-box {
      .products-list.supplier_orders_list {
        .flex-checkbox {
          > div {
            &:nth-child(3) {
              .rs-btn {
                margin: 0 !important;
              } 
            }                
          }
        }          
        .products-list-flex {
          > div {
            &:nth-child(1){
              width: 124px;
              max-width: 124px;
            }
            &:nth-child(2){
              width: calc(100% - 124px);
              max-width: calc(100% - 124px);
            }        
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5){
              width: 33.33333333333333%;
              max-width: 33.33333333333333%; 
            }        
          } 
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .make_offer {
    .offers_list .offer-box {
      .products-list.supplier_orders_list {
        .products-list-flex {
          > div {
            &:nth-child(1){
              width: 124px;
              max-width: 124px;
            }
            &:nth-child(2){
              width: calc(100% - 124px);
              max-width: calc(100% - 124px);
            }        
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5){
              width: 100%;
              max-width: 100%; 
              .input_wrapper {
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-direction: row;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-flex-wrap: wrap;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                -webkit-justify-content: flex-start;
                -ms-flex-pack: start;
                justify-content: flex-start;
                -webkit-align-content: center;
                -ms-flex-line-pack: center;
                align-content: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                position: relative;
                width: auto;
                margin: 0 -7.5px;
                > div {
                  -webkit-order: 0;
                  -ms-flex-order: 0;
                  order: 0;
                  -webkit-flex: 0 1 auto;
                  -ms-flex: 0 1 auto;
                  flex: 0 1 auto;
                  -webkit-align-self: auto;
                  -ms-flex-item-align: auto;
                  align-self: auto;
                  padding: 0 7.5px;
                  &:nth-child(1) {
                    width: 114px;
                    max-width: 114px;
                    p {
                      text-align: left;
                      margin: 0;
                    }
                  }
                  &:nth-child(2) {
                    width: calc(100% - 114px);
                    max-width: calc(100% - 114px);
                    padding-right: 17.5px;
                  }                  
                }
              }
            }        
          } 
        }
      }
    }
    .make_offer_timer_flex {
      > div {
        &:nth-child(1) {
          width: 100%;
          max-width: 100%;         
        }
        &:nth-child(2) {
          width: 100%;
          max-width: 100%;    
        }      
      }     
    }    
  }
}

@media (max-width: 767px) {
  .make_offer {
    .offers_list .offer-box {
      .products-list.supplier_orders_list {
        ul > li{
          padding-bottom: 15px;
          position: relative;
        }        
        .flex-checkbox {
          > div {
            &:nth-child(1) {
              .rs-checkbox {
                top: 50%;
                position: absolute;
                margin-top: -9px;
              }              
            }
            &:nth-child(2) {
              width: calc(100% - 46px);
              max-width: calc(100% - 46px);
              padding: 0;
            } 
            &:nth-child(3) {
              width: 100%;
              max-width: 100%;              
              padding: 15px 174px 15px 0; 
              position: relative;
              &.is_alternative {
                &::before{ 
                  content: "";
                  display: block;
                  width: 103px;
                  height: 1px;
                  background: $panel-border-color;
                  position: absolute;
                  top: 50%;
                  right: 46px;
                }
              }
            }  
            &:nth-child(4) {
              width: 100%;
              max-width: 100%;              
              padding: 0 46px 0 0; 
            }                           
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .make_offer {
    .offers_list .offer-box {
      .products-list.supplier_orders_list {
        ul > li {
          padding-left: 16px;
        }
        .flex-checkbox {
          > div {
            &:nth-child(3) {
              padding-right: 46px; 
              &.is_alternative {
                &::before{ 
                  content: none;
                  display: block;
                }
              }
            }  
          }
        }
        .products-list-flex {
          > div:nth-child(1) {
            width: 105px;
            max-width: 105px;
            .products-img {
              width: 80px;
              height: 80px;
            }
          }
          > div:nth-child(2) {
            width: calc(100% - 105px);
            max-width:  calc(100% - 105px);
            padding-right: 15px;
          }          
          > div:nth-child(3),
          > div:nth-child(4),
          > div:nth-child(5) {
            .input_wrapper {
              margin: 0;
              > div:nth-child(1),
              > div:nth-child(2) {
                width: 100%;
                max-width: 100%;
                min-height: auto;
                padding: 0;
                > p {
                  line-height: normal;
                  min-height: auto;
                  text-align: initial;
                }
              }
              > div:nth-child(1) {
                p {
                  margin-bottom: 9px;
                }
              }
            }  
          }
        }
      }
    }
    .offers_list,
    .in_stock {
      position: relative;
      margin-right: -25px;
      margin-left: -25px;
      width: auto;
    }
    .make_offer_total {
      > div {
        .grey_box {
          ul {
            > li {
              width: 100%;
              max-width: 100%;
            }
            > li + li {
              margin-top: 9px;
            }
          }
        }
        .wrapper_input_number {
          max-width: 100%;
        }
      }
    }
  }  
}

.search_page {
  // padding-top: 30px;
  h1 {
    margin: 0 0 30px;
    .red {
      color: $H500;
    }
    &:last-child {
      margin: 0;
    }
  }
  .search_category_list {
    background: $main-bg-light-grey;
    padding: 5px 20px 17px;
    margin: 0 0 35px;
    &:last-child {
      margin: 0;
    }    
    ul,
    ul > li {
      display: block;
      width: auto;
      margin: 0;
      padding: 0;
      list-style: none;
    }
    ul {
      margin: 0 -11px;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      > li {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        padding: 5px 11px 0;
      }      
    }
    p {
      width: auto; 
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $make_offer-light-color;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;          
      a {
        color: $main-header-color;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
          color: $main-header-color;
        }
      } 
      &.active {
        color: $H500;
        font-weight: 500;
        a {
          color: $main-header-color;
          text-decoration: none;
          &:hover {
            color: $main-header-color;
            text-decoration: none;
          }          
        }
      }  
      .bull {
        padding: 0 2px;
      }
    }
  }
  .product_not_found {
    &:last-child {
      padding-bottom: 0;
    }
    &:first-child {
      padding-top: 0;
    }    
  }  
}

.supplier_orders {
  .requests_proposal {
    width: auto;
    position: relative;
    margin: 0 -5px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    > div {
      padding: 0 5px;
      &:nth-child(1) {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        width: 350px;
        max-width: 350px;
        .full_height {
          height: 100%;
          background: $body-bg-white;
          padding: 26px 30px;
          border: 1px solid $panel-border-color;
        }
      }
      &:nth-child(2) {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;    
        width: calc(100% - 350px);
        max-width: calc(100% - 350px);  
        &.empty {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-align-content: center;
          -ms-flex-line-pack: center;
          align-content: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
        } 
        &.empty.accepted {
          .nav-list {
            width: 100%;
          }
          .nav-list + div {
            width: 100%;
            -webkit-order: 0;
            -ms-flex-order: 0;
            order: 0;
            -webkit-flex: 1 1 auto;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            -webkit-align-self: center;
            -ms-flex-item-align: center;
            align-self: center;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-align-content: center;
            -ms-flex-line-pack: center;
            align-content: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;            
          }
        }   
      }      
    }
    &.pagination {
      text-align: left;
      .wrapper_pagination {
        margin-top: 16px;
        display: inline-block;
        vertical-align: top;
      }
    }
    .nav-list {
      background: $body-bg-white;
      border: 1px solid $panel-border-color;
      margin: 0 0 10px;
      .rs-nav {
        ul {
          width: 100%;
          > li.rs-nav-item {
            width: auto;
            max-width: none;          
            a {
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              color: $main-header-color;
              padding: 17px 27px 20px;
              > span {
                display: block;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                min-width: 117px;
              }
            }
            &.rs-nav-item-active {
              a {
                font-weight: 500;
              }
            }
          }          
        }
      }
    } 
    .orders_list_flex ~ .warning_box {
      margin: 20px 0 0;
      background: $warning_new_bg_color;
      border: 0 none;
    }       
  }
  .wrapper_orders_list_flex {
    background: $panel-bg-color;
    border: 1px solid $panel-border-color;
    border-radius: 2px;    
    padding: 25px 30px;
    margin: 0 0 10px;
    &:last-child {
      margin-bottom: 0;
    }
    .orders_list_flex {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: flex-end;
      -ms-flex-line-pack: end;
      align-content: flex-end;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;
      position: relative;
      width: auto;
      margin: 0 -15px;
      > div {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        padding: 0 15px;
        &:nth-child(1) {
          width: calc(100% - 200px);
          max-width: calc(100% - 200px);
        }
        &:nth-child(2) {
          width: 200px;
          max-width: 200px;
        }
        &:nth-child(3) {
          padding-top: 25px;
          width: calc(100% - 200px);
          max-width: calc(100% - 200px);        
        }
        &:nth-child(4) {
          padding-top: 25px;
          width: 200px;
          max-width: 200px;  
          -webkit-align-self: stretch;
          -ms-flex-item-align: stretch;
          align-self: stretch;
        }
      }
      .rs-btn {
        width: 100%;
        &.rs-btn-ghost {
          display: block;
          width: 100%;
          margin: 0;
          padding: 10px 6px;
          border-radius: 2px;
          border: 1px solid $H500;
          color: $H500;
          font-size: 15px;        
          line-height: 20px;
          font-weight: 500;
          cursor: pointer;
          > span {
            display: block;
            width: 100%;
            margin: 0;
            padding: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          &:hover {
            border: 1px solid $H700;
            color: $H700;
          }
        }        
      }
    }
    .wrapper_flex_timer {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;      
      height: 100%;
      > div {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        width: 100%;
        max-width: 100%;
        padding: 0 0 25px;
        &:last-child {
          padding: 0;
        }
      }
    }
    .offers_list{
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      width: auto;
      position: relative;      
      margin: 0 -15px;
      > div {
        padding: 0 15px;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        width: 50%;
        max-width: 50%;
        &:nth-child(3),
        &:nth-child(4) {
          padding-top: 20px;
        }
      }
      .details_box {
        border: 0 none;
        margin: 0;
        padding: 0;
        .ico-item {
          &.price {
            &::before {
              background: transparent url('../svg/price-ico.svg') no-repeat scroll 50% 50%;
              background-size: contain;
            }
          }
        }        
      }
    }       
  }
  .header-flex.with_line {
    padding-bottom: 18px;
    border-bottom: 1px solid $make_offer-border-color;
    .with_line_text{
      padding-top: 5px;
      padding-left: 3px;
      opacity: 0.7;
    }
  }
  .orders_links_list,
  .orders_links_list > li {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    width: auto;
  }
  .orders_links_list {
    margin: 25px -30px 0;
    position: relative;
    border-top: 1px solid $make_offer-delimiter-color;
    &:first-child {
      margin-top: 0;
    }
    > li {
      border-bottom: 1px solid $make_offer-delimiter-color;
      a {
        display: block;
        position: relative;
        width: 100%;
        min-height: 70px;
        margin: 0;
        padding: 23px 70px 23px 15px;
        cursor: pointer;
        text-decoration: none;
        color: $body-text-third-color;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;        
        > span {
          display: block;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &:hover {
          font-weight: 500;
        }
        &:before {
          content: "";
          display: block;
          width: 24px;
          height: 24px;
          position: absolute;
          top: 50%;
          right: 30px;
          margin: -12px 0 0;
          padding: 0;
        }
        &.new {
          &:before {
            background: transparent url('../svg/services-ico.svg') no-repeat scroll 50% 50%;
          }          
        }
        &.review {
          &:before {
            background: transparent url('../svg/supplier-in-review-ico.svg') no-repeat scroll 50% 50%;
          }
        }
        &.awaiting_delivery {
          &:before {
            background: transparent url('../svg/delivery-contact-ico.svg') no-repeat scroll 50% 50%;
          }
        }
        &.delivered {
          &:before {
            background: transparent url('../svg/supplier-accepted-ico.svg') no-repeat scroll 50% 50%;
          }
        }
        &.accepted {
          &:before {
            background: transparent url('../svg/supplier-accepted-ico.svg') no-repeat scroll 50% 50%;
          }
        }
        &.expired {
          &:before {
            background: transparent url('../svg/supplier-expired-icon.svg') no-repeat scroll 50% 50%;
          }
        }
        &.active {
          background: $orders_links_list-bg;
          font-weight: 500;
          padding-left: 45px;
          &::after {
            content: "";
            display: block;
            width: 12px;
            height: 16px;
            position: absolute;
            top: 50%;
            left: 15px;
            margin: -8px 0 0;
            padding: 0;   
            background: transparent url('../svg/catalog_chevron_rtl.svg') no-repeat scroll 50% 50%;       
          }
        }
      }
    }
  }
  .don_not_have_orders {
    padding: 25px;
    width: auto;
    max-width: 100%;
    .banner {
      position: relative;
      width: 50px;
      height: 50px;
      background: $body-text-white-color;
      border-radius: 50%;
      margin: 0 auto 10px;
      &::before {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -12px 0 0 -12px;
        padding: 0;
      }
    }
    &.new {
      .banner {
        &::before {
          background: transparent url('../svg/services-ico.svg') no-repeat scroll 50% 50%;
        }   
      }  
    }
    &.review {
      .banner {
        &::before {
          background: transparent url('../svg/supplier-in-review-ico.svg') no-repeat scroll 50% 50%;
        }        
      }
    }
    &.accepted {
      .banner {
        &::before {
          background: transparent url('../svg/supplier-accepted-ico.svg') no-repeat scroll 50% 50%;
        }        
      }
    }
    &.expired {
      .banner {
        &::before {
          background: transparent url('../svg/supplier-expired-icon.svg') no-repeat scroll 50% 50%;
        }
      }
    }     
    p {
      display: block;
      width: 100%;
      margin: 0 0 15px;
      padding: 0;
      text-align: center; 
      font-weight: 400; 
      font-size: 16px;
      line-height: 19px;
      color: $main-header-color;   
      opacity: 0.7;  
      &.title {
        opacity: 1;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
      }  
      &:last-child {
        margin: 0;
      }  
    }
  }
}

@media (max-width: 1199px) {
  .supplier_orders {
    .wrapper_orders_list_flex {
      .offers_list{
        > div {
          width: 100%;
          max-width: 100%;
          &:nth-child(2) {
            padding-top: 20px;
          }
        }
      }       
    }
  }  
}

@media (max-width: 991px) {
  .supplier_orders {
    .requests_proposal {
      display: block;
      margin: 0;
      > div {
        padding: 0;
        &:nth-child(1) {
          width: 100%;
          max-width: 100%;
          .full_height {
            border: 0 none;
            padding: 0;
          } 
        }          
        &:nth-child(2) {
          width: 100%;
          max-width: 100%;
        }      
      } 
      > div + div {
        margin-top: 30px;
      } 
      &.pagination {
        > div:nth-child(2) {
          margin-top: 16px;
        }
        .wrapper_pagination {
          margin: 0;
        }
      }       
    }
    .wrapper_orders_list_flex {
      padding: 25px 15px 20px;
      margin: 0 0 10px;
      .orders_list_flex {
        > div {
          &:nth-child(1) {
            width: 100%;
            max-width: 100%;
            -webkit-order: 1;
            -ms-flex-order: 1;
            order: 1;            
          }
          &:nth-child(2) {
            padding-top: 25px;
            width: 100%;
            max-width: 100%;
            -webkit-order: 3;
            -ms-flex-order: 3;
            order: 3;             
          }
          &:nth-child(3) {
            padding-top: 25px;
            width: 100%;
            max-width: 100%; 
            -webkit-order: 2;
            -ms-flex-order: 2;
            order: 2;                  
          }
          &:nth-child(4) {
            padding-top: 25px;
            width: 100%;
            max-width: 100%; 
            -webkit-order: 4;
            -ms-flex-order: 4;
            order: 4;                 
          }                  
        }
      }
    }
  } 
  .wrapper_requests_proposal {
    background: $body-bg-white;
    .orders_links_list {
      margin: 25px 0 0;
      > li {
        a {
          padding: 23px 42px 23px 0;
          &:before {
            right: 0;
          }
          &.active {
            padding-left: 15px;
            background: none;
            font-weight: 400;
            &::after {
              content: none;
              display: none;
            }
            &:hover {
              font-weight: 500;
            }            
          }
        }
      }
    } 
  } 
}

.cancel_offer {
  min-height: 100vh;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;  
  margin: 0 auto;
  .rs-modal-dialog {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    .flex-container > div {
      width: auto !important;      
    }
  }
}

.add_alternative {
  max-width: 560px;
  width: calc(100% - 30px);  
  min-height: 100vh;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;  
  margin: 46px auto;
  .rs-modal-dialog {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    .rs-modal-content {
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: $H500;
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        -webkit-border-top-left-radius: 4px;
        -webkit-border-top-right-radius: 4px;
        -moz-border-radius-topleft: 4px;
        -moz-border-radius-topright: 4px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;        
      }
      text-align: initial;
      .rs-modal-body {
        p {
          color: $panel-p-color;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          .rs-btn-link {
            padding: 0;
            margin: 0;
            display: inline;
            vertical-align: top;
            height: auto;
            text-decoration: underline;
            font-size: 16px;
            line-height: 19px;
            > span {
              white-space: normal;
            }
            &:hover {
              text-decoration: none;
            }
            &.red {
              color: $H500;
            }
            &.dark {
              color: $main-header-color;
            }
          } 
          &.error {
            display: block;
            width: 100%;
            overflow: hidden;
            padding: 0 !important;
            margin: 7px 0 7.89px !important;
            color: $H500 !important;
            font-size: 14px !important;
            line-height: 16px !important;
            letter-spacing: -0.006em;  
            font-weight: 400 !important;
            &:last-child {
              margin-bottom: 0 !important;
            }
            &:empty {
              display: none;
            }
          }
        }
        .products-list {
          margin: 15px 0 25px;
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }    
          p {
            display: block;
            margin: 0 0 5px;
            padding: 0;
            width: 100%;
            overflow: hidden;
            font-size: 16px;
            line-height: 19px;  
            font-weight: 400;
            color: $panel-p-color;
            &.description{
              color: $main-header-color;
            }
            &:last-child {
              margin: 0;
            }
          }
          .products-img {
            width: 99px;
            height: 99px;
            overflow: hidden;
            margin: 0;
            padding: 0;
            position: relative;
            border: 1px solid $panel-border-color;
            background: $body-bg-white;
            > img {
              display: block;
              width: auto;
              height: auto;
              max-width: calc(100% - 10px);
              max-height: calc(100% - 10px);
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            &:last-child {
              margin: 0;
            }
          }
          .products-list-flex {
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-align-content: flex-start;
            -ms-flex-line-pack: start;
            align-content: flex-start;
            -webkit-align-items: flex-start;
            -ms-flex-align: start;
            align-items: flex-start;
            margin: 0;
            position: relative;
            width: auto;
            > div {
              -webkit-order: 0;
              -ms-flex-order: 0;
              order: 0;
              -webkit-flex: 0 1 auto;
              -ms-flex: 0 1 auto;
              flex: 0 1 auto;
              -webkit-align-self: auto;
              -ms-flex-item-align: auto;
              align-self: auto;
              &:nth-child(1) {
                width: 109px;
                max-width: 109px;
                padding: 0 0 0 10px;
              }
              &:nth-child(2) {
                width: calc(100% - 109px);
                max-width: calc(100% - 109px);
                padding: 0 10px 0 0;
              }        
            }
          }
          .flex_box_p {
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-align-content: center;
            -ms-flex-line-pack: center;
            align-content: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            position: relative;
            width: auto;
            margin: 0 -7.5px 5px;
            > p {
              width: auto;
              max-width: 100%;
              padding: 0 7.5px;
              margin: 0;
            }
          }    
        }  
        .registration {
          margin: 17px 0 15px;
          overflow: visible;
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }             
          width: auto;
          min-height: auto;
          padding: 0;
          background: none;
          .registration_box {
            overflow: visible;
            &::before {
              content: none;
              display: none;
            }
            max-width: 100%;
            background: none;
            padding: 0;
            border-radius: 0;
            border: 0 none;
          }
        }    
        .wrapper_dropdown {
          .rs-dropdown {
            .rs-btn {
              padding: 8px 14px 8px 34px;
            }
            .rs-dropdown-menu {
              .rs-dropdown-item-content {
                padding: 9px 14px;
              }
            }
          }
        }        
        .wrapper_number_input {
          .postfix {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            white-space: nowrap;
            margin: 0;
            padding: 0;
            display: block;
            overflow: hidden;
            letter-spacing: -0.006em;
            color: $make_offer-postfix-color;
          }
          .rs-input-number {
            .rs-input-number-btn-group-vertical {
              display: none !important;
            }
          }
          .rs-input-group {
            border-radius: 2px;
            background: $textarea-bg-color;
            border: 1px solid $textarea-border-color;
            width: 100%;
            height: 42px;
            overflow: hidden;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-align-content: stretch;
            -ms-flex-line-pack: stretch;
            align-content: stretch;
            -webkit-align-items: flex-start;
            -ms-flex-align: start;
            align-items: flex-start;        
            .rs-input-group-addon {
              background: none;
              padding: 10px 5px 10px 14px;
            }
            input {
              display: block;
              background: $header-search-bg;
              border-radius: 0;
              border: 0 none;
              padding: 10px 14px 10px 5px;
              font-family: $font;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;  
              color: $body-text-second-color;
              overflow: hidden;      
              text-overflow: ellipsis;
              margin: 0;
            }
            input::-webkit-input-placeholder {color: $textarea-placeholder-color;opacity: 1;}
            input::-moz-placeholder          {color: $textarea-placeholder-color;opacity: 1;}
            input:-moz-placeholder           {color: $textarea-placeholder-color;opacity: 1;}
            input:-ms-input-placeholder      {color: $textarea-placeholder-color;opacity: 1;}  
            &:focus,
            &:hover,
            &.rs-input-group-focus {
              border: 1px solid $textarea-border-color-active !important;
            }  
          } 
          &.has-error {
            .rs-input-group {
              border: 1px solid $H500 !important;
              &:focus,
              &:hover,
              &.rs-input-group-focus {
                border: 1px solid $H500 !important;
              } 
            }  
          }
        }  
        .flex-box-button {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-align-content: center;
          -ms-flex-line-pack: center;
          align-content: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          position: relative;
          width: auto;
          margin: 0 -10px;
          > div {
            padding: 15px 10px 0;
            -webkit-order: 0;
            -ms-flex-order: 0;
            order: 0;
            -webkit-flex: 1 1 auto;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            -webkit-align-self: auto;
            -ms-flex-item-align: auto;
            align-self: auto;
            width: auto;
            max-width: 100%;
            .rs-btn.rs-btn-primary{
              min-width: 180px;
              max-width: 100%;
              margin: 0 auto;
              display: block;
            }   
            p {
              text-align: center;
            }         
          }
        }   
        .auto_complete {
          margin: 30px 0;
          position: relative;
          .rs-input-group {
            border-radius: 2px;
            background: $textarea-bg-color;
            border: 1px solid $textarea-border-color;
            width: 100%;
            height: 42px;
            overflow: hidden;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-align-content: stretch;
            -ms-flex-line-pack: stretch;
            align-content: stretch;
            -webkit-align-items: flex-start;
            -ms-flex-align: start;
            align-items: flex-start;        
            .rs-input-group-addon {
              position: relative !important;
              background: none;
              padding: 10px 11px 10px 9px;
              height: 40px;
              width: 36px;
              .rs-icon.rs-icon-search {
                position: absolute;
                top: 50%;
                left: 11px;
                margin: -8px 0 0;
                padding: 0;
                display: block;
                width: 16px;
                height: 16px;
                background: transparent url('../svg/search_bar_ico.svg') no-repeat scroll 0 0;
                &::before {
                  content: none;
                  display: none;
                }
              }
            }
            .rs-btn.rs-btn-default.rs-input-group-btn {
              position: relative !important;
              padding: 10px 9px 10px 11px;
              background: none;
              padding: 10px 11px 10px 9px;
              height: 40px;
              width: 36px;     
              cursor: pointer; 
              .rs-ripple-pond {
                display: none !important;
              }    
              .rs-icon.rs-icon-close {
                position: absolute;
                top: 50%;
                right: 11px;
                margin: -8px 0 0;
                padding: 0;
                display: block;
                width: 16px;
                height: 16px;
                background: transparent url('../svg/delete_button.svg') no-repeat scroll 0 0;
                &::before {
                  content: none;
                  display: none;
                }                
              }
            }
            input {
              display: block;
              background: $header-search-bg;
              border-radius: 0;
              border: 0 none;
              padding: 10px 0;
              font-family: $font;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;  
              color: $body-text-second-color;
              overflow: hidden;      
              text-overflow: ellipsis;
              margin: 0;
              &:last-child {
                padding: 10px 0 10px 11px;
              }
            }
            input::-webkit-input-placeholder {color: $textarea-placeholder-color;opacity: 1;}
            input::-moz-placeholder          {color: $textarea-placeholder-color;opacity: 1;}
            input:-moz-placeholder           {color: $textarea-placeholder-color;opacity: 1;}
            input:-ms-input-placeholder      {color: $textarea-placeholder-color;opacity: 1;}  
            &:focus,
            &:hover,
            &.rs-input-group-focus {
              border: 1px solid $textarea-border-color-active !important;
            }  
          }            
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }  
          .products-list {
            position: absolute;
            background: $body-bg-white;
            border: 1px solid $panel-border-color;
            box-sizing: border-box;
            box-shadow: 0px 2px 8px $calendar-box-shadow-color;
            top: 42px;
            left: 0;
            width: 100%;
            margin: 0;
            max-height: 288px;
            padding: 25px 20px;
            z-index: 5;
            &:empty {
              display: none;
            }
            ul,
            ul > li {
              display: block;
              width: 100%;
              margin: 0;
              padding: 0;
              list-style: none;
            }
            ul {
              max-height: 238px;
              width: 100%;
              overflow: auto;    
              > li {
                padding: 25px 0;
                border-bottom: 1px solid $make_offer-delimiter-color;
                &:first-child {
                  padding-top: 0;
                }
                &:last-child {
                  padding-bottom: 0;
                  border: 0 none;
                }                
                .products-list-flex {
                  width: 100%;
                  overflow: hidden;
                  .rs-btn.rs-btn-primary {
                    width: 100%;
                    margin: 5px 0 0;
                    min-width: auto;
                  }
                }
              }          
            }
            .rs-loader-wrapper {
              max-height: 238px;
              width: 100%;
              overflow: auto;
              .rs-loader {
                background: none;
              }              
            }
            .product_not_found {
              max-height: 238px;
              width: 100%;
              overflow: auto; 
              margin: 0;
              padding: 0;
              .banner {
                max-width: 95px;
              }        
              p {
                font-weight: 400;
                font-size: 16px;
                line-height: 21px;
                margin: 0 0 15px;
                &.header {
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 21px;
                  margin: 0 0 9px;
                  color: $main-header-color;
                }
                &:last-child {
                  margin :0;
                }
                
              }       
            }            
          }        
        }       
      }
    }
  }
}

@media (max-width: 575px) {
  .add_alternative {
    .rs-modal-dialog {
      .rs-modal-content {
        padding: 34px 15px;
        .rs-modal-body {
          .products-list {
            .products-img {
              width: 80px;
              height: 80px;
            }
            .products-list-flex {
              > div {
                -webkit-order: 0;
                -ms-flex-order: 0;
                order: 0;
                -webkit-flex: 0 1 auto;
                -ms-flex: 0 1 auto;
                flex: 0 1 auto;
                -webkit-align-self: auto;
                -ms-flex-item-align: auto;
                align-self: auto;
                &:nth-child(1) {
                  width: 90px;
                  max-width: 90px;
                }
                &:nth-child(2) {
                  width: calc(100% - 90px);
                  max-width: calc(100% - 90px);
                  padding: 0;
                }        
              }
            }
          }  
        }
      }
    }
  }  
}

.flex_box_p {
  .icon_refresh {
    display: inline-block;
    vertical-align: top;
    width: 26px;
    height: 26px;
    margin: 0;
    padding: 4px;
    background: $main-bg-light-grey;
    border: 1px solid $calendar-border-color;
    border-radius: 4px;
    i {
      display: block;
      width: 16px;
      height: 16px;
      margin: 0;
      padding: 0;
      background: transparent url('../svg/replacement-ico.svg') no-repeat scroll 50% 50%;
      &::before {
        content: none;
        display: none;
      }
    }
  }
}

.menu-container-scroll {
  position: relative;
  width: 100%;
  > div {
    width: 100%;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    } 
  }
  button {
    display: block;
    width: 50px;
    height: 100%;
    position: absolute;
    z-index: 5;
    top: 0;
    background-color: transparent !important;
    border-radius: 0;
    padding: 0;
    margin: 0;
    transition: none;
    &::before {
      content: "";
      display: block;
      width: 25px;
      height: 24px;
      overflow: hidden;
      position: absolute;
      top: 50%;
      right: 50%;
      margin: -12px -12.5px 0 0;
      padding: 0;
    }    
    .rs-ripple-pond {
      display: none !important;
    }
    &:hover{
      &::before {
        opacity: 0.75;
      }       
    }
    &:first-child {
      right:0;
      background: linear-gradient(to left, rgba($body-bg-white,0.9) 50%,rgba($body-bg-white,0.5) 100%) !important;
      &::before {
        background: transparent url('../svg/page_top_icon.svg') no-repeat scroll 0% 0; 
      }       
    }
    &:last-child {
      background: linear-gradient(to left, rgba($body-bg-white,0.5) 0%,rgba($body-bg-white,0.9) 50%) !important;
      left:0;
      &::before {
        background: transparent url('../svg/page_end_icon.svg') no-repeat scroll 0% 0; 
      }         
    }    
    &.rs-btn-disabled {
      display: none !important;
    }
  }
}

.no_saved_projects {
  margin: 35px auto 0;
  width: 100%;
  max-width: 477px;
  p {
    display: block;
    width: 100%;
    margin: 0 0 20px;
    overflow: hidden;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $panel-p-color;
    &.header {
      font-size: 18px;
      line-height: 21px;
      color: $body-text-second-color;
      margin: 0 0 15px;
    }    
    &:last-child {
      margin: 0;
    }    
  }
  .rs-btn {
    display: block;
    width: 100%;
    max-width: 170px;
    margin: 20px auto 0;
  }
}

.warning_box.make_offer {
  margin: 15px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }  
}

.rs-input.has-error {
  border-color: $H500 !important;
}

.rs-dropdown.has-error {
  .rs-btn {
    border-color: $H500 !important;
  }
}

.price_box.with_line {
  ul:not(:last-child) {
    border-bottom: 1px solid $panel-border-color !important;
    padding-bottom: 20px !important;
  }
}

.need_help {
  position: relative;
  &::before{ 
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 29px;
    left: 25px;
    background: transparent url('../svg/need_help.svg') no-repeat scroll 50% 50%;
    background-size: contain;
    transform: scaleX(1);
  }
  h4 {
    display: block;
    width: calc(100% - 40px);
    overflow: hidden;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: $body-text-second-color;
    margin: 0 0 12px;
  }
  p {
    display: block;
    width: 100%;
    overflow: hidden;    
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $main-header-color;
    margin: 0 0 4px;
    &:first-of-type {
      margin: 0 0 12px;
    }    
    &:last-child {
      margin: 0;
    }
    a {
      color: $H500;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
        color: $H700;
      }
    } 
  }
}

.wrapper_download_file {
  margin: 24px 0 9px;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }  
  >div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 20px 50px 20px 14px;
    background: $link-list-bg;
    border: 1px dashed $link-list-border-upload;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: $main-header-color;  
    min-height: 20px;
    text-decoration: none; 
    text-align: initial;
    > span {
      display: block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }  
    &::before {
      content: "";
      display: block;
      margin: -10px 0 0;
      padding: 0;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      right: 20px;
      background: transparent url('../svg/document-ico.svg') no-repeat scroll 50% 50%;
      background-size: contain;
    }     
  }
  .button_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &--icon{
    width: 20px;
    height: 20px;
    position: relative;
    background: transparent;
    margin-right: 16px;
    &::before {
      content: "";
      display: block;
      padding: 0;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  &--removeIcon{
    &::before {
      background: transparent url('../svg/delete.svg') no-repeat;
      background-size: contain;
    }
  }
  &--downloadIcon{
    &::before {
      background: transparent url('../svg/download-ico.svg') no-repeat;
      background-size: contain;
    }
  }
}

.upload-packing-modal {
  .rs-modal-content{
    .rs-modal-header {
      p:last-child {
        margin-bottom: 14px
      }
    }
    .rs-modal-body {
      input[type="file"] {
        display: none !important;
      }
      .upload_label {
        display: block;
        padding: 26px 20px;
        background: $link-list-bg;
        border: 1px dashed $link-list-border-upload;
        font-style: normal;
        font-weight: normal;
        margin: 26px 0;   
        cursor: pointer;     
        span {
          display: block;
          width: 100%;
          padding: 0;
          overflow: hidden;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $panel-p-color;
          margin: 0 0 7px;
          &:last-child {
            margin: 0;
          }
          &.title {
            color: $body-text-second-color;
          }
          &.choose_file {
            margin-top: 3px;
            display: inline-block;
            vertical-align: top;
            width: auto;
            max-width: 100%;
            background: $body-bg-white;
            border: 1px solid $chose_border_color;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 4px 16px;  
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;  
            color: $main-header-color;                 
            > strong {
              display: block;
              width: 100%;
              overflow: hidden;
              margin: 0;
              padding: 0;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-weight: 500;
            }
          }
        }
        &:hover {
          span {
            opacity: 0.75;
          }
        } 
      }
      .wrapper_download_file {
        margin: 26px 0;
      }
      .flex-box {
        margin-top: 0;
        > div {
          width: 50%;
          max-width: 50%;
        }
      }
      .choose_file_error{
        color: #e02443;
        font-size: 14px;
      }
    } 
  } 
}

.offers_list .price_box {
  .relative_for_badge {
    position: relative;
    padding-left: 70px;
    overflow: visible;
    width: auto;
    display: inline-block;
    max-width: 100%;
    vertical-align: top;
    .discount_badge {
      display: inline-block;
      vertical-align: middle;
      border: 1px solid $H500;
      border-radius: 3px;  
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.006em;
      color: $H500;  
      padding: 3px 2px 1px;
      min-width: 54px;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 0;
      margin: -14px 0 0;
    }
  }
}

.order-list-separator {
  margin: 0;
}

// landing page
.landing-modal {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  margin: 0;

  .rs-modal {
    padding: 0 15px;
  }

  .rs-modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 40px;
  }

  .rs-modal-body {
    margin: 0;
    padding: 0;
  }

  .rs-modal-content {
    text-align: center;
    padding: 0;

    .container {
      background: $body-bg-white;
      padding: 35px;
      position: relative;
      @media (min-width: 768px) {
        padding: 53px 125px;
      }
    }

    img {
      margin-bottom: 20px;
    }

    h3 {
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 27px;
      direction: ltr !important;
    }

    .btn {
      display: inline;
    }

    .rs-modal-header-close {
      position: absolute;
      width: 26px;
      height: 26px;
      right: -35px;
      top: 0;
      cursor: pointer;
      margin-bottom: 0;
      background: $body-bg-white;
      border-radius: 50%;
      padding: 0;
    }
  }
}

.direction-container {
  font-size: 18px;
  &.active {
    direction: rtl;
  }
}

.language-panel {
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  padding: 3px;
  background: $concrete;
  border-radius: 3px;
  margin-bottom: 17px;

  button {
    border: none;
    background: none;
    padding: 2px 13px;
    transition: .3s opacity;

    @media (min-width: 768px) {
      padding: 8px 18px;
    }

    &.active {
      background: $panel-bg-color;
      box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.04), 0 3px 8px 0 rgba(0, 0, 0, 0.12);
      border-radius: 3px;
    }

    &:hover {
      opacity: .7;
      &.active {
        opacity: 1;
      }
    }
  }
  img {
    width: 28px;
    height: 20px;
  }
}

.bg {
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
}

.link {
  transition: .3s color;
  &:hover {
    color: $H500;
  }
}

// main-banner section
.main-banner {
  padding-top: 31px;
  @media (min-width: 768px) {
    padding-top: 66px;
  }
  @media (min-width: 1024px) {
    padding-top: 116px;
  }

  img {
    max-width: 100%;
  }
}

.main-holder {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 40px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 56px;
  }

  h1 {
    margin-bottom: 29px;
    max-width: 426px;
    font-size: 24px;
    line-height: 1.2;
    @media (min-width: 768px) {
      font-size: 34px;
      margin-bottom: -14px;
    }
    @media (min-width: 1024px) {
      font-size: 55px;
      max-width: 665px;
    }
  }
}

.holder-text {
  max-width: 426px;
  @media (min-width: 768px) {
    max-width: 240px;
  }
  @media (min-width: 1024px) {
    max-width: 450px;
  }
}

.holder-text > div {
  max-width: 426px;
  color: $secondary-text;
  line-height: 1.25;
  @media (min-width: 768px) {
    max-width: 388px;
  }

  p {
    margin-bottom: 29px;
  }
}

// tabs-btn section
.tabs-btn {
  padding-top: 50px;
  padding-bottom: 30px;
  @media (min-width: 768px) {
    padding-top: 70px;
    padding-bottom: 40px;
  }
  @media (min-width: 1024px) {
    padding-top: 90px;
    padding-bottom: 60px;
  }
}

.holder-margin {
  margin: 0 -5px;
  @media (min-width: 768px) {
    display: flex;
  }
}

.tab-benefit {
  display: block;
  padding: 5px;
  cursor: pointer;
  @media (min-width: 768px) {
    width: 50%;
  }

  &:hover {
    text-decoration: none;
  }

  p {
    margin-bottom: 0;
  }
}

.tab-holder {
  padding: 22px 15px;
  border-radius: 10px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $concrete;
  transition: .3s background, .3s opacity;
  @media (min-width: 768px) {
    min-height: 275px;
    padding: 29px 27px;
  }
  &:hover {
    opacity: .7;
  }

  &.active {
    background: $H500;
    border: 2px solid $H500;
    &:hover {
      background: $primary-red-hover;
      opacity: 1;
    }

    .heading,
    h3,
    .text-holder {
      color: $body-bg-white;
    }

    path {
      fill: $body-bg-white;
    }
  }

  .heading {
    font-size: 14px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $banner-bg;
    @media (min-width: 768px) {
      font-size: 16px;
      margin-bottom: 12px;
    }
    @media (min-width: 1024px) {
      margin-bottom: 17px;
    }

    .heading-text {
      flex: 1;
      transition: .3s color;
    }
  }

  h3 {
    flex: 1 1 auto;
    transition: .3s color;
    color: $banner-bg;
  }

  .text-holder {
    flex: 0 1 auto;
    transition: .3s color;
    color: $banner-bg;
  }

  path {
    fill: $banner-bg;
    transition: .3s fill;
  }
}

// about us section
.about-us {
  padding-top: 30px;
  padding-bottom: 30px;
  @media (min-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media (min-width: 1024px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .content-holder {
    padding: 0 5px;
    width: 100%;
    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    &.section-top {
      margin-bottom: 30px;
      @media (min-width: 768px) {
        margin-bottom: 60px;
      }
      @media (min-width: 1024px) {
        margin-bottom: 120px;
      }

      .text-holder {
        padding-left: 0;
        @media (min-width: 768px) {
          padding-right: 60px;
        }
      }
    }

    &.section-bottom {
      .text-holder {
        padding-right: 0;
        @media (min-width: 768px) {
          padding-left: 60px;
        }
      }
      .img-holder {
        display: none;
        @media (min-width: 768px) {
          display: block;
        }
      }

    }
  }

  .img-holder,
  .text-holder {
    width: 100%;
    @media (min-width: 768px) {
      width: 50%;
    }
  }

  .img-holder {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-bottom: 30px;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      float: left;
      padding-top: 129%;
    }
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  h2 {
    font-size: 24px;
    line-height: 1.16;
    margin-bottom: 14px;
    @media (min-width: 768px) {
      font-size: 32px;
    }
  }

  p:not(:last-child) {
    margin-bottom: 15px;
  }

  .list {
    padding-left: 0;
    margin: 0;
    list-style: none;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 1;
      color: $body-text-third-color;
      border-bottom: 1px solid $banner-bg;
      @media (min-width: 768px) {
        font-size: 19px;
        padding: 22px 0;
      }
      @media (min-width: 1024px) {
        font-size: 25px;
      }

      &:first-child {
        border-top: 1px solid $banner-bg;
      }

      &:after {
        content: '';
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: inline;
      }

      &:nth-child(1) {
        &:after {
          background-image: url("../svg/landing_page/graph-bar.svg");
          width: 25px;
          height: 28px;
        }
      }
      &:nth-child(2) {
        &:after {
          background-image: url("../svg/landing_page/clock.svg");
          width: 30px;
          height: 30px;
        }
      }
      &:nth-child(3) {
        &:after {
          background-image: url("../svg/landing_page/interface.svg");
          width: 28px;
          height: 25px;
        }
      }
      &:nth-child(4) {
        &:after {
          background-image: url("../svg/landing_page/system.svg");
          width: 30px;
          height: 27px;
        }
      }
      &:nth-child(5) {
        &:after {
          background-image: url("../svg/landing_page/smart.svg");
          width: 31px;
          height: 33px;
        }
      }
    }
  }
}

// why we section
.why-we {
  padding-top: 60px;
  padding-bottom: 100px;
  .bg-holder {
    background: $H500;
    position: relative;
    min-height: 400px;
    padding: 0 14px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    @media (min-width: 768px) {
      padding: 0 30px;
    }
    @media (min-width: 1024px) {
      padding: 0 52px;
      min-height: 520px;
    }
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url("../img/landing_page/dots.png");
      background-position: center;
      background-repeat: no-repeat;
      top: 0;
      left: 0;
    }
  }

  .content-holder {
    position: relative;
    max-width: 510px;
    z-index: 1;
    color: $header-bg-1;
    font-size: 16px;

    & > div {
      max-width: 410px;
    }
  }

  .preheader {
    font-size: 18px;
    display: block;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 15px;
    line-height: 1.1;
    @media (min-width: 768px) {
      font-size: 35px;
      margin-bottom: 30px;
    }
    @media (min-width: 1024px) {
      font-size: 55px;
    }
  }

  p {
    margin-bottom: 40px;
  }

  .rs-btn-block {
    background: $header-bg-1;
    color: $H500;
    width: auto;
    min-width: 100px;
    padding: 7px 16px;
    font-size: 18px;
    transition: color .3s;

    &:hover {
      color: $body-text-color;
    }
  }
}

// how it work section
.how-it-work {
  background-image: url("../img/landing_page/HIW-banner.png");
  min-height: 620px;
  padding-top: 50px;

  .container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .preheader {
    display: none;
  }

  h3 {
    max-width: 280px;
    margin-bottom: 10px;
    flex: 1 1 auto;
    text-align: right;
    line-height: 1;
    align-self: flex-end;
    direction: rtl;
    @media (min-width: 620px) {
      line-height: 1.5;
    }
  }

  .text-holder {
    flex: 0 1 auto;
    text-align: right;
  }

  p:not(:last-child) {
    margin-bottom: 15px;
  }

  .carousel-list {
    direction: rtl;
    padding-right: 0;
    list-style: none;
    margin-bottom: 20px;

    li {
      line-height: 1;
    }
  }

  .btn-primary {
    max-width: 136px;
    margin-bottom: 0;
    margin-left: 1px;
  }
}

.slick-slider {
  .slick-slide {
    > div {
      height: 100%;
      min-height: 100%;
    }
  }

  .rs-btn-primary {
    margin-bottom: 20px;
    margin-right: 1px;
    margin-left: 0;
    @media (min-width: 620px) {
      margin-bottom: 0;
    }
  }

  .slide-holder {
    height: 100%;
    display: flex !important;
    flex-direction: column;
  }

  .slick-arrow {
    visibility: hidden;
    bottom: 20px !important;
    position: absolute;
    top: auto;
    @media (min-width: 620px) {
      bottom: 34px !important;
    }

    &.slick-prev {
      right: 65px !important;
      @media (min-width: 620px) {
        right: 102px !important;
      }
      &:before {
        background-image: url("../svg/landing_page/arrow-left.svg");
      }
    }

    &.slick-next {
      right: 20px !important;
      left: auto;
      @media (min-width: 620px) {
        right: 57px !important;
      }
      &:before {
        background-image: url("../svg/landing_page/arrow-right.svg");
      }
    }

    &:before {
      content: '';
      visibility: visible;
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 27px;
      height: 16px;
    }
  }
}

.slick-track {
  height: 406px;
  @media (min-width: 620px) {
    height: 370px;
  }
}

.custom-dots {
  display: flex !important;
  list-style: none;
  padding-left: 0;
  position: absolute;
  margin: 0;
  left: 15px;
  bottom: 37px;
  @media (min-width: 620px) {
    left: 50px;
    bottom: 50px;
  }

  li {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    border: 2px solid $body-bg-white;
    background: $banner-bg;
    margin-right: 9px;
    cursor: pointer;
    opacity: .7;

    &.slick-active {
      border: 2px solid $banner-bg;
      background: $body-bg-white;
      cursor: default;
    }
  }

  button {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.carousel-wrap {
  width: calc(100vw - 30px);
  background: $body-bg-white;
  border-radius: 10px;
  position: relative;
  padding: 50px 15px;
  min-height: 515px;
  margin-bottom: 0;
  @media (min-width: 620px) {
    width: 570px;
    min-height: 515px;
    padding: 50px 52px;
  }
}

// benefits section
.benefits {
  padding-top: 64px;
  overflow-x: hidden;
  @media (min-width: 768px) {
    padding-top: 90px;
  }
  @media (min-width: 1024px) {
    padding-top: 128px;
  }

  h2 {
    max-width: 790px;
    line-height: 1.2;
    margin-bottom: 20px;
    @media (min-width: 768px) {
      font-size: 34px;
    }
    @media (min-width: 1024px) {
      font-size: 55px;
      margin-bottom: 40px;
    }
  }

  .tabs-pannel {
    display: flex;
    justify-content: space-between;
    border: 1px solid $light-grey;
    border-radius: 3px;
    padding: 2px;
    margin-bottom: 50px;
    @media (min-width: 768px) {
      max-width: 300px;
    }

    .btn {
      width: 49.5%;
      margin-bottom: 0;
      border: none;
      transition: .3s opacity, .3s background;
      padding: 7px;
      background: none;
      border-radius: 3px;

      &.rs-btn-primary {
        background-color: $H500;
        &:hover {
          background: $H700;
          opacity: 1;
        }
      }

      &:hover {
        opacity: .7;
      }
    }
  }

  .benefits-list {
    display: none;

    &.active {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      margin: 0 -26px;
    }

    li {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 26px;
      font-size: 14px;
      margin-bottom: 26px;
      @media (min-width: 768px) {
        width: 50%;
        font-size: 16px;
        margin-bottom: 70px;
      }
      @media (min-width: 1024px) {
        font-size: 18px;
        margin-bottom: 110px;
      }
    }

    h3 {
      position: relative;
      padding-top: 30px;
      padding-bottom: 14px;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      width: 100%;
      @media (min-width: 768px) {
        font-size: 24px;
      }
      @media (min-width: 1024px) {
        font-size: 32px;
      }

      &:before {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50px;
        background: $H500;
        top: 0;
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: $light-grey;
        bottom: 0;
      }
    }

    p {
      margin-bottom: 0;
    }
  }

  .benefits-builders {
    li:nth-child(7) {
      display: none;
    }
  }
}

// questions section
.questions {
  padding-bottom: 80px;
  @media (min-width: 768px) {
    padding-bottom: 100px;
  }
  h2 {
    font-size: 24px;
    margin-bottom: 23px;
    font-weight: 500;
    @media (min-width: 768px) {
      font-size: 35px;
      margin-bottom: 30px;
    }
    @media (min-width: 1024px) {
      font-size: 55px;
      margin-bottom: 40px;
    }
  }
}

.accordion-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.accordion-item {
  border: 1px solid $concrete;
  border-radius: 12px;
  margin-bottom: 15px;
  padding: 22px 15px;
  font-size: 16px;
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 768px) {
    padding: 30px 25px;
    font-size: 18px;
  }
}

.accordion-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .title {
    font-weight: 500;
    opacity: .9;
  }

  .toggle {
    position: relative;
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    background: $body-text-third-color;
    border-radius: 50px;
    pointer-events: none;
    @media (min-width: 768px) {
      width: 30px;
      height: 30px;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      background: $body-text-white-color;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:before {
      width: 10px;
      height: 1px;
      @media (min-width: 768px) {
        width: 16px;
        height: 2px;
      }
    }

    &:after {
      width: 1px;
      height: 10px;
      @media (min-width: 768px) {
        width: 2px;
        height: 16px;
      }
    }
  }
}

.accordion-content {
  height: 0;
  opacity: 0;
  color: $body-text-third-color;
  display: flex;
  max-width: 92%;
  overflow: hidden;
  transition: all .5s ease-in-out;

  &.show {
    height: auto;
    opacity: .7;
    margin-top: 20px;
  }

  &.show + .accordion-heading {
    .toggle {
      &:after {
        display: none;
      }
    }
  }
}

// contact-form section
.contact-form {
  padding-bottom: 50px;
  @media (min-width: 768px) {
    padding-bottom: 130px;
  }

  .bg-holder {
    background: $concrete;
    border-radius: 10px;
    padding: 38px 14px;
    min-height: 506px;
    position: relative;
    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      padding: 43px 50px;
    }
  }

  .content-left,
  .content-right {
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
      width: 50%;
    }
  }

  .content-left {
    @media (min-width: 768px) {
      margin-left: 27px;
    }

    h2 {
      font-weight: 500;
      margin-bottom: 20px;
    }
  }

  .content-right {
    @media (min-width: 768px) {
      margin-right: 27px;
    }

    div {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
    }

    div:not(:last-child) {
      margin-bottom: 20px;
      @media (min-width: 768px) {
        margin-bottom: 25px;
      }
    }
    
    .rs-form-group {
      margin-bottom: 0 !important;
    }

    .rs-input {
      border-radius: 0;
      color: $secondary-text;

      &:hover {
        border-color: $light-grey;
      }

      &:focus {
        border-bottom: 2px solid $secondary-text;
      }

      &.invalid {
        color: $H500;

        &:focus {
          border-bottom: 2px solid $H500;
        }
        &:hover {
          border-bottom: 2px solid $H500;
        }
      }
    }
  }

  .rs-form-control-wrapper {
    input.valid {
      border-bottom: 2px solid $light-grey;
    }
  }

  .text-holder {
    color: $body-text-third-color;
    opacity: .7;
    p {
      margin-bottom: 20px;
    }
  }

  .text-holder,
  .textarea-block {
    flex: 1 1 auto;
  }

  .socials-block,
  .btn-holder {
    flex: 0 1 auto;
  }

  .socials-block {
    position: absolute;
    bottom: 34px;
    z-index: 1;
    @media (min-width: 768px) {
      position: static;
    }

    svg {
      width: 30px;
      height: 30px;
      @media (min-width: 768px) {
        width: 40px;
        height: 40px;
      }
    }
  }

  a {
    text-decoration: none;
    margin-right: 12px;
    @media (min-width: 768px) {
      margin-right: 17px;
    }

    &:hover {
      .facebook {
        .hover {
          fill: #3b5998;
        }
      }

      .linkedin {
        .hover {
          fill: #0274b3;
        }
      }
    }
  }

  svg {
    .hover {
      transition: .3s fill;
    }
  }

  input, textarea {
    color: $short-description-color;
    font-size: 16px;
    border-top: none;
    border-right: none;
    border-bottom: 2px solid $light-grey;
    border-left: none;
    background: transparent;
    padding: 11px 0;

    &:focus-within,
    &:active {
      outline: none;
    }
  }

  textarea {
    resize: none;
    height: 44px;
  }

  .error {
    display: none;
  }

  .error_show {
    display: block;
    color: $H500;
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    bottom: 2px;
    @media (min-width: 768px) {
      bottom: 7px;
    }
  }

  input.invalid,
  textarea.invalid {
    color: $H500;
    border-bottom: 2px solid $H500;

    &::placeholder {
      color: $H500;
    }
  }

  input.valid,
  textarea.valid {
    color: $secondary-text;
    border-bottom: 2px solid $secondary-text;
  }

  .rs-btn-primary {
    border: 1px solid $H500;
    margin-bottom: 70px;
    margin-top: 25px;
    @media (min-width: 768px) {
      margin-bottom: 4px;
    }
  }
}

// footer
.footer-top {
  background: $body-text-third-color;
  padding: 40px 0;
  @media (min-width: 768px) {
    padding: 50px 0;
  }

  .container {
    @media (min-width: 768px) {
      display: flex;
      align-items: flex-start;
    }
  }

  .logo-holder,
  .contacts-holder {
    @media (min-width: 768px) {
      width: 50%;
    }
  }

  .logo-holder {
    @media (min-width: 768px) {
      margin-right: 27px;
    }
  }

  .logo {
    margin-bottom: 30px;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  .contacts-holder {
    @media (min-width: 768px) {
      margin-left: 27px;
    }
  }

  h6 {
    color: $body-bg-white;
    font-weight: 500;
  }

  .contacts-list {
    margin: 0;
    padding-left: 0;
    list-style: none;

    li {
      font-size: 16px;
      margin-bottom: 12px;
    }

    address {
      font-style: normal;
    }

    address,
    a {
      color: $body-bg-white;
      opacity: .8;
    }
  }

  a {
    text-decoration: none;
  }

  .socials-block {
    a {
      margin-right: 10px;

      &:hover {
        .facebook {
          .hover {
            fill: #3b5998;
          }
        }

        .linkedin {
          .hover {
            fill: #0274b3;
          }
        }
      }
    }

    svg {
      .hover {
        transition: .3s fill;
      }
    }
  }
}

.carousel {
  display: flex;
  transition: all 0.35s ease 0s;
}

.carousel-card {
  min-width: 100%;
  width: 100%;
  opacity: 0;
}

.carousel-card.active {
  opacity: 1;
}

.dots {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.dot {
  display: inline-block;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  border: none;
  padding: 0;
}

.dot:hover {
  cursor: pointer
}

.dot:not(:last-child) {
  margin-right: 10px;
}

.dot.active {
  background-color: #c9c9c9
}

.dot:not(.active) {
  background-color: #545454
}

.button-group {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.button-group > * {
  height: 32px;
  width: 77px;
  border: none;
}

.button-group button {
  background-color: #060606;
  color: #e5e5e5;
}

.button-group button:hover {
  cursor: pointer
}

.button-group button:disabled {
  opacity: 0.5
}

.button-group button:is(:first-child) {
  margin-right: 20px
}
