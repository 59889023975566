@import "variables.scss";
@import "../fonts/shapirit/shapirit.scss";

@font-face {
  font-family: 'rsuite-icon-font';
  src:  url('../fonts/rsuite-icon-font/rsuite-icon-font.ttf') format('truetype'), 
        url('../fonts/rsuite-icon-font/rsuite-icon-font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

[dir=ltr] {
  @import "rsuite-default.scss";
  @import "main.scss";
}

[dir=rtl] {
  @import "rsuite-default-rtl.scss";
  @import "main-rtl.scss";
}

html,
body {
  overflow-x: hidden;
  &.rs-modal-open,
  &.white-rs-supplier-open,
  &.rs-supplier-open {
    overflow: hidden;
    height: 100vh;
  }
}

.ltr {
  direction: ltr !important;
  display: inline-block;
  vertical-align: top;
}

.rs-modal-wrapper {
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
      width: 0;
      height: 0;
  }   
}

.full-height-page {
  > .rs-container {
    > .rs-content {
      position: relative;
      min-height: 83px;
    }
  }  
}